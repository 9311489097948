import React, { Fragment, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomTextarea from "../../components/CustomTextarea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/createBlog.scss";
import {
  clearBlogErrors,
  clearBlogState,
  getBlogById,
  updateBlog,
} from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/ButtonLoader";
import Loader from "../../components/Loader";
import { clearErrors, getAllOfficers } from "../../redux/actions/userAction";
import CustomMultiSelect from "../../components/CustomMultiSelect";

const EditBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [, , showNotification] = useNotification();

  const {
    loading: detailsLoading,
    error: detailsError,
    blogDetails,
  } = useSelector((state) => state.getBlogById);

  useEffect(() => {
    dispatch(getBlogById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (detailsError) {
      dispatch(clearBlogErrors());
    }
  }, [dispatch, detailsError]);

  const [blogData, setBlogData] = useState({
    title: "",
    shortDescription: "",
    metaTitle: "",
    metaDescription: "",
  });

  const [image, setImage] = useState();

  const [avatarPreview, setAvatarPreview] = useState("");

  const [blogContent, setBlogContent] = useState();

  const [authorId, setAuthorId] = useState();
  const [socialData, setSocialData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
  });

  useEffect(() => {
    setBlogData({
      title: blogDetails?.blog?.title,
      shortDescription: blogDetails?.blog?.shortDescription,
      metaTitle: blogDetails?.blog?.metaTitle,
      metaDescription: blogDetails?.blog?.metaDescription,
    });
    if (blogDetails?.blog?.authorDetails?.socialLinks) {
      const socialLinks = blogDetails?.blog?.authorDetails?.socialLinks;

      socialLinks.forEach((link) => {
        const platform = Object.keys(link)[0];
        setSocialData((prevState) => ({
          ...prevState,
          [platform]: link[platform],
        }));
      });
    }
    setAuthorId({
      label: blogDetails?.blog?.authorDetails?.author?.first_name,
      value: blogDetails?.blog?.authorDetails?.author?._id,
    });
    setAvatarPreview(blogDetails?.blog?.image?.url);
    setBlogContent(blogDetails?.blog?.blogContent);
  }, [blogDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setBlogData({
        ...blogData,
        [name]: value,
      });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setSocialData({
      ...socialData,
      [name]: value,
    });
  };

  //   REACT QUILL SETIINGS
  const style = {
    "& .qlSnow .qlStroke": { stroke: "white" },
    height: "400px",
    marginBottom: "20px",
  };

  const modules = {
    toolbar: [
      [{ header: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const formats1 = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "bullet",
    "color",
    "background",
  ];

  const { title, shortDescription, metaDescription, metaTitle } = blogData;

  const handleUpdateBlog = () => {
    const author = authorId?.value;
    const socialLinksArray = Object.entries(socialData)?.map(
      ([key, value]) => ({ [key]: value })
    );
    dispatch(
      updateBlog(
        id,
        title,
        shortDescription,
        metaDescription,
        metaTitle,
        image,
        blogContent,
        author,
        socialLinksArray
      )
    );
  };

  const { loading, error, updateData } = useSelector(
    (state) => state.updateBlogData
  );

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearBlogErrors());
    }
    if (updateData?.success) {
      showNotification("success",updateData?.message);
      dispatch(clearBlogState());
      navigate("/admin/blogs");
    }
  });

  // GET OFFICER LIST
  const {
    officers,
    loading: officerLoading,
    error: officerError,
  } = useSelector((state) => state.allOfficersReducer);

  useEffect(() => {
    dispatch(getAllOfficers());
  }, [dispatch]);

  useEffect(() => {
    if (officerError) {
      showNotification("error", officerError);
      dispatch(clearErrors());
    }
  }, [officerError, dispatch, showNotification]);

  let officerArray = [];

  officers?.officers?.forEach((ele) => {
    officerArray.push({
      label: ele?.first_name,
      value: ele?._id,
    });
  });

  return (
    <Fragment>
      {detailsLoading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_blog_main">
            <div className="container">
              <div className="title_header">
                <PageTitle title="Update Blog" />
              </div>
              <div className="create_blog_data">
                <div className="batch_form">
                  <CustomInput
                    label="Title"
                    type="text"
                    placeholder="AGNIVEER + SSB"
                    name="title"
                    onChange={handleInputChange}
                    value={blogData?.title}
                    impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                  <CustomInput
                    label="Meta title"
                    type="text"
                    placeholder="alpha course"
                    name="metaTitle"
                    onChange={handleInputChange}
                    value={blogData.metaTitle}
                    impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                  <CustomMultiSelect
                    label="Select Officer"
                    placeholder="Select Officer"
                    name="title"
                    isMulti={false}
                    onChange={(val) => setAuthorId(val)}
                    value={authorId}
                    loading={officerLoading}
                    impStar="*"
                    optionArray={officerArray}
                  />
                  {authorId?.value && (
                    <div className="social_links">
                      <CustomInput
                        label="Facebook Url"
                        type="text"
                        placeholder="facebook.com"
                        name="facebook"
                        onChange={handleInput}
                        value={socialData?.facebook}
                        impStar="*"
                        readOnly=""
                        borderColor=""
                      />
                      <CustomInput
                        label="Twitter Url"
                        type="text"
                        placeholder="twitter.com"
                        name="twitter"
                        onChange={handleInput}
                        value={socialData.twitter}
                        impStar="*"
                        readOnly=""
                        borderColor=""
                      />
                      <CustomInput
                        label="Instagram Url"
                        type="text"
                        placeholder="instagram.com"
                        name="instagram"
                        onChange={handleInput}
                        value={socialData?.instagram}
                        impStar="*"
                        readOnly=""
                        borderColor=""
                      />
                      <CustomInput
                        label="Youtube Link"
                        type="text"
                        placeholder="youtube.com"
                        name="youtube"
                        onChange={handleInput}
                        value={socialData.youtube}
                        impStar="*"
                        readOnly=""
                        borderColor=""
                      />
                    </div>
                  )}
                  <CustomTextarea
                    label="ShortDescription"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Description..."
                    name="shortDescription"
                    value={blogData.shortDescription}
                  />
                  <CustomTextarea
                    label="Meta Description"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Meta Description..."
                    name="metaDescription"
                    value={blogData.metaDescription}
                  />
                  <div className="blog_data">
                    <p>Add Blog Content</p>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats1}
                      style={style}
                      name="blogContent"
                      value={blogContent}
                      onChange={(content, delta, source, editor) =>
                        setBlogContent(content)
                      }
                    />
                  </div>

                  {avatarPreview && (
                    <img
                      src={avatarPreview}
                      alt={avatarPreview}
                      width="100%"
                      height="200px"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "16px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <CustomInput
                    label="Blog Thumbnail"
                    type="file"
                    name="thumbnail"
                    accept="image/*"
                    onChange={handleInputChange}
                    impStar="*"
                  />
                </div>
                <CustomButton
                  className="create_course_btn"
                  title={loading ? <ButtonLoader /> : "Update Blog"}
                  onClick={() => handleUpdateBlog()}
                />
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default EditBlog;
