import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allBatchesList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearState,
  deleteBatchByAdmin,
  getAllBatches,
} from "../../redux/actions/batchAction";
import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import moment from "moment";
import usePaginate from "../../hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { useNotification } from "../../context/notificationContext";

const AllBatchesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [, , showNotification] = useNotification();

  // GET ALL BATCHES START
  // GET ALL BATCHES START
  const { loading, batches, error } = useSelector((state) => state.getBatches);

  const allBatches = batches?.batches;

  useEffect(() => {
    dispatch(getAllBatches());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }

    // if (batches?.success) {
    //   showNotification("success",batches?.message);
    //   dispatch(clearState());
    // }
  }, [dispatch, error, batches?.success, showNotification]);

  // GET ALL BATCHES ENDS
  // GET ALL BATCHES ENDS

  // DELETE BATCH START
  // DELETE BATCH START
  const {
    loading: deletedBatchLoading,
    deletedBatch,
    error: deletedBatchError,
  } = useSelector((state) => state.deleteBatch);

  const handleDeleteBatch = (id) => {
    setDeleteId(id);
    setDeletePrompt(true);
  };

  useEffect(() => {
    if (deletedBatchError) {
      showNotification("error", deletedBatchError);
      dispatch(clearErrors());
    }

    if (deletedBatch?.success) {
      showNotification("success", deletedBatch?.message);
      setDeleteId("");
      setDeletePrompt(false);
      dispatch(clearState());
      dispatch(getAllBatches());
    }
  }, [
    deletedBatch?.message,
    deletedBatch?.success,
    deletedBatchError,
    dispatch, showNotification
  ]);

  // DELETE BATCH ENDS
  // DELETE BATCH ENDS

  // PAGINATION START
  // PAGINATION START
  const { currentItems, pageCount, handlePageClick } = usePaginate(allBatches);
  // PAGINATION ENDS
  // PAGINATION ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_batches_main">
            <div className="container">
              <PageTitle title="All Batches" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search batches"
                    name="searchBatch"
                  //   onChange=""
                  //   value=""
                  />

                  <button
                    title="Create New Batch"
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-batch")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Batch name</th>
                        <th>For Course</th>
                        <th>Date created</th>
                        <th>Created by</th>
                        <th>Active Or Not</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item) => {
                        const storedCreatedAt = item?.createdAt;
                        const formattedCreatedAt = moment(
                          storedCreatedAt
                        ).format("DD-MM-YYYY [at] hh:mm A");
                        return (
                          <tr key={item?._id}>
                            <td>
                              <p>{item?.batchName}</p>
                            </td>
                            <td>
                              <p>{item?.forCourse?.label}</p>
                            </td>
                            <td>
                              <p>{formattedCreatedAt}</p>
                            </td>
                            <td>
                              <p>
                                {item?.createdBy?.userName
                                  ? item?.createdBy?.userName
                                  : item?.createdBy}
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  color: item?.isActive ? "green" : "red",
                                  fontWeight: "700",
                                }}
                              >
                                {item?.isActive ? "Active" : "In-Active"}
                              </p>
                            </td>
                            <td>
                              <div>
                                <button
                                  title="Delete Batch"
                                  disabled={deletedBatchLoading ? true : false}
                                  onClick={() => handleDeleteBatch(item?._id)}
                                >
                                  <RiDeleteBin5Line />
                                </button>
                                <button
                                  title="View/Edit Batch"
                                  onClick={() =>
                                    navigate(
                                      `/admin/view-edit-batch/${item?._id}`
                                    )
                                  }
                                >
                                  <AiFillEye />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {allBatches?.length <= 10 ? (
                  ""
                ) : (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => dispatch(deleteBatchByAdmin(deleteId))}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Batch?"
                loading={deletedBatchLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllBatchesList;
