import React from "react";
import "../../styles/allAffiliates.scss";
import "../../styles/allStudentsList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearErrors,
  deleteUserByAdmin,
  getAllUsersByAdmin,
} from "../../redux/actions/userAction";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import { useState } from "react";
import usePaginate from "../../hooks/usePaginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { useNotification } from "../../context/notificationContext";

const AllAffiliates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchString, setSearchString] = useState("");
  const [, , showNotification] = useNotification();

  // GET ALL AFFILIATE USERS START
  // GET ALL AFFILIATE USERS START
  const { loading, allUsers, error } = useSelector(
    (state) => state.getUsersByAdmin
  );

  const allAffiliates = allUsers?.allAffiliates;

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, dispatch, showNotification]);
  // GET ALL AFFILIATE USERS ENDS
  // GET ALL AFFILIATE USERS ENDS

  // DELETE AFFILIATE START
  // DELETE AFFILIATE START
  const {
    loading: deletedUserLoading,
    deletedUser,
    error: deletedUserError,
  } = useSelector((state) => state.deleteUser);

  const deleteUserHandler = (id) => {
    if (id) {
      setDeleteId(id);
      setDeletePrompt(true);
    }
  };

  useEffect(() => {
    if (deletedUserError) {
      showNotification("error", deletedUserError);
      dispatch(clearErrors());
    }
    if (deletedUser?.success) {
      showNotification("success", deletedUser?.message);
      dispatch(clearErrors());
      setDeletePrompt(false);
      dispatch(getAllUsersByAdmin());
    }
  }, [deletedUserError, deletedUser?.success, deletedUser?.message, dispatch, showNotification]);

  // DELETE AFFILIATE ENDS
  // DELETE AFFILIATE ENDS

  // SEARCH AFFILIATS START
  // SEARCH AFFILIATS START
  // let filteredAffiliates = [];

  // filteredAffiliates = allAffiliates?.filter((i) => {
  //   return (
  //     i.email.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
  //     i.phone.includes(searchString.toLocaleLowerCase())
  //   );
  // });
  // SEARCH AFFILIATS ENDS
  // SEARCH AFFILIATS ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } =
    usePaginate(allAffiliates);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_affiliate_main">
            <div className="container">
              <PageTitle title="All Affiliates" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search affiliates by email/mobile no."
                    name="searchString"
                    onChange={(e) => setSearchString(e.target.value)}
                    value={searchString}
                  />

                  <button
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-affiliate")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                <div className="table_data custonScroll">
                  {currentItems?.length === 0 ? (
                    <h3 className="alertText_style">Affiliates Not Found!</h3>
                  ) : (
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Affiliate name</th>
                          <th>Email</th>
                          <th>Contact No.</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          return (
                            <tr key={item._id}>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              <td>
                                <p>{item?.email}</p>
                              </td>
                              <td>
                                <p>{item?.phone}</p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    onClick={() => deleteUserHandler(item._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-affiliate/${item._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>

            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => dispatch(deleteUserByAdmin(deleteId))}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this affiliate user?"
                loading={deletedUserLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllAffiliates;
