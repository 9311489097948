import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERROR,
  CLEAR_STATE,
  CREATE_BATCH_FAIL,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  GET_BATCHES_FAIL,
  GET_BATCHES_REQUEST,
  GET_BATCHES_SUCCESS,
  GET_BATCH_BY_ID_FAIL,
  GET_BATCH_BY_ID_REQUEST,
  GET_BATCH_BY_ID_SUCCESS,
} from "../constants/batchConstant";

// CREATE NEW BATCH
export const createNewBatch =
  (
    batchName,
    forCourse,
    batchHead,
    batchTeachers,
    numOfStudents,
    supportLink,
    timeTable
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_BATCH_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-batch`,
        {
          batchName,
          forCourse,
          batchHead,
          batchTeachers,
          numOfStudents,
          supportLink,
          timeTable,
        },
        config
      );

      dispatch({
        type: CREATE_BATCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_BATCH_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getAllBatches = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BATCHES_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-batches`, config);

    dispatch({
      type: GET_BATCHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BATCHES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getBatchById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BATCH_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/batch/${id}`, config);

    dispatch({
      type: GET_BATCH_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BATCH_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE USER PROFILE DETAILS
export const deleteBatchByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BATCH_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/delete-batch/${id}`, config);

    dispatch({
      type: DELETE_BATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BATCH_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
