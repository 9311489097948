export const CREATE_VIDEO_REQUEST = "CREATE_VIDEO_REQUEST";
export const CREATE_VIDEO_SUCCESS = "CREATE_VIDEO_SUCCESS";
export const CREATE_VIDEO_FAIL = "CREATE_VIDEO_FAIL";

export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAIL = "GET_VIDEOS_FAIL";

export const GET_VIDEO_BY_ID_REQUEST = "GET_VIDEO_BY_ID_REQUEST";
export const GET_VIDEO_BY_ID_SUCCESS = "GET_VIDEO_BY_ID_SUCCESS";
export const GET_VIDEO_BY_ID_FAIL = "GET_VIDEO_BY_ID_FAIL";

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAIL = "DELETE_VIDEO_FAIL";

export const UPDATE_VIDEO_REQUEST = "UPDATE_VIDEO_REQUEST";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_FAIL = "UPDATE_VIDEO_FAIL";

export const CLEAR_VIDEO_ERROR = "CLEAR_VIDEO_ERROR";
export const CLEAR_VIDEO_STATE = "CLEAR_VIDEO_STATE";

export const CLEAR_DELETE_VIDEO_STATE = "CLEAR_DELETE_VIDEO_STATE";
