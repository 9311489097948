export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

export const GET_BLOG_BY_ID_REQUEST = "GET_BLOG_BY_ID_REQUEST";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_FAIL = "GET_BLOG_BY_ID_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";

export const CLEAR_BLOG_ERROR = "CLEAR_BLOG_ERROR";
export const CLEAR_BLOG_STATE = "CLEAR_BLOG_STATE";

export const CLEAR_DELETE_BLOG_STATE = "CLEAR_DELETE_BLOG_STATE";
