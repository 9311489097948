import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  GET_STATS_FAIL,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
} from "../constants/statsConstant";

// UPDATE TEXT TESTIMONIALREDUCER - ADMIN
export const allStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STATS_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_STATS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_STATS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        data: {},
      };

    default:
      return state;
  }
};
