export const CREATE_VID_TESTI_REQUEST = "CREATE_VID_TESTI_REQUEST";
export const CREATE_VID_TESTI_SUCCESS = "CREATE_VID_TESTI_SUCCESS";
export const CREATE_VID_TESTI_FAIL = "CREATE_VID_TESTI_FAIL";

export const ALL_VID_TESTI_REQUEST = "ALL_VID_TESTI_REQUEST";
export const ALL_VID_TESTI_SUCCESS = "ALL_VID_TESTI_SUCCESS";
export const ALL_VID_TESTI_FAIL = "ALL_VID_TESTI_FAIL";

export const DELETE_VID_TESTI_REQUEST = "DELETE_VID_TESTI_REQUEST";
export const DELETE_VID_TESTI_SUCCESS = "DELETE_VID_TESTI_SUCCESS";
export const DELETE_VID_TESTI_FAIL = "DELETE_VID_TESTI_FAIL";

export const GET_VID_TESTI_DETAIL_REQUEST = "GET_VID_TESTI_DETAIL_REQUEST";
export const GET_VID_TESTI_DETAIL_SUCCESS = "GET_VID_TESTI_DETAIL_SUCCESS";
export const GET_VID_TESTI_DETAIL_FAIL = "GET_VID_TESTI_DETAIL_FAIL";

export const UPDATE_VIDEO_TESTI_REQUEST = "UPDATE_VIDEO_TESTI_REQUEST";
export const UPDATE_VIDEO_TESTI_SUCCESS = "UPDATE_VIDEO_TESTI_SUCCESS";
export const UPDATE_VIDEO_TESTI_FAIL = "UPDATE_VIDEO_TESTI_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
