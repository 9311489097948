import React from "react";
import "../../styles/createNewCourse.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { useState } from "react";
import CustomTextarea from "../../components/CustomTextarea";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearState,
  createCourse,
} from "../../redux/actions/courseAction";
import {
  clearErrors as clearUserErrors,
  getAllUsersByAdmin,
} from "../../redux/actions/userAction";
import { useEffect } from "react";
import PopupPrompt from "../../components/PopupPrompt";
import ToggleSwitch from "../../components/ToggleSwitch";
import { BsCalendarDay } from "react-icons/bs";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { useNotification } from "../../context/notificationContext";

const CreateCourse = () => {
  const [createCourseModal, setCreateCourseModal] = useState(false);
  const dispatch = useDispatch();
  const [thumbnail, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();
  const [isEmiAvail, setIsEmiAvail] = useState(false);
  const [allEmis, setAllEmis] = useState([]);
  const [courseTeachers, setCourseTeachers] = useState([]);
  const [, , showNotification] = useNotification();

  // GET ALL TEACHER USERS START
  // GET ALL TEACHER USERS START
  const {
    loading: allUsersLoading,
    allUsers,
    error: allUsersErrors,
  } = useSelector((state) => state.getUsersByAdmin);

  const allTeachers = allUsers?.allTeachers;

  const teacherOptions = [];
  allTeachers?.forEach((item) => {
    teacherOptions.push({
      value: item?._id,
      label: item?.first_name,
    });
  });

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (allUsersErrors) {
      showNotification("error", allUsersErrors);
      dispatch(clearUserErrors());
    }
  }, [allUsersErrors, dispatch, showNotification]);
  // GET ALL TEACHER USERS ENDS
  // GET ALL TEACHER USERS ENDS

  const [courseEMIs, setCourseEMIs] = useState({
    emiName: "",
    emiAmount: "",
    emiDuration: "",
  });

  const handleAddEMI = (e) => {
    const { value, name } = e.target;
    setCourseEMIs({
      ...courseEMIs,
      [name]: value,
    });
  };

  const handleRemoveEmi = (id) => {
    var newData = allEmis;
    newData.splice(id, 1);
    setAllEmis([...newData]);
    showNotification("success", `EMI number ${id + 1} removed`);
  };

  const addAllEmis = () => {
    if (courseEMIs.emiAmount || courseEMIs.emiName || courseEMIs.emiDuration) {
      setAllEmis([...allEmis, courseEMIs]);
    } else {
      showNotification("warning", "Add All EMIs Parameters!");
    }
  };

  // STORED STATE FOR COURSE CREATION DATA
  const [courseData, setCourseData] = useState({
    title: "",
    titleHindi: "",
    description: "",
    descriptionHindi: "",
    original_price: "",
    discounted_price: "",
    study_mode: "",
    affiliate_percentage: "",
    starting_date: "",
    goalType: "",
    courseCategory: "",
    bannervideoLink: "",
    courseDuration: "",
  });

  // COURSE DATA DESTURUCTURE
  const {
    title,
    titleHindi,
    description,
    descriptionHindi,
    original_price,
    discounted_price,
    study_mode,
    affiliate_percentage,
    starting_date,
    goalType,
    courseCategory,
    bannervideoLink,
    courseDuration,
  } = courseData;

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setCourseData({
        ...courseData,
        [name]: value,
      });
    }
  };

  const { loading, error, newCourse } = useSelector(
    (state) => state.createCourse
  );

  // CREATE COURSE HANDLER
  const handleCreateCourse = () => {
    if (
      title &&
      titleHindi &&
      description &&
      descriptionHindi &&
      original_price &&
      discounted_price &&
      study_mode &&
      affiliate_percentage &&
      thumbnail &&
      starting_date &&
      goalType &&
      courseCategory &&
      bannervideoLink &&
      courseTeachers &&
      courseDuration
    ) {
      dispatch(
        createCourse(
          title,
          titleHindi,
          description,
          descriptionHindi,
          original_price,
          discounted_price,
          study_mode,
          affiliate_percentage,
          thumbnail,
          starting_date,
          goalType,
          courseCategory,
          bannervideoLink,
          isEmiAvail,
          allEmis,
          courseTeachers,
          courseDuration
        )
      );
    } else {
      showNotification("error", "Please fill all (*) data carefully.");
    }
  };

  useEffect(() => {
    if (newCourse?.success) {
      showNotification("success", newCourse?.message);
      dispatch(clearState());
      setCreateCourseModal(false);
    }
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, newCourse?.message, newCourse?.success, showNotification]);

  return (
    <Layout>
      <div className="create_course_main">
        <div className="container">
          <div className="title_header">
            <PageTitle title="Create new course" />
          </div>

          <div className="create_batch_data">
            <div className="batch_form">
              <CustomMultiSelect
                label="Select Course Teachers *"
                value={courseTeachers}
                onChange={(val) => setCourseTeachers(val)}
                isMulti={true}
                optionArray={teacherOptions}
                disabled={allUsersLoading ? true : false}
                borderColor={allUsersLoading ? "#f2f2f2" : ""}
              />
              <CustomInput
                label="Course name"
                type="text"
                placeholder="AGNIVEER + SSB"
                name="title"
                onChange={handleInputChange}
                value={title}
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="कोर्स का नाम"
                type="text"
                placeholder="अग्निवीर + एसएसबी"
                name="titleHindi"
                onChange={handleInputChange}
                value={titleHindi}
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Original Price"
                type="Number"
                placeholder="Original Price"
                name="original_price"
                onChange={handleInputChange}
                value={original_price}
                icon="₹"
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Discounted Price"
                type="Number"
                placeholder="Discounted Price"
                name="discounted_price"
                onChange={handleInputChange}
                value={discounted_price}
                icon="₹"
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Course Duration (in months)"
                type="Number"
                placeholder="Course Duration"
                name="courseDuration"
                onChange={handleInputChange}
                value={courseDuration}
                // icon="₹"
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomSelect
                label="Study Mode"
                name="study_mode"
                impStar="*"
                value={study_mode}
                onChange={handleInputChange}
                readOnly=""
                borderColor=""
                optionArray={[
                  { id: 1, option: "Online" },
                  { id: 2, option: "Offline" },
                  { id: 3, option: "Both Online/Offline" },
                ]}
              />
              <CustomInput
                label="Affiliate Percentage"
                type="Number"
                placeholder="Affiliate Percentage"
                name="affiliate_percentage"
                onChange={handleInputChange}
                value={affiliate_percentage}
                icon="%"
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Starting From"
                type="text"
                placeholder="Starting From Text (Custom)"
                name="starting_date"
                onChange={handleInputChange}
                value={starting_date}
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Goal Type"
                type="text"
                placeholder="Goal Type i.e; Armed Forces | Para Military"
                name="goalType"
                onChange={handleInputChange}
                value={goalType}
                impStar="*"
                readOnly=""
                borderColor=""
              />

              <CustomInput
                label="Course Category"
                type="text"
                placeholder="Course Category, i.e; Air Force | Navy | Army | CAPF"
                name="courseCategory"
                onChange={handleInputChange}
                value={courseCategory}
                impStar="*"
                readOnly=""
                borderColor=""
              />
              <CustomInput
                label="Banner video Link"
                type="text"
                placeholder="Note: Please provide youtube embed video link!"
                name="bannervideoLink"
                onChange={handleInputChange}
                value={bannervideoLink}
                impStar="*"
                readOnly=""
                borderColor=""
              />
            </div>
            <div className="course_summary">
              <CustomTextarea
                label="Course Description *"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="Course Description..."
                name="description"
                value={description}
              />
              <CustomTextarea
                label="कोर्स डिस्क्रिप्शन *"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="कोर्स डिस्क्रिप्शन हिंदी में लिखें..."
                name="descriptionHindi"
                value={descriptionHindi}
              />
              {avatarPreview && (
                <img
                  src={avatarPreview}
                  alt={avatarPreview}
                  width="100%"
                  height="200px"
                  style={{
                    marginBottom: "15px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "16px",
                    objectFit: "contain",
                  }}
                />
              )}
              <CustomInput
                label="Course Thumbnail"
                type="file"
                name="thumbnail"
                accept="image/*"
                onChange={handleInputChange}
                impStar="*"
              />
              <div className="add_Emis">
                <div className="title">
                  Create EMIs For This Course:{" "}
                  <ToggleSwitch
                    onChange={(e) => setIsEmiAvail(!isEmiAvail)}
                    checked={isEmiAvail ? true : false}
                  />
                </div>

                {allEmis?.length !== 0 && isEmiAvail && (
                  <div className="emi_data_table_main custonScroll">
                    <table className="all_emis_table ">
                      <thead>
                        <tr>
                          <th>
                            <p>#</p>
                          </th>
                          <th>
                            <p>EMI Name</p>
                          </th>
                          <th>
                            <p>EMI Amount</p>
                          </th>
                          <th>
                            <p>EMI Time</p>
                          </th>
                          <th>
                            <p>Action</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allEmis?.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              <p>{idx + 1}</p>
                            </td>
                            <td>
                              <p>{item?.emiName}</p>
                            </td>
                            <td>
                              <p>₹{item?.emiAmount}</p>
                            </td>
                            <td>
                              <p>{item?.emiDuration} days</p>
                            </td>
                            <td>
                              <button onClick={() => handleRemoveEmi(idx)}>
                                X
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {isEmiAvail === true ? (
                  <div className="options_data" style={{ marginTop: "10px" }}>
                    <div>
                      <CustomInput
                        placeholder="EMI Name"
                        type="text"
                        name="emiName"
                        impStar="*"
                        onChange={handleAddEMI}
                      />
                      <CustomInput
                        placeholder="EMI Amout"
                        type="number"
                        name="emiAmount"
                        icon="₹"
                        onChange={handleAddEMI}
                      />
                      <CustomInput
                        placeholder="EMI Duration (in days)"
                        type="text"
                        name="emiDuration"
                        icon={<BsCalendarDay />}
                        onChange={handleAddEMI}
                      />
                    </div>
                    <button className="add_Emi_btn" onClick={addAllEmis}>
                      Add
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "13px",
                      padding: "10px",
                      background: "#081336",
                      width: "fit-content",
                      margin: "10px auto 0",
                    }}
                  >
                    If you want to create EMIs for this course, please trun on
                    EMI Options
                  </div>
                )}
              </div>
            </div>
            <CustomButton
              className="create_course_btn"
              title="Create Course"
              onClick={() => setCreateCourseModal(true)}
            />
          </div>
        </div>
      </div>

      {/* POPUP PROMPT START */}
      {createCourseModal && (
        <PopupPrompt
          confirmOnclick={handleCreateCourse}
          cancelOnclick={() => setCreateCourseModal(false)}
          title={`Do you really want to create the course for ${title} ?`}
          loading={loading}
        />
      )}

      {/* POPUP PROMPT ENDS */}
    </Layout>
  );
};

export default CreateCourse;
