import React, { Fragment, useEffect } from "react";
import "../../styles/viewStudent.scss";
import PageTitle from "../../components/PageTitle";
import Layout from "../../components/Layout";
import CustomInput from "../../components/CustomInput";
import { defaultUser } from "../../assets/images";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import {
  clearErrors,
  getUserDetailsByAdmin,
  updateUserDetailsByAdmin,
} from "../../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import ButtonLoader from "../../components/ButtonLoader";
import Loader from "../../components/Loader";
import { useNotification } from "../../context/notificationContext";

const ViewStudent = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const { id: userId } = useParams();
  const [, , showNotification] = useNotification();

  // GET USER DETAILS BY ID START
  // GET USER DETAILS BY ID START
  const { loading, userDetails, error } = useSelector(
    (state) => state.getUserDetails
  );

  const userDetailParam = userDetails?.userDetails;

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (userDetails?.success) {
      showNotification("success", userDetails?.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error, userDetails?.success, userDetails?.message, showNotification]);
  // GET USER DETAILS BY ID ENDS
  // GET USER DETAILS BY ID ENDS

  // UPDATE PROFILE STATE
  const [studentData, setStudentData] = useState({
    first_name: userDetailParam?.first_name,
    middle_name: userDetailParam?.middle_name,
    last_name: userDetailParam?.last_name,
    email: userDetailParam?.email,
    phone: userDetailParam?.phone,
    dob: userDetailParam?.dob,
    gender: userDetailParam?.gender,
    address: userDetailParam?.address,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setStudentData({
      ...studentData,
      [name]: value,
    });
  };

  // UPDATE USER DETAILS START
  // UPDATE USER DETAILS START
  const {
    loading: updateUserDetailsLoading,
    updatedUser,
    error: updateUserDetailsError,
  } = useSelector((state) => state.updateUserDetails);

  const handleUserUpdate = () => {
    dispatch(
      updateUserDetailsByAdmin(
        userId,
        studentData?.first_name,
        studentData?.email,
        studentData?.phone,
        studentData?.dob,
        studentData?.gender,
        studentData?.address,
        studentData?.middle_name,
        studentData?.last_name
      )
    );
  };

  useEffect(() => {
    if (updateUserDetailsError) {
      showNotification("error", updateUserDetailsError);
      dispatch(clearErrors());
    }
    if (updatedUser?.success) {
      showNotification("success", updatedUser?.message);
      setEditable(false);
      dispatch(clearErrors());
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [
    dispatch,
    updateUserDetailsError,
    updatedUser?.success,
    updatedUser?.message,
    userId, showNotification
  ]);
  // UPDATE USER DETAILS ENDS
  // UPDATE USER DETAILS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="view_student_main">
            <div className="container">
              <PageTitle title="View Student Profile" />

              <div className="student_profile">
                <div className="student_Parent_details_main">
                  <div className="title">
                    Student Details
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </div>
                  <div className="details_card studentDetails">
                    <div>
                      <CustomInput
                        label="First Name"
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        value={
                          editable
                            ? studentData?.first_name
                            : userDetailParam?.first_name
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Middle Name"
                        type="text"
                        placeholder="Middle Name"
                        name="middle_name"
                        value={
                          editable
                            ? studentData?.middle_name
                            : userDetailParam?.middle_name
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Last Name"
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        value={
                          editable
                            ? studentData?.last_name
                            : userDetailParam?.last_name
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomInput
                        label="Email"
                        type="email"
                        placeholder=""
                        name="email"
                        value={
                          editable ? studentData?.email : userDetailParam?.email
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                    </div>
                    <div>
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="Whatsapp Number"
                        name="phone"
                        value={
                          editable ? studentData?.phone : userDetailParam?.phone
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Date Of Birth"
                        type="date"
                        placeholder=""
                        name="dob"
                        value={
                          editable ? studentData?.dob : userDetailParam?.dob
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomSelect
                        label="Gender"
                        name="gender"
                        value={
                          editable
                            ? studentData?.gender
                            : userDetailParam?.gender
                        }
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                        onChange={handleInputChange}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                          {
                            id: 3,
                            option: "Others",
                          },
                        ]}
                      />
                      <CustomInput
                        label="Address"
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={
                          editable
                            ? studentData?.address
                            : userDetailParam?.address
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                    </div>
                    <div className="userImage">
                      <img src={defaultUser} alt="defaultUser" />
                      {/* <button className="edit_btn">
                      <RiEdit2Line />
                    </button> */}
                    </div>
                  </div>

                  {editable && (
                    <CustomButton
                      className="update_user_btn"
                      disabled={updateUserDetailsLoading ? true : false}
                      title={
                        updateUserDetailsLoading ? <ButtonLoader /> : "Update"
                      }
                      onClick={handleUserUpdate}
                    />
                  )}

                  <div className="title" style={{ marginTop: "50px" }}>
                    Parent Details
                  </div>
                  <div className="details_card studentDetails">
                    <div>
                      <CustomInput
                        label="Father Name"
                        type="text"
                        placeholder="Father Name"
                        value={userDetailParam?.fatherName}
                        readOnly={true}
                      />
                      <CustomInput
                        label="Mother Name"
                        type="text"
                        placeholder="Mother Name"
                        value={userDetailParam?.motherName}
                        readOnly={true}
                      />
                    </div>
                    <div>
                      <CustomInput
                        label="Parent Email"
                        type="email"
                        placeholder="Parent Email"
                        value={userDetailParam?.parentEmail}
                        readOnly={true}
                      />
                      <CustomInput
                        label="Parent WhatsAppNo"
                        type="number"
                        placeholder="Parent WhatsAppNo"
                        value={userDetailParam?.parentWhatsAppNo}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="performance_stats">
                  <div className="title">Performance Stats</div>
                  <div className="details_card"></div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewStudent;
