import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  GET_STATS_FAIL,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
} from "../constants/statsConstant";

// GET ALL STATS OF DATA
export const getAllStats = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-stats`, config);

    dispatch({
      type: GET_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STATS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
