export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAIL = "CREATE_COURSE_FAIL";

export const GET_ALL_COURSE_REQUEST = "GET_ALL_COURSE_REQUEST";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_FAIL = "GET_ALL_COURSE_FAIL";

export const COURSE_BY_ID_REQUEST = "COURSE_BY_ID_REQUEST";
export const COURSE_BY_ID_SUCCESS = "COURSE_BY_ID_SUCCESS";
export const COURSE_BY_ID_FAIL = "COURSE_BY_ID_FAIL";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL";

export const UPDATE_COURSE_DETAILS_REQUEST = "UPDATE_COURSE_DETAILS_REQUEST";
export const UPDATE_COURSE_DETAILS_SUCCESS = "UPDATE_COURSE_DETAILS_SUCCESS";
export const UPDATE_COURSE_DETAILS_FAIL = "UPDATE_COURSE_DETAILS_FAIL";

export const GET_CRS_LONG_DTLS_REQUEST = "GET_CRS_LONG_DTLS_REQUEST";
export const GET_CRS_LONG_DTLS_SUCCESS = "GET_CRS_LONG_DTLS_SUCCESS";
export const GET_CRS_LONG_DTLS_FAIL = "GET_CRS_LONG_DTLS_FAIL";

export const UPDATE_COURSE_META_REQUEST = "UPDATE_COURSE_META_REQUEST";
export const UPDATE_COURSE_META_SUCCESS = "UPDATE_COURSE_META_SUCCESS";
export const UPDATE_COURSE_META_FAIL = "UPDATE_COURSE_META_FAIL";

export const GET_COURSE_META_REQUEST = "GET_COURSE_META_REQUEST";
export const GET_COURSE_META_SUCCESS = "GET_COURSE_META_SUCCESS";
export const GET_COURSE_META_FAIL = "GET_COURSE_META_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_STATE = "CLEAR_STATE";
