import React from "react";
import CustomButton from "../../components/CustomButton";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import { useSelector, useDispatch } from "react-redux";
import ButtonLoader from "../../components/ButtonLoader";
import {
  clearErrors,
  clearState,
  createNewUser,
} from "../../redux/actions/userAction";
import { useEffect } from "react";
import { useNotification } from "../../context/notificationContext";
import { useNavigate } from "react-router-dom";

const CreateNewDoctor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [avatarPreview, setAvatarPreview] = useState("");
  const [avatar, setAvatar] = useState("");

  const [data, setData] = useState({
    first_name: "",
    email: "",
    phone: "",
    password: "",
    teacherBio: "",
    price: "",
    role: "19",
  });

  const { first_name, email, phone, password, teacherBio, price, role } = data;

  // HANDLE INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "profile") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  // USER CREATION START
  const { user, loading, error } = useSelector(
    (state) => state.createNewUserByAdmin
  );
  const newUser = user?.newUser;
  // HANDLE CREATE USER
  const handleCreateUser = () => {
    if (first_name && email && phone && password && price) {
      dispatch(
        createNewUser(
          first_name,
          email,
          phone,
          password,
          avatar,
          teacherBio,
          price,
          role
        )
      );
    } else {
      showNotification("warning", "Please fill all the required fields");
    }
  };

  useEffect(() => {
    if (newUser?.success) {
      showNotification("success", newUser?.message);
      dispatch(clearState());
      navigate("/admin/all-doctors");
    }

    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [newUser, error, dispatch, showNotification, navigate]);
  // USER CREATION ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_Teacher_main">
            <div className="container">
              <PageTitle title="Create Doctor's Profile" />

              <div className="student_profile">
                <div className="student_Parent_details_main">
                  <div className="title">Create Doctor</div>
                  <div className="details_card studentDetails">
                    <div>
                      <CustomInput
                        label="Full Name"
                        type="text"
                        placeholder="Full Name"
                        name="first_name"
                        value={data?.first_name}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Email"
                        type="email"
                        placeholder="Teacher Email"
                        name="email"
                        value={data?.email}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Phone"
                        type="number"
                        placeholder="Mobile / Whatsapp Number"
                        name="phone"
                        value={data?.phone}
                        onChange={handleInputChange}
                      />
                      <CustomSelect
                        label="Gender"
                        name="gender"
                        value={data?.gender}
                        onChange={handleInputChange}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                        ]}
                      />
                      <CustomInput
                        label="Profile Picture"
                        type="file"
                        name="profile"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <CustomInput
                        label="Password"
                        type="text"
                        placeholder="Enter password for Login"
                        name="password"
                        value={data?.password}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Price per consultant"
                        type="number"
                        placeholder="Price per consultant"
                        name="price"
                        value={data?.price}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Specialization"
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Teacher Bio"
                        name="teacherBio"
                        value={data?.teacherBio}
                      />
                    </div>
                    <div className="userImage">
                      <img
                        src={
                          avatarPreview
                            ? avatarPreview
                            : data?.gender === "Female"
                            ? defaultFemalUser
                            : defaultUser
                        }
                        alt="defaultUser"
                      />
                      {/* <button className="edit_btn">
                      <RiEdit2Line />
                    </button> */}
                    </div>
                  </div>

                  <CustomButton
                    className="update_user_btn"
                    disabled={loading ? true : false}
                    title={loading ? <ButtonLoader /> : "Create Doctor"}
                    onClick={handleCreateUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateNewDoctor;
