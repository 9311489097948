import React, { Fragment, useState } from "react";
import "../../styles/videoTestimonialList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import usePaginate from "../../hooks/usePaginate";
import moment from "moment";
import {
  clearErrors,
  clearState,
  deleteVideoTestimonial,
  getVideoTestimonial,
} from "../../redux/actions/vidTestiAction";
import { useNotification } from "../../context/notificationContext";

const VideoTestimonialList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [, , showNotification] = useNotification();
  // const [searchString, setSearchString] = useState("");

  // GET ALL VIDEO TESTIMONIALS START
  // GET ALL VIDEO TESTIMONIALS START
  const { loading, testimonial, error } = useSelector(
    (state) => state.allVideoTestimonial
  );

  const allTestimonials = testimonial?.testimonials;

  useEffect(() => {
    dispatch(getVideoTestimonial());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, dispatch, showNotification]);

  // GET ALL VIDEO TESTIMONIALS ENDS
  // GET ALL VIDEO TESTIMONIALS ENDS

  // DELETE TESTIMONIAL START
  // DELETE TESTIMONIAL START
  const {
    loading: deleteLoading,
    data,
    error: deleteError,
  } = useSelector((state) => state.deleteVideoTestimonial);

  const deleteItemHandler = (id) => {
    setDeletePrompt(true);
    setDeleteId(id);
  };

  useEffect(() => {
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(clearErrors());
    }
    if (data?.success) {
      showNotification("success", data?.message);
      dispatch(clearState());
      setDeletePrompt(false);
      dispatch(getVideoTestimonial());
    }
  }, [dispatch, deleteError, data?.message, data?.success, showNotification]);

  // DELETE TESTIMONIAL ENDS
  // DELETE TESTIMONIAL ENDS

  // Pagination Hook Start
  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } =
    usePaginate(allTestimonials);
  // Pagination Hook Ends
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="video_testimonial_main">
            <div className="container">
              <PageTitle title="Video Testimonials" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search testimonials"
                    name="searchString"
                  //   onChange=""
                  //   value=""
                  />

                  <button
                    className="add_batch_button"
                    title="Create New Testimonial"
                    onClick={() => navigate("/admin/create-video-testimonial")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {allTestimonials?.length <= 0 ? (
                  <h3 className="alertText_style">Testimonial Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Thumbnail</th>
                          <th>Created At & By</th>
                          <th>Active or Not</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          const createddate = item?.createdAt;
                          const formattedCreatedate = moment(
                            createddate
                          ).format("DD-MM-YYYY [at] hh:mm A");

                          return (
                            <tr key={item?._id}>
                              <td>
                                <p>
                                  <Link target="_blank" to={item?.videoUrl}>
                                    <img
                                      style={{ width: "50px", height: "auto" }}
                                      src={item?.thumbnail?.url}
                                      alt="video testimonial"
                                    />
                                  </Link>
                                </p>
                              </td>
                              <td>
                                <p>
                                  {" "}
                                  {formattedCreatedate} by{" "}
                                  <b>{item?.createdBy?.userName}</b>{" "}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    color: item?.isActive ? "green" : "red",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.isActive ? "Active" : "In-Active"}
                                </p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    title="Delete Testimonial"
                                    onClick={() => deleteItemHandler(item?._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>

                                  <button
                                    title="View/Edit Testimonial"
                                    onClick={() =>
                                      navigate(
                                        `/admin/video-testimonial/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {allTestimonials?.length <= 10 ? (
                  ""
                ) : (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() =>
                  dispatch(deleteVideoTestimonial(deleteId))
                }
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Testimonial?"
                loading={deleteLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default VideoTestimonialList;
