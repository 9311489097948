import React, { useState } from "react";
import "../styles/header.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { defaultUser, logo_main, logo_mobile } from "../assets/images";
import CustomInput from "./CustomInput";
import { BsSearch } from "react-icons/bs";
import { GrNotification } from "react-icons/gr";
import { useAuth } from "../context/userContext";

const Header = () => {
  const [openToggler, setOpenToggler] = useState(false);
  const [auth] = useAuth(); // GET USER DETAILS FROM USER CONTEXT
  const userData = auth?.user;

  return (
    <div className="header_main txt_color">
      <div className="container">
        <div className="nav_bar_sec">
          <div className="logo">
            <Link to="/admin/dashboard">
              <img
                className="desktop"
                src={logo_main}
                alt="Alpha Regiment desktop_logo"
              />
              <img
                className="mobile"
                src={logo_mobile}
                alt="Alpha Regiment mobile_logo"
              />
            </Link>
          </div>
          <div className="open_navbar_btn">
            <GiHamburgerMenu onClick={() => setOpenToggler(!openToggler)} />
          </div>
          <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
            <div className="close_menu_btn">
              <AiOutlineClose onClick={() => setOpenToggler(!openToggler)} />
            </div>
            <div className="nav_link">
              <CustomInput
                icon={<BsSearch />}
                type="search"
                // onChange=""
                placeholder="Search"
                name="searchString"
              />
            </div>
            <div className="nav_link">
              <div className="notification_bell">
                <GrNotification />
                <span>3</span>
              </div>
            </div>
            <div className="nav_link">
              <div className="userDetails">
                <span>
                  {userData?.first_name ? userData?.first_name : "Dummy"}
                </span>
                <span>
                  <img
                    src={
                      userData?.profile?.url
                        ? userData?.profile?.url
                        : defaultUser
                    }
                    alt={
                      userData?.profile?.url
                        ? userData?.first_name + " profile photo"
                        : "defaultUser"
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
