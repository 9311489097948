import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allCourseList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { MdContentCopy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearState,
  deleteCourse,
  getAllCourses,
} from "../../redux/actions/courseAction";
import Loader from "../../components/Loader";
import ButtonLoader from "../../components/ButtonLoader";
import PopupPrompt from "../../components/PopupPrompt";
import moment from "moment";
import { useNotification } from "../../context/notificationContext";

const AllCourseList = () => {
  const [openDeletePropmt, setopenDeletePropmt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [, , showNotification] = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // GET ALL COURSES
  const { loading, courses, error } = useSelector(
    (state) => state.getAllCourses
  );

  // DELETE COURSE
  const {
    loading: deleteLoading,
    courses: deleteCourseData,
    error: deleteError,
  } = useSelector((state) => state.deleteCourse);

  // STORE ALL COURSES IN A VARIABLE
  const allCourses = courses?.courses;

  // DELETE COURSE HANDLER
  const deleteCourseHandler = (id) => {
    if (id) {
      setDeleteId(id);
      setopenDeletePropmt(true);
    }
  };

  // GET ALL COURSES ON PAGE LOAD
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  // SHOW ALERT WHEN COURSE DELETED SUCCESSFULLY
  useEffect(() => {
    if (deleteCourseData?.success) {
      showNotification("success", deleteCourseData?.message);
      dispatch(getAllCourses());
      dispatch(clearState());
      setopenDeletePropmt(false);
    }
  }, [deleteCourseData?.message, deleteCourseData?.success, dispatch, showNotification]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      showNotification("error", deleteError);
      dispatch(clearErrors());
    }
  }, [dispatch, error, deleteError, showNotification]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_course_main">
            <div className="container">
              <PageTitle title="Courses" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search Course"
                    name="searchBatch"
                  //   onChange=""
                  //   value=""
                  />

                  <button
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-course")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {allCourses?.length > 0 ? (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Course Thumbnail</th>
                          <th>Course name</th>
                          <th>Study Mode</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allCourses &&
                          allCourses?.map((item, idx) => {
                            const createdDate = item?.createdAt;
                            const formattedCreatedate = moment(
                              createdDate
                            ).format("DD-MM-YYYY [at] hh:mm A");
                            return (
                              <tr key={idx}>
                                <td>
                                  <p>
                                    <img
                                      width={90}
                                      src={item?.thumbnail?.url}
                                      alt={item?.title}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>{item?.title}</p>
                                </td>
                                <td>
                                  <p>{item?.study_mode}</p>
                                </td>
                                <td>
                                  <p>{formattedCreatedate}</p>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      disabled={deleteLoading ? true : false}
                                      onClick={() =>
                                        deleteCourseHandler(item?._id)
                                      }
                                    >
                                      {deleteLoading ? (
                                        <ButtonLoader />
                                      ) : (
                                        <RiDeleteBin5Line />
                                      )}
                                    </button>
                                    {/* <button>
                                      <MdContentCopy />
                                    </button> */}
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/view-edit-course/${item._id}`
                                        )
                                      }
                                    >
                                      <AiFillEye />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="txt_color txt_center">
                    <p>Courses Not Found! We'll work on your requirement.</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* PROMPT START */}
          {openDeletePropmt && (
            <PopupPrompt
              confirmOnclick={() => dispatch(deleteCourse(deleteId))}
              cancelOnclick={() => setopenDeletePropmt(false)}
              title="Do You Really Want to delete this course ?"
              loading={deleteLoading}
            />
          )}
          {/* PROMPT ENDS */}
        </Layout>
      )}
    </Fragment>
  );
};

export default AllCourseList;
