import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allOfficerList.scss";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import { RiDeleteBin5Line } from "react-icons/ri";
import PopupPrompt from "../../components/PopupPrompt";
import { MdAirplanemodeActive, MdAirplanemodeInactive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  clearErrors,
  clearState,
  getAllOfficers,
  inactivateUser,
  seatBookingModify,
} from "../../redux/actions/userAction";
import ButtonLoader from "../../components/ButtonLoader";
import { useNotification } from "../../context/notificationContext";
import { VscFlame } from "react-icons/vsc";
import usePaginate from "../../hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

const AllOfficersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [offcierId, setOffcierId] = useState();
  const [filterOfficerArr, setFilterOfficerArr] = useState([]);
  const [, , showNotification] = useNotification();

  // GET ALL OFFICERS START
  const { officers, loading, error } = useSelector(
    (state) => state.allOfficersReducer
  );

  useEffect(() => {
    dispatch(getAllOfficers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (officers?.success) {
      setFilterOfficerArr(officers?.officers);
    }
  }, [
    error,
    dispatch,
    officers?.success,
    officers?.officers,
    showNotification,
  ]);
  // GET ALL OFFICERS ENDS

  // FILTER OFFICER START
  const filterOfficers = (role) => {
    setRoleId(role);
    if (role !== 0) {
      const allOfficers = officers?.officers?.filter(
        (ele) => ele?.role === role
      );
      setFilterOfficerArr(allOfficers);
    } else {
      setFilterOfficerArr(officers?.officers);
    }
  };
  // FILTER OFFICER ENDS

  // ACTIVATE USER
  const {
    status: activateStatus,
    loading: activateLoading,
    error: activateError,
  } = useSelector((state) => state.activateUserReducer);

  const handleActiveUser = (id) => {
    if (id) {
      setOffcierId(id);
      dispatch(activateUser(id));
    }
  };

  useEffect(() => {
    if (activateError) {
      showNotification("error", activateError);
      dispatch(clearErrors());
    }
    if (activateStatus?.success) {
      showNotification("success", activateStatus?.message);
      dispatch(clearState());
      dispatch(getAllOfficers());
    }
  }, [
    activateError,
    dispatch,
    activateStatus?.success,
    activateStatus?.message,
    showNotification,
  ]);
  // ACTIVATE USER

  // INACTIVATE USER
  const {
    status: inactivateStatus,
    loading: inactivateLoading,
    error: inactivateError,
  } = useSelector((state) => state.inactivateUserReducer);

  const handleInactiveUser = (id) => {
    if (id) {
      setOffcierId(id);
      dispatch(inactivateUser(id));
    }
  };

  useEffect(() => {
    if (inactivateError) {
      showNotification("error", inactivateError);
      dispatch(clearErrors());
    }
    if (inactivateStatus?.success) {
      showNotification("success", inactivateStatus?.message);
      dispatch(clearState());
      dispatch(getAllOfficers());
    }
  }, [
    inactivateError,
    dispatch,
    inactivateStatus?.success,
    inactivateStatus?.message,
    showNotification,
  ]);
  // INACTIVATE USER

  // MODIFY SEAT BOOKING ELIGIBILITY FOR COURSE CREATOR START
  const {
    loading: seatBookingLoading,
    error: seatBookingError,
    status: seatBookingStatus,
  } = useSelector((state) => state.seatBookingEligibility);

  const handleModifySeatBooking = (id) => {
    setOffcierId(id);
    dispatch(seatBookingModify(id));
  };

  useEffect(() => {
    if (seatBookingError) {
      showNotification("error", seatBookingError);
      dispatch(clearErrors());
    }
    if (seatBookingStatus?.success) {
      showNotification("success", seatBookingStatus?.message);
      dispatch(clearState());
      dispatch(getAllOfficers());
    }
  }, [
    seatBookingError,
    dispatch,
    seatBookingStatus?.success,
    seatBookingStatus?.message,
    showNotification,
  ]);
  // MODIFY SEAT BOOKING ELIGIBILITY FOR COURSE CREATOR END

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } =
    usePaginate(filterOfficerArr);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="allOfficersMain">
            <div className="container">
              <PageTitle title="All Officers" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search Officers"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    className="add_batch_button"
                    title="Create New Officer"
                    onClick={() => navigate("/admin/create-teacher")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                <div className="navPanelFilter ">
                  <ul className="navTabs">
                    <li
                      onClick={() => filterOfficers(0)}
                      className={roleId === 0 ? "active" : ""}
                    >
                      All Officers
                    </li>
                    <li
                      onClick={() => filterOfficers("16")}
                      className={roleId === "16" ? "active" : ""}
                    >
                      GTO
                    </li>
                    <li
                      onClick={() => filterOfficers("17")}
                      className={roleId === "17" ? "active" : ""}
                    >
                      IO
                    </li>
                    <li
                      onClick={() => filterOfficers("18")}
                      className={roleId === "18" ? "active" : ""}
                    >
                      Psychologist
                    </li>
                  </ul>
                </div>

                {currentItems?.length <= 0 ? (
                  <h3 className="alertText_style">Officers Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Profile Picture</th>
                          <th>Officer name</th>
                          <th>Email</th>
                          <th>Verified or Not</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={
                                    item?.profile?.url
                                      ? item?.profile?.url
                                      : item?.gender === "Female"
                                      ? defaultFemalUser
                                      : defaultUser
                                  }
                                  alt={item?.first_name}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "5px",
                                    background: "#f2f2f2",
                                  }}
                                />
                              </td>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              <td>
                                <p> {item?.email}</p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    color: item?.isVerified ? "green" : "red",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.isVerified ? "Verified" : "Unverified"}
                                </p>
                              </td>
                              <td>
                                <div>
                                  <button title="Delete Officer">
                                    <RiDeleteBin5Line />
                                  </button>

                                  {item?.isVerified ? (
                                    <button
                                      title="Unverify Officer"
                                      onClick={() =>
                                        handleInactiveUser(item?._id)
                                      }
                                      style={{ color: "green" }}
                                    >
                                      {inactivateLoading &&
                                      offcierId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeInactive />
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      title="Verify Officer"
                                      onClick={() =>
                                        handleActiveUser(item?._id)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      {activateLoading &&
                                      offcierId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeActive />
                                      )}
                                    </button>
                                  )}

                                  <button
                                    title="Modify Seat Booking Eligibility"
                                    onClick={() =>
                                      handleModifySeatBooking(item?._id)
                                    }
                                    style={{
                                      color: item?.seatBooking
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {seatBookingLoading &&
                                    offcierId === item?._id ? (
                                      <ButtonLoader />
                                    ) : (
                                      <VscFlame />
                                    )}
                                  </button>

                                  <button
                                    title="View/Edit Officer"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-teacher/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => setDeletePrompt(false)}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Officer?"
              />
            )}
            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllOfficersList;
