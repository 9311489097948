export const GET_TEXT_TESTI_REQUEST = "GET_TEXT_TESTI_REQUEST";
export const GET_TEXT_TESTI_SUCCESS = "GET_TEXT_TESTI_SUCCESS";
export const GET_TEXT_TESTI_FAIL = "GET_TEXT_TESTI_FAIL";

export const CREATE_TEXT_TESTI_REQUEST = "CREATE_TEXT_TESTI_REQUEST";
export const CREATE_TEXT_TESTI_SUCCESS = "CREATE_TEXT_TESTI_SUCCESS";
export const CREATE_TEXT_TESTI_FAIL = "CREATE_TEXT_TESTI_FAIL";

export const DELETE_TEXT_TESTI_REQUEST = "DELETE_TEXT_TESTI_REQUEST";
export const DELETE_TEXT_TESTI_SUCCESS = "DELETE_TEXT_TESTI_SUCCESS";
export const DELETE_TEXT_TESTI_FAIL = "DELETE_TEXT_TESTI_FAIL";

export const GET_TEXT_TESTI_BY_ID_REQUEST = "GET_TEXT_TESTI_BY_ID_REQUEST";
export const GET_TEXT_TESTI_BY_ID_SUCCESS = "GET_TEXT_TESTI_BY_ID_SUCCESS";
export const GET_TEXT_TESTI_BY_ID_FAIL = "GET_TEXT_TESTI_BY_ID_FAIL";

export const UPDATE_TEXT_TESTI_REQUEST = "UPDATE_TEXT_TESTI_REQUEST";
export const UPDATE_TEXT_TESTI_SUCCESS = "UPDATE_TEXT_TESTI_SUCCESS";
export const UPDATE_TEXT_TESTI_FAIL = "UPDATE_TEXT_TESTI_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
