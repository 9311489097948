import React, { Fragment, useEffect, useState } from "react";
import PopupPrompt from "../../components/PopupPrompt";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBlogErrors,
  clearDeleteBlogState,
  deleteBlogByAdmin,
  getAllBlogs,
} from "../../redux/actions/blogAction";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../hooks/usePaginate";
import moment from "moment";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNotification } from "../../context/notificationContext";

const BlogList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [, , showNotification] = useNotification();

  const { loading, error, blogs } = useSelector((state) => state.getBlogs);

  useEffect(() => {
    if (error) {
      dispatch(clearBlogErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  // DELETE SSB COUIRSE BY ID START
  const {
    error: blogDeleteError,
    loading: blogDeleteLoading,
    blogDelete,
  } = useSelector((state) => state.deleteBlog);

  const handleDeleteBlog = (id) => {
    setDeleteId(id);
    setDeletePrompt(true);
  };

  const handleDelete = () => {
    dispatch(deleteBlogByAdmin(deleteId));
  };

  useEffect(() => {
    if (blogDeleteError) {
      showNotification("error", blogDeleteError);
      dispatch(clearBlogErrors());
    }
    if (blogDelete?.success) {
      showNotification("success", blogDelete?.message);
      dispatch(clearDeleteBlogState());
      setDeletePrompt(false);
      dispatch(getAllBlogs());
    }
  }, [
    blogDeleteError,
    dispatch,
    blogDelete?.message,
    blogDelete?.success,
    showNotification,
  ]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(
    blogs?.blogs
  );
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_batches_main">
            <div className="container">
              <PageTitle title="All Blogs" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search batches"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    title="Create New Batch"
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-blog")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {currentItems?.length > 0 ? (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Blog Image</th>
                          <th>Title</th>
                          <th>Date created</th>
                          <th>Created by</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          const storedCreatedAt = item?.createdAt;
                          const formattedCreatedAt = moment(
                            storedCreatedAt
                          ).format("DD-MM-YYYY [at] hh:mm A");
                          return (
                            <tr key={item?._id}>
                              <td>
                                <p>
                                  <img
                                    width={90}
                                    src={item?.image?.url}
                                    alt={item?.createdBy?.first_name}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>{item?.title?.slice(0, 100)}</p>
                              </td>
                              <td>
                                <p>{formattedCreatedAt}</p>
                              </td>
                              <td>
                                <p>
                                  {item?.createdBy?.first_name
                                    ? item?.createdBy?.first_name
                                    : item?.createdBy}
                                </p>
                              </td>

                              <td>
                                <div>
                                  <button
                                    title="Delete Batch"
                                    disabled={blogDeleteLoading ? true : false}
                                    onClick={() => handleDeleteBlog(item?._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>
                                  <button
                                    title="View/Edit Batch"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-blog/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="txt_color txt_center">
                    <p>Blog Not Found.</p>
                  </div>
                )}

                {blogs?.blogs?.length <= 10 ? (
                  ""
                ) : (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmBtnTxt="Confirm"
                cancelBtnTxt="Cancel"
                confirmOnclick={() => handleDelete()}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Blog?"
                loading={blogDeleteLoading}
                inputPlaceholder="DELETE"
                inputLabel={"Type DELETE in capital letters"}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default BlogList;
