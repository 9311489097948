import {
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ALL_USERS_ADMIN_FAIL,
  ALL_USERS_ADMIN_REQUEST,
  ALL_USERS_ADMIN_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_USER_LOADING,
  CREATE_AFFILIATE_FAIL,
  CREATE_AFFILIATE_REQUEST,
  CREATE_AFFILIATE_SUCCESS,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_TEACHER_FAIL,
  CREATE_TEACHER_REQUEST,
  CREATE_TEACHER_SUCCESS,
  DELETE_USER_ADMIN_FAIL,
  DELETE_USER_ADMIN_REQUEST,
  DELETE_USER_ADMIN_SUCCESS,
  GET_ALL_OFFICERS_FAIL,
  GET_ALL_OFFICERS_REQUEST,
  GET_ALL_OFFICERS_SUCCESS,
  GET_USER_DETAILS_ADMIN_FAIL,
  GET_USER_DETAILS_ADMIN_REQUEST,
  GET_USER_DETAILS_ADMIN_SUCCESS,
  INACTIVATE_USER_FAIL,
  INACTIVATE_USER_REQUEST,
  INACTIVATE_USER_SUCCESS,
  SEAT_BOOK_ELIGIBILITY_FAIL,
  SEAT_BOOK_ELIGIBILITY_REQUEST,
  SEAT_BOOK_ELIGIBILITY_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
} from "../constants/userConstant";

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        isAuthenticated: false,
        userInfo: null,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        userInfo: {},
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };

    default:
      return state;
  }
};

// GET ALL USERS BY ADMIN
export const getUsersByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_USERS_ADMIN_REQUEST:
      return {
        loading: true,
        allUsers: {},
      };
    case ALL_USERS_ADMIN_SUCCESS:
      return {
        loading: false,
        allUsers: action.payload,
      };
    case ALL_USERS_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        allUsers: {},
        error: null,
      };

    default:
      return state;
  }
};

// CREATE AFFILIATE BY ADMIN
export const createAffiliateByAdmin = (state = {}, action) => {
  switch (action.type) {
    case CREATE_AFFILIATE_REQUEST:
      return {
        loading: true,
        affiliate: {},
      };
    case CREATE_AFFILIATE_SUCCESS:
      return {
        loading: false,
        affiliate: action.payload,
      };
    case CREATE_AFFILIATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        affiliate: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const deleteUserByAdmin = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_ADMIN_REQUEST:
      return {
        loading: true,
        deletedUser: {},
      };
    case DELETE_USER_ADMIN_SUCCESS:
      return {
        loading: false,
        deletedUser: action.payload,
      };
    case DELETE_USER_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        deletedUser: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const getUserDetailsByAdmin = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_ADMIN_REQUEST:
      return {
        loading: true,
        userDetails: {},
      };
    case GET_USER_DETAILS_ADMIN_SUCCESS:
      return {
        loading: false,
        userDetails: action.payload,
      };
    case GET_USER_DETAILS_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        userDetails: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const updateUserDetailsByAdmin = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_ADMIN_REQUEST:
      return {
        loading: true,
        updatedUser: {},
      };
    case UPDATE_USER_ADMIN_SUCCESS:
      return {
        loading: false,
        updatedUser: action.payload,
      };
    case UPDATE_USER_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        updatedUser: {},
        error: null,
      };

    default:
      return state;
  }
};

// CREATE TEACHER USER BY ADMIN -- ROLE ID => "12"
export const createTeacherByAdmin = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TEACHER_REQUEST:
      return {
        loading: true,
        teacher: {},
      };
    case CREATE_TEACHER_SUCCESS:
      return {
        loading: false,
        teacher: action.payload,
      };
    case CREATE_TEACHER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        teacher: {},
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL OFFICERS BY ADMIN
export const getAllOfficersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_OFFICERS_REQUEST:
      return {
        loading: true,
        officers: {},
      };
    case GET_ALL_OFFICERS_SUCCESS:
      return {
        loading: false,
        officers: action.payload,
      };
    case GET_ALL_OFFICERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// ACTIVATE USER
export const activateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_USER_REQUEST:
      return {
        loading: true,
        status: {},
      };
    case ACTIVATE_USER_SUCCESS:
      return {
        loading: false,
        status: action.payload,
      };
    case ACTIVATE_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        status: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// INACTIVATE USER
export const inactivateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case INACTIVATE_USER_REQUEST:
      return {
        loading: true,
        status: {},
      };
    case INACTIVATE_USER_SUCCESS:
      return {
        loading: false,
        status: action.payload,
      };
    case INACTIVATE_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        status: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// SEAT BOOKING ELIGIBILITY (true/false) USER
export const seatBookingEligibilityReducer = (state = {}, action) => {
  switch (action.type) {
    case SEAT_BOOK_ELIGIBILITY_REQUEST:
      return {
        loading: true,
        status: {},
      };
    case SEAT_BOOK_ELIGIBILITY_SUCCESS:
      return {
        loading: false,
        status: action.payload,
      };
    case SEAT_BOOK_ELIGIBILITY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        status: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE NEW USER -- ANY ROLE
export const createNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_USER_REQUEST:
      return {
        loading: true,
        user: {},
      };
    case CREATE_NEW_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case CREATE_NEW_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        user: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};