
import {
    CLEAR_DELETE_VIDEO_STATE,
  CLEAR_VIDEO_ERROR,
  CLEAR_VIDEO_STATE,
  CREATE_VIDEO_FAIL,
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  GET_VIDEO_BY_ID_FAIL,
  GET_VIDEO_BY_ID_REQUEST,
  GET_VIDEO_BY_ID_SUCCESS,
  GET_VIDEOS_FAIL,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  UPDATE_VIDEO_FAIL,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
} from "../constants/gptVideoConstant";

// CREATE NEW BATCH BY ADMIN
export const createVideoReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_VIDEO_REQUEST:
      return {
        loading: true,
      videoCreate: {},
      };
    case CREATE_VIDEO_SUCCESS:
      return {
        loading: false,
      videoCreate: action.payload,
      };
    case CREATE_VIDEO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_VIDEO_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_VIDEO_STATE:
      return {
        loading: false,
        error: null,
      videoCreate: {},
      };

    default:
      return state;
  }
};

// GET ALL videos
export const getVideosReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VIDEOS_REQUEST:
      return {
        loading: true,
        videos: {},
      };
    case GET_VIDEOS_SUCCESS:
      return {
        loading: false,
        videos: action.payload,
      };
    case GET_VIDEOS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_VIDEO_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_VIDEO_STATE:
      return {
        loading: false,
        error: null,
        videos: {},
      };

    default:
      return state;
  }
};

// GET ALL BATCH BY ID
export const getVideoBYIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VIDEO_BY_ID_REQUEST:
      return {
        loading: true,
        videoDetails: {},
      };
    case GET_VIDEO_BY_ID_SUCCESS:
      return {
        loading: false,
        videoDetails: action.payload,
      };
    case GET_VIDEO_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_VIDEO_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_VIDEO_STATE:
      return {
        loading: false,
        error: null,
        videoDetails: {},
      };

    default:
      return state;
  }
};

//   UPDATE BLOG REDUCER
export const updateVideoDataReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_REQUEST:
      return {
        loading: true,
        updateData: {},
      };
    case UPDATE_VIDEO_SUCCESS:
      return {
        loading: false,
        updateData: action.payload,
      };
    case UPDATE_VIDEO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_VIDEO_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_VIDEO_STATE:
      return {
        loading: false,
        error: null,
        updateData: {},
      };

    default:
      return state;
  }
};

// DELETE BATCH BY ADMIN
export const deleteVideoByAdmin = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VIDEO_REQUEST:
      return {
        loading: true,
        deletedVideo: {},
      };
    case DELETE_VIDEO_SUCCESS:
      return {
        loading: false,
        deletedVideo: action.payload,
      };
    case DELETE_VIDEO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_VIDEO_ERROR:
      return {
        loading: false,
        error: null,
      };
    case CLEAR_DELETE_VIDEO_STATE:
      return {
        loading: false,
        error: null,
        deletedVideo: {},
      };

    default:
      return state;
  }
};
