import React, { Fragment, useEffect, useState } from "react";
import "../../styles/createTeacher.scss";
import PageTitle from "../../components/PageTitle";
import Layout from "../../components/Layout";
import CustomInput from "../../components/CustomInput";
import { defaultUser, defaultFemalUser } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import CustomTextarea from "../../components/CustomTextarea";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { GrAdd, GrClose } from "react-icons/gr";
import {
  clearErrors,
  createTeacherByAdmin,
} from "../../redux/actions/userAction";
import { useNotification } from "../../context/notificationContext";

const CreateTeacher = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // HANDLE TIME TABLE START
  // HANDLE TIME TABLE START
  const [timeTable, setTimeTable] = useState([]);
  const [weekDay, setWeekDay] = useState("");
  const [time, setTime] = useState("");
  const [classTime, setClassTime] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [avatarPreview, setAvatarPreview] = useState();
  const [thumbnail, setAvatar] = useState();

  // ADD TIME TABLE IN ARRAY
  const handleAddTimeTable = () => {
    if (!weekDay || !time) {
      showNotification("warning", "Please Select Time and WeekDay for add in list");
    } else {
      setClassTime([...classTime, { time: time }]);
      setSelectedTime({
        weekDay: weekDay,
        classTime: [...classTime, { time: time }],
      });

      setTime("");
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = classTime;
    newData.splice(id, 1);
    setClassTime([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  // ADD WEEKDAY AND TIMES IN MAIN LIST
  const addMainTimeTableList = () => {
    if (!weekDay || classTime?.length <= 0) {
      showNotification("warning", "Please Add Time and WeekDay for adding in main list.");
    } else {
      setTimeTable([
        ...timeTable,
        { weekDay: selectedTime?.weekDay, classTime: selectedTime?.classTime },
      ]);

      setWeekDay("");
      setTime("");
      setClassTime([]);
    }
  };

  // DELETE SINGLE ROW  FROM MAIN TIME TABLE ARRAY
  const deleteMainTimeTableRow = (id) => {
    var newData = timeTable;
    newData.splice(id, 1);
    setTimeTable([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };
  // HANDLE TIME TABLE ENDS
  // HANDLE TIME TABLE ENDS

  // SUBJECT ON CHANGE AND MANAGEMENT START
  // SUBJECT ON CHANGE AND MANAGEMENT START
  const [assignSubjects, setAssignSubjects] = useState([]);

  // SUBJECT ON CHANGE AND MANAGEMENT ENDS
  // SUBJECT ON CHANGE AND MANAGEMENT ENDS

  const [teacherData, setTeacherData] = useState({
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    password: "",
    subSpec: "",
    teacherBio: "",
    isActive: true,
    role: "12",
  });

  // HANDLE INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setTeacherData({
        ...teacherData,
        [name]: value,
      });
    }
  };

  // CREATE TEACHER START
  // CREATE TEACHER START
  const { loading, teacher, error } = useSelector(
    (state) => state.createTeacher
  );

  const handleCreateTeacher = () => {
    dispatch(
      createTeacherByAdmin(
        teacherData?.first_name,
        teacherData?.email,
        teacherData?.phone,
        teacherData?.dob,
        teacherData?.gender,
        teacherData?.password,
        teacherData?.subSpec,
        teacherData?.teacherBio,
        teacherData?.isActive,
        teacherData?.role,
        assignSubjects,
        timeTable,
        thumbnail
      )
    );
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }

    if (teacher?.success) {
      showNotification("success", teacher?.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error, teacher?.message, teacher?.success, showNotification]);
  // CREATE TEACHER ENDS
  // CREATE TEACHER ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_Teacher_main">
            <div className="container">
              <PageTitle title="Create Teacher's Profile" />

              <div className="student_profile">
                <div className="student_Parent_details_main">
                  <div className="title">Create Teacher</div>
                  <div className="details_card studentDetails">
                    <div>
                      <CustomInput
                        label="Full Name"
                        type="text"
                        placeholder="Full Name"
                        name="first_name"
                        value={teacherData?.first_name}
                        onChange={handleInputChange}
                      />

                      <CustomInput
                        label="Email"
                        type="email"
                        placeholder="Teacher Email"
                        name="email"
                        value={teacherData?.email}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="Whatsapp Number"
                        name="phone"
                        value={teacherData?.phone}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Date Of Birth"
                        type="date"
                        placeholder=""
                        name="dob"
                        value={teacherData?.dob}
                        onChange={handleInputChange}
                      />
                      <CustomSelect
                        label="Gender"
                        name="gender"
                        value={teacherData?.gender}
                        onChange={handleInputChange}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                          {
                            id: 3,
                            option: "Others",
                          },
                        ]}
                      />
                      <CustomInput
                        label="Profile Picture"
                        type="file"
                        name="thumbnail"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <CustomInput
                        label="Password"
                        type="text"
                        placeholder="Enter password for Login"
                        name="password"
                        value={teacherData?.password}
                        onChange={handleInputChange}
                      />
                      <CustomInput
                        label="Subject Specialization"
                        type="text"
                        placeholder="Subject Specialization"
                        name="subSpec"
                        value={teacherData?.subSpec}
                        onChange={handleInputChange}
                      />
                      <CustomMultiSelect
                        label="Select Subjects"
                        value={assignSubjects}
                        onChange={(val) => setAssignSubjects(val)}
                        isMulti={true}
                        optionArray={[
                          { value: "HIN-01", label: "HIN-01" },
                          { value: "HIN-02", label: "HIN-02" },
                          { value: "HIN-03", label: "HIN-03" },
                          { value: "HIN-04", label: "HIN-04" },
                          { value: "HIN-05", label: "HIN-05" },
                        ]}
                      />
                      <CustomTextarea
                        label="Teacher Bio"
                        rows="5"
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Teacher Bio"
                        name="teacherBio"
                        value={teacherData?.teacherBio}
                      />
                    </div>
                    <div className="userImage">
                      <img
                        src={
                          avatarPreview
                            ? avatarPreview
                            : teacherData?.gender === "Female"
                              ? defaultFemalUser
                              : defaultUser
                        }
                        alt="defaultUser"
                      />
                      {/* <button className="edit_btn">
                        <RiEdit2Line />
                      </button> */}
                    </div>
                  </div>

                  <div className="add_time_title">Time Table</div>
                  <div className="add_timeTable">
                    <div>
                      <div className="timetable_tile">
                        <span>
                          <select
                            name="weekDay"
                            value={weekDay}
                            onChange={(e) => setWeekDay(e.target.value)}
                          >
                            <option value="">Select Week Day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thrusday">Thrusday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                          </select>
                          <button onClick={handleAddTimeTable}>
                            <GrAdd />
                          </button>
                        </span>
                        <span>
                          <input
                            type="time"
                            name="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />

                          {classTime?.length > 0 && (
                            <div className="time_list">
                              <h5>Time List:</h5>
                              {classTime?.map((item, idx) => (
                                <p key={idx}>
                                  {item.time}
                                  <span onClick={() => deleteTimeRow(idx)}>
                                    <GrClose />
                                  </span>
                                </p>
                              ))}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="btn_sec">
                      <CustomButton
                        onClick={addMainTimeTableList}
                        title="Add In List"
                        className="add_time_btn"
                      />
                    </div>
                  </div>

                  {timeTable?.length > 0 && (
                    <div className="main_timeTable_list">
                      <h5>Main Time Table List</h5>

                      <div className="table_data custonScroll">
                        <table className="list_table" cellSpacing="0">
                          <thead>
                            <tr>
                              <th>Week Day</th>
                              <th>Time Table</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {timeTable?.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <p>{item?.weekDay}</p>
                                  </td>
                                  <td>
                                    {item?.classTime?.map((item, idx) => (
                                      <p key={idx}>{item?.time}</p>
                                    ))}
                                  </td>
                                  <td>
                                    <div>
                                      <button
                                        onClick={() =>
                                          deleteMainTimeTableRow(idx)
                                        }
                                      >
                                        <GrClose />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <CustomButton
                    className="update_user_btn"
                    disabled={loading ? true : false}
                    title={"Create Teacher"}
                    onClick={handleCreateTeacher}
                  />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateTeacher;
