import React from "react";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus, AiFillEye } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import usePaginate from "../../hooks/usePaginate";
import { MdAirplanemodeInactive, MdAirplanemodeActive } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  activateUser,
  clearErrors,
  clearState,
  deleteUserByAdmin,
  getAllUsersByAdmin,
  inactivateUser,
} from "../../redux/actions/userAction";
import { useNotification } from "../../context/notificationContext";
import { useState } from "react";
import ButtonLoader from "../../components/ButtonLoader";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import PopupPrompt from "../../components/PopupPrompt";

const DoctorsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [userId, setUserId] = useState("");
  const [deletePrompt, setDeletePrompt] = useState("");

  // GET ALL USERS START
  // GET ALL USERS START
  const { loading, allUsers, error } = useSelector(
    (state) => state.getUsersByAdmin
  );

  const doctors = allUsers?.doctors;
  console.log("doctors", doctors);

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, dispatch, showNotification]);
  // GET ALL USERS ENDS
  // GET ALL USERS ENDS

  // DELETE USER START
  // DELETE USER START
  const {
    loading: deletedUserLoading,
    deletedUser,
    error: deletedUserError,
  } = useSelector((state) => state.deleteUser);

  const deleteUserHandler = (id) => {
    if (id) {
      setUserId(id);
      setDeletePrompt(true);
    }
  };

  useEffect(() => {
    if (deletedUserError) {
      showNotification("error", deletedUserError);
      dispatch(clearErrors());
    }
    if (deletedUser?.success) {
      showNotification("success", deletedUser?.message);
      dispatch(clearErrors());
      setUserId("");
      setDeletePrompt(false);
      dispatch(getAllUsersByAdmin());
    }
  }, [
    deletedUserError,
    deletedUser?.success,
    deletedUser?.message,
    dispatch,
    showNotification,
  ]);

  // DELETE USER ENDS
  // DELETE USER ENDS

  // ACTIVATE USER
  const {
    status: activateStatus,
    loading: activateLoading,
    error: activateError,
  } = useSelector((state) => state.activateUserReducer);

  const handleActiveUser = (id) => {
    if (id) {
      setUserId(id);
      dispatch(activateUser(id));
    }
  };

  useEffect(() => {
    if (activateError) {
      showNotification("error", activateError);
      dispatch(clearErrors());
    }
    if (activateStatus?.success) {
      showNotification("success", activateStatus?.message);
      dispatch(clearState());
      setUserId("");
      dispatch(getAllUsersByAdmin());
    }
  }, [
    activateError,
    dispatch,
    activateStatus?.success,
    activateStatus?.message,
    showNotification,
  ]);
  // ACTIVATE USER

  // INACTIVATE USER
  const {
    status: inactivateStatus,
    loading: inactivateLoading,
    error: inactivateError,
  } = useSelector((state) => state.inactivateUserReducer);

  const handleInactiveUser = (id) => {
    if (id) {
      setUserId(id);
      dispatch(inactivateUser(id));
    }
  };

  useEffect(() => {
    if (inactivateError) {
      showNotification("error", inactivateError);
      dispatch(clearErrors());
    }
    if (inactivateStatus?.success) {
      showNotification("success", inactivateStatus?.message);
      dispatch(clearState());
      setUserId("");
      dispatch(getAllUsersByAdmin());
    }
  }, [
    inactivateError,
    dispatch,
    inactivateStatus?.success,
    inactivateStatus?.message,
    showNotification,
  ]);
  // INACTIVATE USER

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(doctors);
  // Pagination Hook Ends
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_batches_main">
            <div className="container">
              <PageTitle title="All Doctors" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search doctors"
                    name="searchString"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    title="Create New Batch"
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-new-doctor")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {currentItems?.length <= 0 ? (
                  <h3 className="alertText_style">Doctors Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Profile Picture</th>
                          <th>Doctor name</th>
                          <th>Email</th>
                          <th>Contact No.</th>
                          <th>Verified or Not</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={
                                    item?.profile?.url
                                      ? item?.profile?.url
                                      : item?.gender === "Female"
                                      ? defaultFemalUser
                                      : defaultUser
                                  }
                                  alt={item?.first_name}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "5px",
                                  }}
                                />
                              </td>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              <td>
                                <p> {item?.email}</p>
                              </td>
                              <td>
                                <p> {item?.phone}</p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    color: item?.isVerified ? "green" : "red",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.isVerified ? "Verified" : "Unverified"}
                                </p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    title="Delete Doctor"
                                    onClick={() => deleteUserHandler(item?._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>

                                  {item?.isVerified ? (
                                    <button
                                      title="Unverify Doctor"
                                      onClick={() =>
                                        handleInactiveUser(item?._id)
                                      }
                                      style={{ color: "green" }}
                                    >
                                      {inactivateLoading &&
                                      userId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeInactive />
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      title="Verify Doctor"
                                      onClick={() =>
                                        handleActiveUser(item?._id)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      {activateLoading &&
                                      userId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeActive />
                                      )}
                                    </button>
                                  )}

                                  <button
                                    title="View/Edit Doctor"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-doctor/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {doctors?.length >= 10 && (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmBtnTxt="Confirm"
                cancelBtnTxt="Cancel"
                inputLabel="Type `DELETE` in capital latters, for delete the doctor"
                inputPlaceholder="DELETE"
                confirmOnclick={() => dispatch(deleteUserByAdmin(userId))}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Doctor?"
                loading={deletedUserLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default DoctorsList;
