import React, { Fragment, useEffect, useState } from "react";
import "../../styles/createNewBatch.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";
import { getAllUsersByAdmin } from "../../redux/actions/userAction";
import CustomButton from "../../components/CustomButton";
import { GrAdd, GrClose } from "react-icons/gr";
import { clearState, createNewBatch } from "../../redux/actions/batchAction";
import Loader from "../../components/Loader";
import { clearErrors as batchErrors } from "../../redux/actions/batchAction";
import { useNotification } from "../../context/notificationContext";

const CreateNewBatch = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // GET ALL COURSES START
  // GET ALL COURSES START
  const {
    loading: courseLoading,
    courses,
    error: courseError,
  } = useSelector((state) => state.getAllCourses);

  const courseParam = courses?.courses;

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearErrors());
    }
  }, [dispatch, courseError, showNotification]);
  // GET ALL COURSES ENDS
  // GET ALL COURSES ENDS

  // GET ALL USERS START
  // GET ALL USERS START
  const {
    loading: allUsersLoading,
    allUsers,
    error: allUsersError,
  } = useSelector((state) => state.getUsersByAdmin);

  const allTeachers = allUsers?.allTeachers;

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (allUsersError) {
      showNotification("error", allUsersError);
      dispatch(clearErrors());
    }
  }, [allUsersError, dispatch, showNotification]);
  // GET ALL USERS ENDS
  // GET ALL USERS ENDS

  // HANDLE TIME TABLE START
  // HANDLE TIME TABLE START
  const [timeTable, setTimeTable] = useState([]);
  const [weekDay, setWeekDay] = useState("");
  const [time, setTime] = useState("");
  const [classTime, setClassTime] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});

  // ADD TIME TABLE IN ARRAY
  const handleAddTimeTable = () => {
    if (!weekDay || !time) {
      showNotification("warning", "Please Select Time and WeekDay for add in list");
    } else {
      setClassTime([...classTime, { time: time }]);
      setSelectedTime({
        weekDay: weekDay,
        classTime: [...classTime, { time: time }],
      });

      setTime("");
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = classTime;
    newData.splice(id, 1);
    setClassTime([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  // ADD WEEKDAY AND TIMES IN MAIN LIST
  const addMainTimeTableList = () => {
    if (!weekDay || classTime?.length <= 0) {
      showNotification("warning", "Please Add Time and WeekDay for adding in main list.");
    } else {
      setTimeTable([
        ...timeTable,
        { weekDay: selectedTime?.weekDay, classTime: selectedTime?.classTime },
      ]);

      setWeekDay("");
      setTime("");
      setClassTime([]);
    }
  };

  // DELETE SINGLE ROW  FROM MAIN TIME TABLE ARRAY
  const deleteMainTimeTableRow = (id) => {
    var newData = timeTable;
    newData.splice(id, 1);
    setTimeTable([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };
  // HANDLE TIME TABLE ENDS
  // HANDLE TIME TABLE ENDS

  // COURSE OPTIONS FOR SELECTION START
  let courseOptions = [];
  courseParam?.forEach((item) => {
    courseOptions.push({ value: item?._id, label: item?.title });
  });
  // COURSE OPTIONS FOR SELECTION ENDS

  // TEACHER OPTIONS FOR SELECTION START
  let teacherOptions = [];
  allTeachers?.forEach((item) => {
    teacherOptions.push({ value: item?._id, label: item?.first_name });
  });
  // TEACHER OPTIONS FOR SELECTION ENDS

  const [batchName, setBatchName] = useState("");
  const [numOfStudents, setNumOfStudents] = useState(0);
  const [supportLink, setSupportLink] = useState("");

  // BATCH FOR COURSE SETTING START
  // BATCH FOR COURSE SETTING START
  const [forCourse, setForCourse] = useState({}); // SET SELECTION OF COURSE
  // BATCH FOR COURSE SETTING ENDS
  // BATCH FOR COURSE SETTING ENDS

  // BACTH HEAD SETTING START
  // BACTH HEAD SETTING START
  const [batchHead, setBatchHead] = useState({});
  // BACTH HEAD SETTING ENDS
  // BACTH HEAD SETTING ENDS

  // BATCH TEACHER LIST SETTING START
  // BATCH TEACHER LIST SETTING START
  const [batchTeachers, setBatchTeachers] = useState([]);

  // BATCH TEACHER LIST SETTING ENDS
  // BATCH TEACHER LIST SETTING ENDS

  // CREATE NEW BATCH START
  // CREATE NEW BATCH START
  const { loading, batch, error } = useSelector((state) => state.createBatch);

  const handleCreateBatch = () => {
    if (
      !batchName ||
      !forCourse ||
      !batchHead ||
      !batchTeachers ||
      !numOfStudents ||
      !supportLink ||
      !timeTable
    ) {
      showNotification("warning", "Please fill all fields carefully!");
    } else {
      dispatch(
        createNewBatch(
          batchName,
          forCourse,
          batchHead,
          batchTeachers,
          numOfStudents,
          supportLink,
          timeTable
        )
      );
    }
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(batchErrors());
    }

    if (batch?.success) {
      showNotification("success", batch?.message);
      dispatch(clearState());
    }
  }, [batch?.message, batch?.success, dispatch, error, showNotification]);

  // CREATE NEW BATCH ENDS
  // CREATE NEW BATCH ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_batch_main">
            <div className="container">
              <PageTitle title="Create New Batch" />

              <div className="create_batch_data">
                <div>
                  <div className="batch_form">
                    <CustomInput
                      label="Batch name"
                      type="text"
                      placeholder="NDA_2023"
                      name="batchName"
                      value={batchName}
                      onChange={(e) => setBatchName(e.target.value)}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />
                    <CustomMultiSelect
                      label="Select course"
                      value={forCourse}
                      onChange={(val) => setForCourse(val)}
                      isMulti={false}
                      optionArray={
                        courseLoading
                          ? [{ label: "Loading...", value: "" }]
                          : courseOptions
                      }
                      disabled={false}
                      borderColor=""
                    />
                    <CustomMultiSelect
                      label="Batch head"
                      value={batchHead}
                      onChange={(val) => setBatchHead(val)}
                      isMulti={false}
                      optionArray={
                        allUsersLoading
                          ? [{ label: "Loading...", value: "" }]
                          : teacherOptions
                      }
                      disabled={false}
                      borderColor=""
                    />
                    <CustomMultiSelect
                      label="Teachers"
                      value={batchTeachers}
                      onChange={(val) => setBatchTeachers(val)}
                      isMulti={true}
                      optionArray={
                        allUsersLoading
                          ? [{ label: "Please Wait...", value: "" }]
                          : teacherOptions
                      }
                      disabled={false}
                      borderColor=""
                    />
                    <CustomInput
                      label="Number of students"
                      type="number"
                      placeholder="50"
                      name="numOfStudents"
                      onChange={(e) => setNumOfStudents(e.target.value)}
                      value={numOfStudents}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />
                    <CustomInput
                      label="Support Link"
                      type="text"
                      placeholder="Add link here..."
                      name=""
                      onChange={(e) => setSupportLink(e.target.value)}
                      value={supportLink}
                      impStar=""
                      readOnly=""
                      borderColor=""
                    />
                  </div>
                  <div className="batch_timeTable">
                    <div className="title_label">Set Time table</div>
                    <div className="add_timeTable">
                      <div>
                        <div className="timetable_tile">
                          <span>
                            <select
                              name="weekDay"
                              value={weekDay}
                              onChange={(e) => setWeekDay(e.target.value)}
                            >
                              <option value="">Select Week Day</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thrusday">Thrusday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                              <option value="Sunday">Sunday</option>
                            </select>
                            <button onClick={handleAddTimeTable}>
                              <GrAdd />
                            </button>
                          </span>
                          <span>
                            <input
                              type="time"
                              name="time"
                              value={time}
                              onChange={(e) => setTime(e.target.value)}
                            />

                            {classTime?.length > 0 && (
                              <div className="time_list">
                                <h5>Time List:</h5>
                                {classTime?.map((item, idx) => (
                                  <p key={idx}>
                                    {item.time}
                                    <span onClick={() => deleteTimeRow(idx)}>
                                      <GrClose />
                                    </span>
                                  </p>
                                ))}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="btn_sec">
                        <CustomButton
                          onClick={addMainTimeTableList}
                          title="Add In List"
                          className="add_time_btn"
                        />
                      </div>
                    </div>
                    <div className="batch_timeTable_data">
                      {timeTable?.map((item, idx) => {
                        return (
                          <div className="batch_timeCard" key={idx}>
                            <div className="weekDay">
                              <span>{item?.weekDay}</span>
                              <span onClick={() => deleteMainTimeTableRow(idx)}>
                                <RiDeleteBin5Fill />
                              </span>
                            </div>
                            <div className="batch_Time">
                              {item?.classTime?.map((ele, id) => {
                                return <span key={id}>{ele?.time}</span>;
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <CustomButton
                  className="create_batch_btn"
                  title="Create Batch"
                  onClick={handleCreateBatch}
                  disabled={loading ? true : false}
                />
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateNewBatch;
