import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import "../../styles/createVideo.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/ButtonLoader";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import {
  clearVideoErrors,
  clearVideoState,
  createNewVideo,
} from "../../redux/actions/gptVideoAction";

const CreateNewVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [videoData, setVideoData] = useState({
    title: "",
    url: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setVideoData({
      ...videoData,
      [name]: value,
    });
  };
  const [tag, setTag] = useState();
  const [tagList, setTagList] = useState([]);

  // HANDLE Tags ADDS START
  const handleAddTags = () => {
    if (!tag) {
      showNotification("error", "Please add video tags");
    } else {
      setTagList([...tagList, tag]);
      setTag("");
    }
  };

  // HANDLE REMOVE Tags
  const handleRemoveTags = (index) => {
    const updatedTagsList = tagList.filter((_, i) => i !== index);
    // Update the state with the new array
    setTagList(updatedTagsList);
  };

  const { title, url } = videoData;
  const handleCreateVideo = () => {
    if (tagList?.length > 0) {
      dispatch(createNewVideo(title, url, tagList));
    } else {
      showNotification("error", "Please add video tags");
    }
  };

  const { error, loading, videoCreate } = useSelector(
    (state) => state.createVideo
  );

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearVideoErrors());
    }
    if (videoCreate?.success) {
      showNotification("success", videoCreate?.message);
      dispatch(clearVideoState());
      navigate("/admin/videos");
    }
  }, [
    dispatch,
    error,
    navigate,
    videoCreate?.message,
    videoCreate?.success,
    showNotification,
  ]);

  return (
    <Layout>
      <div className="create_video_main">
        <div className="container">
          <div className="title_header">
            <PageTitle title="Add New Video" />
          </div>

          <div className="create_video_data">
            <div className="video_form">
              <CustomInput
                label="Title"
                type="text"
                placeholder="Title has maximum 50 to 70 characters"
                name="title"
                onChange={handleInput}
                value={videoData?.title}
                impStar="*"
                readOnly=""
                borderColor=""
              />

              <CustomInput
                label="Video Url"
                type="text"
                name="url"
                placeholder="Add embeded video url"
                onChange={handleInput}
                impStar="*"
                value={videoData?.url}
              />
              <div className="add_tags">
                <div className="tags">
                  <div className="sc-beqWaB kdsiBp myInput">
                    <label>
                      Add Tag <abbr className="impStar">*</abbr>
                    </label>
                    <div className="input_icon">
                      <input
                        className="input_text"
                        type="text"
                        placeholder="Add Tags"
                        name="tags"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleAddTags();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <button onClick={() => handleAddTags()}>
                    <FaPlus />
                  </button>
                </div>
                {tagList?.length > 0 && (
                  <div className="tag_list">
                    <p>Tag List :</p>
                    <ul>
                      {tagList?.map((ele, idx) => (
                        <li key={idx}>
                          <span>{idx + 1}.</span>
                          <span>{ele}</span>
                          <button onClick={() => handleRemoveTags(idx)}>
                            <RxCross2 />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="create_btns">
              <CustomButton
                className="create_course_btn"
                title={loading ? <ButtonLoader /> : "Create Video"}
                onClick={() => handleCreateVideo()}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateNewVideo;
