import React from "react";
import "../../styles/viewEditAffiliate.scss";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import ButtonLoader from "../../components/ButtonLoader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { defaultUser } from "../../assets/images";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  clearErrors,
  getUserDetailsByAdmin,
  updateUserDetailsByAdmin,
} from "../../redux/actions/userAction";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import { useNotification } from "../../context/notificationContext";

const ViewEditAffiliate = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const { id: userId } = useParams();
  const [, , showNotification] = useNotification();

  // GET USER DETAILS BY ID START
  // GET USER DETAILS BY ID START
  const { loading, userDetails, error } = useSelector(
    (state) => state.getUserDetails
  );

  const userDetailParam = userDetails?.userDetails;

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (userDetails?.success) {
      showNotification("success", userDetails?.message);
      // dispatch(clearErrors())
    }
  }, [dispatch, error, userDetails?.success, userDetails?.message, showNotification]);
  // GET USER DETAILS BY ID ENDS
  // GET USER DETAILS BY ID ENDS

  // UPDATE PROFILE STATE
  const [affiliateData, setAffiliateData] = useState({
    first_name: userDetailParam?.first_name,
    email: userDetailParam?.email,
    phone: userDetailParam?.phone,
    dob: userDetailParam?.dob,
    gender: userDetailParam?.gender,
    address: userDetailParam?.address,
    couponCode: userDetailParam?.couponCode,
  });

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setAffiliateData({
      ...affiliateData,
      [name]: value,
    });
  };

  // UPDATE USER DETAILS START
  // UPDATE USER DETAILS START
  const {
    loading: updateUserDetailsLoading,
    updatedUser,
    error: updateUserDetailsError,
  } = useSelector((state) => state.updateUserDetails);

  const handleUserUpdate = () => {
    dispatch(
      updateUserDetailsByAdmin(
        userId,
        affiliateData?.first_name,
        affiliateData?.email,
        affiliateData?.phone,
        affiliateData?.dob,
        affiliateData?.gender,
        affiliateData?.address
      )
    );
  };

  useEffect(() => {
    if (updateUserDetailsError) {
      showNotification("error", updateUserDetailsError);
      dispatch(clearErrors());
    }
    if (updatedUser?.success) {
      showNotification("success", updatedUser?.message);
      setEditable(false);
      dispatch(clearErrors());
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [
    dispatch,
    updateUserDetailsError,
    updatedUser?.success,
    updatedUser?.message,
    userId, showNotification
  ]);
  // UPDATE USER DETAILS ENDS
  // UPDATE USER DETAILS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_affiliate_main">
            <div className="container">
              <PageTitle title="View/Edit Affiliate" />

              <section className="affiliate_profile_section txt_color">
                {/* <div className="container"> */}
                {/* <h2 className="profilePage_heading">Account Info</h2> */}

                <div className="user_details_main">
                  <div className="user_pro_data">
                    <h2>
                      Affiliate Profile
                      <button
                        className={editable ? "activeBtn" : ""}
                        onClick={() => setEditable(!editable)}
                      >
                        {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                      </button>
                    </h2>
                    <div>
                      <div className="data_card">
                        <CustomInput
                          label="Full Name"
                          type="text"
                          placeholder="Full Name"
                          impStar="*"
                          name="first_name"
                          value={
                            editable
                              ? affiliateData?.first_name
                              : userDetailParam?.first_name
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />

                        <CustomInput
                          label="Email"
                          type="email"
                          placeholder="example@email.com"
                          impStar="*"
                          name="email"
                          value={
                            editable
                              ? affiliateData?.email
                              : userDetailParam?.email
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Whatsapp Number"
                          type="number"
                          placeholder="0987654321"
                          impStar="*"
                          name="phone"
                          value={
                            editable
                              ? affiliateData?.phone
                              : userDetailParam?.phone
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomInput
                          label="Date Of Birth"
                          type="date"
                          placeholder="26-11-1998"
                          name="dob"
                          value={
                            editable ? affiliateData?.dob : userDetailParam?.dob
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                        <CustomSelect
                          label="Gender"
                          name="gender"
                          impStar="*"
                          value={
                            editable
                              ? affiliateData?.gender
                              : userDetailParam?.gender
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                          optionArray={[
                            {
                              id: 1,
                              option: "Male",
                            },
                            {
                              id: 2,
                              option: "Female",
                            },
                            {
                              id: 3,
                              option: "Others",
                            },
                          ]}
                        />
                        <CustomInput
                          label="Unique Coupon Code"
                          type="text"
                          placeholder="Unique Coupon Code In Capital Letters"
                          impStar="*"
                          name="couponCode"
                          value={userDetailParam?.couponCode}
                          onChange={handleInputChange}
                          readOnly={true}
                        />

                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar="*"
                          name="address"
                          value={
                            editable
                              ? affiliateData?.address
                              : userDetailParam?.address
                          }
                          onChange={handleInputChange}
                          readOnly={editable ? false : true}
                          borderColor={editable ? "#f2f2f2" : ""}
                        />
                      </div>
                      <div className="profile_picSec">
                        <div className="user_pro_pic">
                          <img
                            src={
                              userDetailParam?.profile?.url
                                ? userDetailParam?.profile?.url
                                : defaultUser
                            }
                            alt="alpha regiment user"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {editable && (
                    <CustomButton
                      onClick={handleUserUpdate}
                      title={
                        updateUserDetailsLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Update Affiliate"
                        )
                      }
                      className="create_affilaite_btn"
                      disabled={updateUserDetailsLoading ? true : false}
                    />
                  )}
                </div>

                <div className="view_aff_bank">
                  <div className="title">
                    <h2>Affiliate Bank Details:</h2>
                  </div>

                  <div className="bank_dtls_content">
                    <CustomInput
                      label="Bank Name"
                      type="text"
                      placeholder="Bank Name"
                      value={userDetailParam?.aff_bank_name}
                      readOnly={true}
                    />
                    <CustomInput
                      label="IFSC Code"
                      type="text"
                      placeholder="IFSC Code"
                      value={userDetailParam?.aff_bank_ifsc}
                      readOnly={true}
                    />
                    <CustomInput
                      label="Bank Account Number"
                      type="text"
                      placeholder="Bank Account Number"
                      value={userDetailParam?.aff_bank_acc_no}
                      readOnly={true}
                    />
                    <CustomInput
                      label="Bank Branch"
                      type="text"
                      placeholder="Bank Branch"
                      value={userDetailParam?.aff_bank_branch}
                      readOnly={true}
                    />
                    <CustomInput
                      label="Registered Mob Number"
                      type="text"
                      placeholder="Registered Mob Number"
                      value={userDetailParam?.aff_bank_reg_mob}
                      readOnly={true}
                    />
                    <CustomInput
                      label="UPI Id"
                      type="text"
                      placeholder="UPI Id"
                      value={userDetailParam?.aff_upi_id}
                      readOnly={true}
                    />
                  </div>
                </div>

                {/* </div> */}
              </section>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewEditAffiliate;
