import React, { Fragment } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import PageTitle from "../../components/PageTitle";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

const CreateSsbCourseList = () => {
  const loading = false;
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_course_main">
            <div className="container">
              <PageTitle title="Create SSB Course" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search Course"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />
                </div>
              </div>
              <div className="txt_color txt_center">
                <p>You're not able to create course, we're working on it.</p>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateSsbCourseList;
