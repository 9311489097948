import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_BLOG_ERROR,
  CLEAR_BLOG_STATE,
  CLEAR_DELETE_BLOG_STATE,
  CREATE_BLOG_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  GET_BLOGS_FAIL,
  GET_BLOGS_REQUEST,
  GET_BLOGS_SUCCESS,
  GET_BLOG_BY_ID_FAIL,
  GET_BLOG_BY_ID_REQUEST,
  GET_BLOG_BY_ID_SUCCESS,
  UPDATE_BLOG_FAIL,
  UPDATE_BLOG_REQUEST,
  UPDATE_BLOG_SUCCESS,
} from "../constants/blogConstant";

// CREATE NEW BATCH
export const createNewBlog =
  (
    title,
    shortDescription,
    metaDescription,
    metaTitle,
    image,
    blogContent,
    authorId,
    socialLinks
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_BLOG_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-blog`,
        {
          title,
          shortDescription,
          metaDescription,
          metaTitle,
          image,
          blogContent,
          authorId,
          socialLinks,
        },
        config
      );

      dispatch({
        type: CREATE_BLOG_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_BLOG_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getAllBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOGS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/blogs`, config);

    dispatch({
      type: GET_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOGS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getBlogById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOG_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/blog/${id}`, config);

    dispatch({
      type: GET_BLOG_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOG_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE BLOG ACTION

export const updateBlog =
  (
    id,
    title,
    shortDescription,
    metaDescription,
    metaTitle,
    image,
    blogContent,
    authorId,
    socialLinksArray
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_BLOG_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/blog/${id}`,
        {
          title,
          shortDescription,
          metaDescription,
          metaTitle,
          image,
          blogContent,
          authorId,
          socialLinksArray,
        },
        config
      );

      dispatch({
        type: UPDATE_BLOG_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BLOG_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE USER PROFILE DETAILS
export const deleteBlogByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BLOG_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/blog/${id}`, config);

    dispatch({
      type: DELETE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearBlogErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_BLOG_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearBlogState = () => async (dispatch) => {
  dispatch({ type: CLEAR_BLOG_STATE });
};

export const clearDeleteBlogState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_BLOG_STATE });
};
