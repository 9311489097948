import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  activateUserReducer,
  createAffiliateByAdmin,
  createNewUserReducer,
  createTeacherByAdmin,
  deleteUserByAdmin,
  getAllOfficersReducer,
  getUserDetailsByAdmin,
  getUsersByAdminReducer,
  inactivateUserReducer,
  seatBookingEligibilityReducer,
  updateUserDetailsByAdmin,
  userLoginReducer,
  userProfileReducer,
} from "./reducers/userReducer";
import {
  createCourseDetails,
  createCourseReducer,
  deleteCourseReducer,
  getAllCourses,
  getCourseById,
  getCourseLongDetails,
  getCourseMetaDetails,
  updateCourseMetaTags,
  updateCourseReducer,
} from "./reducers/courseReducer";
import {
  createBatchReducer,
  deleteBatchByAdmin,
  getBatchesByIdReducer,
  getBatchesReducer,
} from "./reducers/batchReducer";
import {
  createTextTestimonialsReducer,
  deleteTextTestimonialReducer,
  getTextTestimonialByIdReducer,
  getTextTestimonialsReducer,
  updateTextTestimonialReducer,
} from "./reducers/textTextiReducer";
import { allStatsReducer } from "./reducers/statsReducer";
import {
  allVideoTestimonialReducer,
  createVideoTestimonialsReducer,
  deleteVideoTestimonialReducer,
  getVideoTestimonialByIdReducer,
  updateVideoTestimonialReducer,
} from "./reducers/vidTestiReducer";
import {
  deleteSsbCourseReducer,
  getSsbCoursesReducer,
  pauseSsbCourseReducer,
  verifySsbCourseReducer,
} from "./reducers/ssbCourseReducer";
import {
  createBlogReducer,
  deleteBlogByAdmin,
  getBlogByIdReducer,
  getBlogsReducer,
  updateBlogDataReducer,
} from "./reducers/blogReducer";
import {
  createVideoReducer,
  deleteVideoByAdmin,
  getVideoBYIdReducer,
  getVideosReducer,
  updateVideoDataReducer,
} from "./reducers/gptVideoReducer";

// get user from sessionStorage
const userFromLocalStorage = sessionStorage.getItem("alphaAdminToken")
  ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  createCourse: createCourseReducer,
  getAllCourses: getAllCourses,
  deleteCourse: deleteCourseReducer,
  getCourseById: getCourseById,
  updateCourse: updateCourseReducer,
  createCourseDetails: createCourseDetails,
  getCourseLongDetails: getCourseLongDetails,
  updateCourseMeta: updateCourseMetaTags,
  getCourseMetaDetails: getCourseMetaDetails,
  getUsersByAdmin: getUsersByAdminReducer,
  createAffiliate: createAffiliateByAdmin,
  deleteUser: deleteUserByAdmin,
  getUserDetails: getUserDetailsByAdmin,
  updateUserDetails: updateUserDetailsByAdmin,
  createTeacher: createTeacherByAdmin,
  createBatch: createBatchReducer,
  getBatches: getBatchesReducer,
  deleteBatch: deleteBatchByAdmin,
  getBatchesById: getBatchesByIdReducer,
  createTextTestimonial: createTextTestimonialsReducer,
  getTextTestimonials: getTextTestimonialsReducer,
  deleteTextTestimonial: deleteTextTestimonialReducer,
  getTextTestimonialById: getTextTestimonialByIdReducer,
  updateTextTestimonial: updateTextTestimonialReducer,
  allStats: allStatsReducer,
  createVideoTestimonial: createVideoTestimonialsReducer,
  allVideoTestimonial: allVideoTestimonialReducer,
  deleteVideoTestimonial: deleteVideoTestimonialReducer,
  getVideoTestimonialById: getVideoTestimonialByIdReducer,
  updateVideoTestimonial: updateVideoTestimonialReducer,
  getSsbCourses: getSsbCoursesReducer,
  pauseSsbCourse: pauseSsbCourseReducer,
  deleteSsbCourse: deleteSsbCourseReducer,
  allOfficersReducer: getAllOfficersReducer,
  activateUserReducer: activateUserReducer,
  inactivateUserReducer: inactivateUserReducer,
  createBlog: createBlogReducer,
  getBlogs: getBlogsReducer,
  getBlogById: getBlogByIdReducer,
  deleteBlog: deleteBlogByAdmin,
  updateBlogData: updateBlogDataReducer,

  createVideo: createVideoReducer,
  getVideos: getVideosReducer,
  getVideoByID: getVideoBYIdReducer,
  updateVideoData: updateVideoDataReducer,
  deleteVideo: deleteVideoByAdmin,
  
  verifySsbCourse: verifySsbCourseReducer,
  seatBookingEligibility: seatBookingEligibilityReducer,
  createNewUserByAdmin: createNewUserReducer,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
