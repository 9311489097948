import {
  CLEAR_ERROR,
  CLEAR_STATE,
  COURSE_BY_ID_FAIL,
  COURSE_BY_ID_REQUEST,
  COURSE_BY_ID_SUCCESS,
  CREATE_COURSE_FAIL,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  GET_ALL_COURSE_FAIL,
  GET_ALL_COURSE_REQUEST,
  GET_ALL_COURSE_SUCCESS,
  GET_COURSE_META_FAIL,
  GET_COURSE_META_REQUEST,
  GET_COURSE_META_SUCCESS,
  GET_CRS_LONG_DTLS_FAIL,
  GET_CRS_LONG_DTLS_REQUEST,
  GET_CRS_LONG_DTLS_SUCCESS,
  UPDATE_COURSE_DETAILS_FAIL,
  UPDATE_COURSE_DETAILS_REQUEST,
  UPDATE_COURSE_DETAILS_SUCCESS,
  UPDATE_COURSE_FAIL,
  UPDATE_COURSE_META_FAIL,
  UPDATE_COURSE_META_REQUEST,
  UPDATE_COURSE_META_SUCCESS,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
} from "../constants/courseConstant";

export const createCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COURSE_REQUEST:
      return {
        loading: true,
        newCourse: {},
      };
    case CREATE_COURSE_SUCCESS:
      return {
        loading: false,
        newCourse: action.payload,
      };
    case CREATE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        newCourse: {},
      };

    default:
      return state;
  }
};

export const getAllCourses = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_COURSE_REQUEST:
      return {
        loading: true,
        courses: [],
      };
    case GET_ALL_COURSE_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case GET_ALL_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getCourseById = (state = {}, action) => {
  switch (action.type) {
    case COURSE_BY_ID_REQUEST:
      return {
        loading: true,
        course: {},
      };
    case COURSE_BY_ID_SUCCESS:
      return {
        loading: false,
        course: action.payload,
      };
    case COURSE_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        course: null,
      };

    default:
      return state;
  }
};

export const updateCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COURSE_REQUEST:
      return {
        loading: true,
        courseUpdate: {},
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        loading: false,
        courseUpdate: action.payload,
      };
    case UPDATE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
        courseUpdate: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courseUpdate: {},
      };

    default:
      return state;
  }
};

export const deleteCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COURSE_REQUEST:
      return {
        loading: true,
        courses: [],
      };
    case DELETE_COURSE_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case DELETE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courses: null,
      };

    default:
      return state;
  }
};

export const createCourseDetails = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COURSE_DETAILS_REQUEST:
      return {
        loading: true,
        courseDetails: {},
      };
    case UPDATE_COURSE_DETAILS_SUCCESS:
      return {
        loading: false,
        courseDetails: action.payload,
      };
    case UPDATE_COURSE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
        courseDetails: {},
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courseDetails: {},
      };

    default:
      return state;
  }
};

export const getCourseLongDetails = (state = {}, action) => {
  switch (action.type) {
    case GET_CRS_LONG_DTLS_REQUEST:
      return {
        loading: true,
        courseLongDetails: {},
      };
    case GET_CRS_LONG_DTLS_SUCCESS:
      return {
        loading: false,
        courseLongDetails: action.payload,
      };
    case GET_CRS_LONG_DTLS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
        courseLongDetails: {},
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courseLongDetails: {},
      };

    default:
      return state;
  }
};

export const updateCourseMetaTags = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COURSE_META_REQUEST:
      return {
        loading: true,
        metaTags: {},
      };
    case UPDATE_COURSE_META_SUCCESS:
      return {
        loading: false,
        metaTags: action.payload,
      };
    case UPDATE_COURSE_META_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
        metaTags: {},
      };

    default:
      return state;
  }
};


export const getCourseMetaDetails = (state = {}, action) => {
  switch (action.type) {
    case GET_COURSE_META_REQUEST:
      return {
        loading: true,
        courseMetaDetails: {},
      };
    case GET_COURSE_META_SUCCESS:
      return {
        loading: false,
        courseMetaDetails: action.payload,
      };
    case GET_COURSE_META_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
        courseMetaDetails: {},
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        courseMetaDetails: {},
      };

    default:
      return state;
  }
};