import React, { Fragment } from "react";
import "./App.css";
import { Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AllBatchesList from "./pages/batches/AllBatchesList";
import CreateNewBatch from "./pages/batches/CreateNewBatch";
import Login from "./pages/Login";
import PageNotFound404 from "./pages/PageNotFound404";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import RedirectSpinner from "./components/RedirectSpinner";
import { clearState } from "./redux/actions/userAction";
import AllStudents from "./pages/students/AllStudents";
import AllTeachersList from "./pages/teachers/AllTeachersList";
import AllCoachesList from "./pages/coaches/AllCoachesList";
import AllNoticesList from "./pages/noticeBoard/AllNoticesList";
import AllSyllabusList from "./pages/syllabus/AllSyllabusList";
import AllAttendanceList from "./pages/attendance/AllAttendanceList";
import AllMedicalList from "./pages/medical/AllMedicalList";
import AllCourseList from "./pages/course/AllCourseList";
import SsbCourseList from "./pages/ssb-courses/SsbCourseList";
import AllMCQsList from "./pages/MCQs/AllMCQsList";
import ViewEditBatch from "./pages/batches/ViewEditBatch";
import ScrollToTop from "./components/ScrollToTop";
import ViewEditStudent from "./pages/students/ViewEditStudent";
import CreateCourse from "./pages/course/CreateCourse";
import ViewEditCourse from "./pages/course/ViewEditCourse.js";
import AllAffiliates from "./pages/affiliate/AllAffiliates";
import CreateAffiliate from "./pages/affiliate/CreateAffiliate";
import ViewEditAffiliate from "./pages/affiliate/ViewEditAffiliate";
import ViewEditTeacher from "./pages/teachers/ViewEditTeacher";
import CreateTeacher from "./pages/teachers/CreateTeacher";
import TextTestimonialList from "./pages/textTestimonials/TextTestimonialList";
import CreateTextTesti from "./pages/textTestimonials/CreateTextTesti";
import ViewEditTextTestimonial from "./pages/textTestimonials/ViewEditTextTestimonial";
import CreateVideoTestimonial from "./pages/videoTestimonial/CreateVideoTestimonial";
import VideoTestimonialList from "./pages/videoTestimonial/VideoTestimonialList";
import ViewEditVideoTestimonial from "./pages/videoTestimonial/ViewEditVideoTestimonial";
import AllOfficersList from "./pages/officer/AllOfficersList.js";
import BlogList from "./pages/blogs/BlogList.js";
import CreateNewBlog from "./pages/blogs/CreateNewBlog.js";
import EditBlog from "./pages/blogs/EditBlog.js";
import AllAdminList from "./pages/admins/AdminList.js";
import CreateNewAdmin from "./pages/admins/CreateAdmin.js";
import AllVideoList from "./pages/gptVideo/VideoList.js";
import CreateNewVideo from "./pages/gptVideo/CreateVideo.js";
import UpdateVideo from "./pages/gptVideo/UpdateVideo.js";
import NotificationPrompt from "./components/NotificationPrompt";
import DoctorsList from "./pages/doctor/DoctorsList.js";
import CreateNewDoctor from "./pages/doctor/CreateNewDoctor.js";
import ViewEditDoctor from "./pages/doctor/ViewEditDoctor.js";
import CreateSsbCourseList from "./pages/ssb-courses/CreateSsbCourseList.js";
// import { CLEAR_STATE } from "./redux/constants/userConstant";

const App = () => {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );

  const userRole = userInfo?.user?.role;

  let authenticatedAdmin = false;

  if (isAuthenticated && userRole === "10") {
    authenticatedAdmin = true;
  } else if (isAuthenticated && userRole !== "10") {
    authenticatedAdmin = false;
    sessionStorage.removeItem("alphaAdminToken");
    dispatch(clearState());
  }

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg_color txt_color">
          <ScrollToTop />
          <NotificationPrompt />
          <Routes>
            <Route exact path="/" element={<Login />} />

            <Route
              path="/admin"
              element={authenticatedAdmin ? <Outlet /> : <RedirectSpinner />}
            >
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="all-batches" element={<AllBatchesList />} />
              <Route path="create-batch" element={<CreateNewBatch />} />
              <Route path="view-edit-batch/:id" element={<ViewEditBatch />} />

              <Route path="all-students" element={<AllStudents />} />
              <Route
                path="view-edit-student/:id"
                element={<ViewEditStudent />}
              />

              <Route path="all-teachers" element={<AllTeachersList />} />
              <Route path="create-teacher" element={<CreateTeacher />} />
              <Route
                path="view-edit-teacher/:id"
                element={<ViewEditTeacher />}
              />

              <Route path="all-admin" element={<AllAdminList />} />
              <Route path="create-admin" element={<CreateNewAdmin />} />

              <Route path="all-officers" element={<AllOfficersList />} />

              <Route path="all-coaches" element={<AllCoachesList />} />

              <Route path="all-notices" element={<AllNoticesList />} />

              <Route path="all-syllabus" element={<AllSyllabusList />} />

              <Route path="all-attendance" element={<AllAttendanceList />} />

              <Route path="all-medical" element={<AllMedicalList />} />

              {/* AFFILIATE PAGES START */}
              <Route path="all-affiliate" element={<AllAffiliates />} />
              <Route path="create-affiliate" element={<CreateAffiliate />} />
              <Route
                path="view-edit-affiliate/:id"
                element={<ViewEditAffiliate />}
              />
              {/* AFFILIATE PAGES ENDS */}

              {/* COURSE PAGES START */}
              <Route path="all-course" element={<AllCourseList />} />
              <Route path="create-course" element={<CreateCourse />} />
              <Route path="view-edit-course/:id" element={<ViewEditCourse />} />
              {/* COURSE PAGES ENDS */}

              {/* SSB COURSES START */}
              <Route path="ssb-course" element={<SsbCourseList />} />
              <Route
                path="create-ssb-course"
                element={<CreateSsbCourseList />}
              />
              {/* SSB COURSES ENDS */}

              {/* TEXT TESTIMONIALS START */}
              <Route
                path="text-testimonial"
                element={<TextTestimonialList />}
              />
              <Route
                path="create-text-testimonial"
                element={<CreateTextTesti />}
              />
              <Route
                path="text-testimonial/:id"
                element={<ViewEditTextTestimonial />}
              />
              {/* TEXT TESTIMONIALS ENDS */}

              {/* VIDEO TESTIMONIALS START */}
              <Route
                path="video-testimonial"
                element={<VideoTestimonialList />}
              />
              <Route
                path="create-video-testimonial"
                element={<CreateVideoTestimonial />}
              />
              <Route
                path="video-testimonial/:id"
                element={<ViewEditVideoTestimonial />}
              />

              {/* VIDEO TESTIMONIALS ENDS */}

              <Route path="all-mcqs" element={<AllMCQsList />} />

              {/* BLOG LIST */}

              <Route path="blogs" element={<BlogList />} />
              <Route path="create-blog" element={<CreateNewBlog />} />
              <Route path="view-edit-blog/:id" element={<EditBlog />} />


              <Route path="videos" element={<AllVideoList />} />
              <Route path="create-video" element={<CreateNewVideo />} />
              <Route path="view-edit-video/:id" element={<UpdateVideo />} />
              
              
              {/* DOCTOR ROUTES START */}
              <Route path="all-doctors" element={<DoctorsList />} />
              <Route path="create-new-doctor" element={<CreateNewDoctor />} />
              <Route path="view-edit-doctor/:id" element={<ViewEditDoctor />} />
              {/* DOCTOR ROUTES ENDS */}
            </Route>

            {/* Redirect to */}
            <Route path="*" element={<PageNotFound404 />} />
          </Routes>
        </div>
      )}
    </Fragment>
  );
};

export default App;
