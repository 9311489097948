import React from "react";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";

const ViewEditDoctor = () => {
  const loading = false;
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_batches_main">
            <div className="container">
              <PageTitle title="View/Edit Doctor" />
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewEditDoctor;
