import React from "react";
import "../../styles/createVideoTestimonial.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";
import { useEffect } from "react";
import PopupPrompt from "../../components/PopupPrompt";
import CustomMultiSelect from "../../components/CustomMultiSelect";

import {
  clearState,
  createVideoTestimonial,
  clearErrors as clearTestimonialErrors,
} from "../../redux/actions/vidTestiAction";
import { useNotification } from "../../context/notificationContext";

const CreateVideoTestimonial = () => {
  const [createModal, setCreateModal] = useState(false);
  const dispatch = useDispatch();
  const [thumbnail, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();
  const [, , showNotification] = useNotification();

  // STORED STATE FOR TESTIMONIAL CREATION DATA
  const [selectedCourses, setSelectedCourses] = useState([]);
  let forCourse = [];
  selectedCourses.forEach((item) => {
    forCourse.push({ label: item?.label, value: item?.value });
  });

  const [testimonialData, setTestimonialData] = useState({
    videoUrl: "",
  });

  const { videoUrl } = testimonialData;
  // COURSE DATA DESTURUCTURE

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setTestimonialData({
        ...testimonialData,
        [name]: value,
      });
    }
  };

  // CREATE VIDEO TESTIMONIAL START
  // CREATE VIDEO TESTIMONIAL START
  const { loading, testimonial, error } = useSelector(
    (state) => state.createVideoTestimonial
  );

  const handleCreateTestimnonial = () => {
    dispatch(createVideoTestimonial(videoUrl, thumbnail, forCourse));
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearTestimonialErrors());
    }

    if (testimonial?.success) {
      showNotification("success", testimonial?.message);
      dispatch(clearState());
      setCreateModal(false);
    }
  }, [dispatch, error, testimonial?.success, testimonial?.message, showNotification]);

  // CREATE VIDEO TESTIMONIAL ENDS
  // CREATE VIDEO TESTIMONIAL ENDS

  // GET ALL COURSES DATA START
  // GET ALL COURSES DATA START
  const {
    loading: coursesLoading,
    courses,
    error: coursesError,
  } = useSelector((state) => state.getAllCourses);

  const allCourses = courses?.courses;

  let courseOptions = []; // STORE ALL COURSES IN THIS PARAM
  allCourses?.forEach((item) => {
    courseOptions.push({ label: item?.title, value: item?._id });
  });

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (coursesError) {
      showNotification("error", coursesError);
      dispatch(clearErrors());
    }
  }, [dispatch, coursesError, showNotification]);
  // GET ALL COURSES DATA ENDS
  // GET ALL COURSES DATA ENDS

  return (
    <Layout>
      <div className="create_videoTesti_main">
        <div className="container">
          <div className="title_header">
            <PageTitle title="Create Video Testimonial" />
          </div>

          <div className="create_batch_data">
            <div className="batch_form">
              <CustomMultiSelect
                label="For Course"
                value={selectedCourses}
                onChange={(val) => setSelectedCourses(val)}
                isMulti={true}
                optionArray={
                  coursesLoading
                    ? [{ label: "Please wait..", value: "" }]
                    : courseOptions
                }
              />
              <CustomInput
                label="Video Url (embed Url)"
                type="text"
                placeholder="https://www.youtube.com/embed/0Ccx7luwcoM"
                name="videoUrl"
                onChange={handleInputChange}
                value={videoUrl}
                impStar="*"
              />
            </div>
            <div className="course_summary">
              {avatarPreview && (
                <img
                  src={avatarPreview}
                  alt={avatarPreview}
                  width="100%"
                  height="200px"
                  style={{
                    marginBottom: "15px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "16px",
                    objectFit: "contain",
                  }}
                />
              )}
              <CustomInput
                label="Course Thumbnail"
                type="file"
                name="thumbnail"
                accept="image/*"
                onChange={handleInputChange}
                impStar="*"
              />
            </div>
            <CustomButton
              className="create_course_btn"
              title="Create"
              onClick={() => setCreateModal(true)}
            />
          </div>
        </div>
      </div>

      {/* POPUP PROMPT START */}
      {createModal && (
        <PopupPrompt
          confirmOnclick={handleCreateTestimnonial}
          cancelOnclick={() => setCreateModal(false)}
          title={`Do you really want to create the Video Testimonial?`}
          loading={loading}
        />
      )}

      {/* POPUP PROMPT ENDS */}
    </Layout>
  );
};

export default CreateVideoTestimonial;
