import React, { Fragment, useEffect } from "react";
import "../styles/dashboard.scss";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import ServiceCard from "../components/dashboard/ServiceCard";
// import { dashboardCardContent } from "../dummyContent";
import {
  affiliateIcon,
  attendanceIcon,
  batches,
  bookIcon,
  coachIcon,
  ecommIcon,
  enrollIcon,
  listIcon,
  mcqIcon,
  medicalIcon,
  noticeIcon,
  studentIcon,
  teacherIcon,
} from "../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllStats } from "../redux/actions/statsAction";
import Loader from "../components/Loader";
import { useNotification } from "../context/notificationContext";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // GET STATS START
  // GET STATS START
  const { loading, data, error } = useSelector((state) => state.allStats);

  useEffect(() => {
    dispatch(getAllStats());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, dispatch, showNotification]);
  // GET STATS ENDS
  // GET STATS ENDS

  const dashboardCardContent = [
    {
      id: 22,
      title: "Manage Admins",
      icon: batches,
      numbers: `${data?.admins ? data?.admins : 0}`,
      subTitle: "admins",
      buttonTitle: "Manage",
      link: "/admin/all-admin",
      buttonAction: "/admin/all-admin",
    },
    {
      id: 1,
      title: "Batches",
      icon: batches,
      numbers: `${data?.batches}`,
      subTitle: "batches",
      buttonTitle: "Create new",
      link: "/admin/all-batches",
      buttonAction: "/admin/create-batch",
    },
    {
      id: 2,
      title: "Student",
      icon: studentIcon,
      numbers: `${data?.student}`,
      subTitle: " students",
      buttonTitle: "All Students",
      buttonAction: "/admin/all-students",
      link: "/admin/all-students",
    },
    {
      id: 3,
      title: "Teacher",
      icon: teacherIcon,
      numbers: `${data?.teacher + data?.otherTeacher}`,
      subTitle: "Active faculty",
      link: "/admin/all-teachers",
      buttonTitle: "Add new",
      buttonAction: "/admin/create-teacher",
    },
    {
      id: 4,
      title: "Officers",
      icon: teacherIcon,
      numbers: `${data?.psychologist + data?.gto + data?.io}`,
      subTitle: "Officers",
      link: "/admin/all-officers",
      buttonTitle: "Add new",
      buttonAction: "/admin/all-officers",
    },
    {
      id: 5,
      title: "Doctors",
      icon: coachIcon,
      numbers: `${data?.doctors}`,
      subTitle: "Active doctors",
      link: "/admin/all-doctors",
      buttonTitle: "Add New",
    },
    {
      id: 6,
      title: "Coach",
      icon: coachIcon,
      numbers: "10",
      subTitle: "Active coach",
      link: "/admin/all-coaches",
      buttonTitle: "Add new",
    },
    {
      id: 7,
      title: "Time table",
      icon: coachIcon,
      numbers: "10",
      subTitle: "Active coach",
      buttonTitle: "Add new",
    },
    {
      id: 8,
      title: "Notice board",
      icon: noticeIcon,
      numbers: "10",
      subTitle: "Published notice",
      link: "/admin/all-notices",
      buttonTitle: "Create now",
    },
    {
      id: 9,
      title: "Syllabus",
      icon: studentIcon,
      numbers: "",
      subTitle: "Syllabus of all existing courses",
      link: "/admin/all-syllabus",
      buttonTitle: "Add new",
    },
    {
      id: 10,
      title: "Attendance",
      icon: attendanceIcon,
      numbers: "",
      subTitle: "Mark attendance according to the dates",
      link: "/admin/all-attendance",
      buttonTitle: "Mark new",
    },
    {
      id: 11,
      title: "Enrollments",
      icon: enrollIcon,
      numbers: "12",
      subTitle: "Latest enrollments",
      buttonTitle: "Add new",
    },
    {
      id: 12,
      title: "Subjects",
      icon: listIcon,
      numbers: "16",
      subTitle: "Existing courses",
      buttonTitle: "Add new",
    },
    {
      id: 13,
      title: "Medical",
      icon: medicalIcon,
      numbers: "",
      subTitle: "Upload medical analysis report of a student",
      link: "/admin/all-medical",
      buttonTitle: "Upload new",
    },
    {
      id: 14,
      title: "Courses",
      icon: listIcon,
      numbers: `${data?.courses}`,
      subTitle: "Existing courses",
      link: "/admin/all-course",
      buttonTitle: "Add new",
      buttonAction: "/admin/create-course",
    },
    {
      id: 15,
      title: "MCQs",
      icon: mcqIcon,
      numbers: "12",
      subTitle: "Active quizzes",
      link: "/admin/all-mcqs",
      buttonTitle: "Add new",
    },
    {
      id: 16,
      title: "Study material",
      icon: bookIcon,
      subTitle: "Add study material ",
      buttonTitle: "Add",
    },
    {
      id: 17,
      title: "Affiliate",
      icon: affiliateIcon,
      numbers: "",
      subTitle: "Manage affiliation",
      link: "/admin/all-affiliate",
      buttonTitle: "Manage",
      buttonAction: "/admin/all-affiliate",
    },
    {
      id: 18,
      title: "Ecommerce",
      icon: ecommIcon,
      numbers: "",
      subTitle: "Manage ecommerce",
      buttonTitle: "Manage",
    },
    {
      id: 19,
      title: "Text Testimonials",
      icon: ecommIcon,
      numbers: `${data?.textTestimonialLength}`,
      subTitle: "Active Testimonials",
      buttonAction: "/admin/text-testimonial",
      link: "/admin/text-testimonial",
      buttonTitle: "Manage",
    },
    {
      id: 20,
      title: "Video Testimonials",
      icon: ecommIcon,
      numbers: `${data?.videoTestimonialLength}`,
      subTitle: "Active Testimonials",
      buttonTitle: "Manage",
      buttonAction: "/admin/video-testimonial",
      link: "/admin/video-testimonial",
    },
    {
      id: 21,
      title: "SSB Courses",
      icon: ecommIcon,
      numbers: `${data?.ssbCourses}`,
      subTitle: "SSB Courses",
      buttonTitle: "Manage",
      buttonAction: "/admin/ssb-course",
      link: "/admin/ssb-course",
    },
    {
      id: 22,
      title: "Blogs",
      icon: ecommIcon,
      numbers: `${data?.allblogs ? data?.allblogs : "0"}`,
      subTitle: "Blogs",
      buttonTitle: "Manage",
      buttonAction: "/admin/blogs",
      link: "/admin/blogs",
    },
    {
      id: 21,
      title: "SSB GPT Video",
      icon: ecommIcon,
      numbers: `${data?.videos ? data?.videos : 0}`,
      subTitle: "Videos",
      buttonTitle: "Manage",
      buttonAction: "/admin/videos",
      link: "/admin/videos",
    },
  ];

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="dashboard_main_page">
            <div className="container">
              <PageTitle title="Admin Dashboard" goBack={false} />

              <div className="main_card_content">
                {dashboardCardContent?.map((item, idx) => (
                  <ServiceCard
                    key={idx}
                    link={item.link}
                    title={item.title}
                    icon={item.icon}
                    numbers={item.numbers}
                    subtitle={item.subTitle}
                    buttonTitle={item.buttonTitle}
                    buttonAction={item.buttonAction}
                  />
                ))}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default Dashboard;
