import React from "react";
import "../../styles/allMedicalList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";

const AllMedicalList = () => {
  const dummyBatches = [1, 2, 3, 3, 3, 32, 3, 4, 234, 32, 4, 32];
  return (
    <Layout>
      <div className="all_medical_main">
        <div className="container">
          <PageTitle title="Medical" />

          <div className="all_batch_list">
            <div className="batch_listHeader">
              <CustomInput
                icon={<BsSearch />}
                type="search"
                placeholder="Search medical"
                name="searchBatch"
                //   onChange=""
                //   value=""
              />

              <button
                className="add_batch_button"
                // onClick={() => navigate("/admin/create-batch")}
              >
                <AiOutlinePlus />
              </button>
            </div>

            <div className="table_data custonScroll">
              <table className="list_table" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Student name</th>
                    <th>Email</th>
                    <th>Contact No.</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dummyBatches.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p>Vishesh Kesharwani</p>
                        </td>
                        <td>
                          <p>t101@gmail.com</p>
                        </td>
                        <td>
                          <p>9632528741</p>
                        </td>
                        <td>
                          <div>
                            <button className="attendance_btn">
                              Upload report
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllMedicalList;
