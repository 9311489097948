import { axiosInstance } from "../../utils/axiosConfig";

import {
  CLEAR_DELETE_VIDEO_STATE,
  CLEAR_VIDEO_ERROR,
  CLEAR_VIDEO_STATE,
  CREATE_VIDEO_FAIL,
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  GET_VIDEO_BY_ID_FAIL,
  GET_VIDEO_BY_ID_REQUEST,
  GET_VIDEO_BY_ID_SUCCESS,
  GET_VIDEOS_FAIL,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  UPDATE_VIDEO_FAIL,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
} from "../constants/gptVideoConstant";

// CREATE NEW BATCH
export const createNewVideo = (title, url, tags) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_VIDEO_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };
    const { data } = await axiosInstance.post(
      `/create-video-link`,
      {
        title,
        url,
        tags,
      },
      config
    );

    dispatch({
      type: CREATE_VIDEO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_VIDEO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL BATCHES
export const getAllVideos = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_VIDEOS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-created-video-link`, config);

    dispatch({
      type: GET_VIDEOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_VIDEOS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getVideoById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VIDEO_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/created-video/${id}`, config);

    dispatch({
      type: GET_VIDEO_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_VIDEO_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE BLOG ACTION

export const updateVideo = (id, title, url, tags) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_VIDEO_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/update-my-video/${id}`,
      {
        title,
        url,
        tags,
      },
      config
    );

    dispatch({
      type: UPDATE_VIDEO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VIDEO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE USER PROFILE DETAILS
export const deleteVideoByAdminAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_VIDEO_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-my-video/${id}`,
      config
    );

    dispatch({
      type: DELETE_VIDEO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VIDEO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearVideoErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_VIDEO_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearVideoState = () => async (dispatch) => {
  dispatch({ type: CLEAR_VIDEO_STATE });
};

export const clearDeleteVideoState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_VIDEO_STATE });
};
