import React from "react";
import "../styles/toggleSwitch.scss";
import styled from "styled-components";

const SwitcherBtn = styled.div`
  input:before {
    content: "ON";
    left: 12px;
  }

  input:after {
    content: "OFF";
    right: 12px;
  }
`;

const ToggleSwitch = ({ onClick, checked, onChange, disabled }) => {
  return (
    <SwitcherBtn className="langSwitch_main">
      <input
        type="checkbox"
        id="switcher"
        onClick={onClick}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor="switcher"></label>
    </SwitcherBtn>
  );
};

export default ToggleSwitch;
