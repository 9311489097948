import React from "react";
import "../../styles/createNewCourse.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { useState } from "react";
import CustomTextarea from "../../components/CustomTextarea";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";
import { useEffect } from "react";
import PopupPrompt from "../../components/PopupPrompt";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import {
  clearState,
  clearErrors as clearTestimonialErrors,
  createTextTestimonial,
} from "../../redux/actions/textTextiAction";
import { useNotification } from "../../context/notificationContext";

const CreateTextTesti = () => {
  const [createModal, setCreateModal] = useState(false);
  const dispatch = useDispatch();
  const [thumbnail, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();
  const [, , showNotification] = useNotification();

  // STORED STATE FOR TESTIMONIAL CREATION DATA
  const [selectedCourses, setSelectedCourses] = useState([]);
  let forCourse = [];
  selectedCourses.forEach((item) => {
    forCourse.push({ label: item?.label, value: item?.value });
  });

  console.log("forCourse", forCourse);
  const [testimonialData, setTestimonialData] = useState({
    title: "",
    selectedIn: "",
    givenStar: "",
    textHindi: "",
    textEnglish: "",
  });

  const { title, selectedIn, givenStar, textHindi, textEnglish } =
    testimonialData;
  // COURSE DATA DESTURUCTURE

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setTestimonialData({
        ...testimonialData,
        [name]: value,
      });
    }
  };

  // CREATE TEXT TESTIMONIAL START
  // CREATE TEXT TESTIMONIAL START
  const { loading, testimonial, error } = useSelector(
    (state) => state.createTextTestimonial
  );

  const handleCreateTestimnonial = () => {
    dispatch(
      createTextTestimonial(
        title,
        selectedIn,
        givenStar,
        textHindi,
        textEnglish,
        thumbnail,
        forCourse
      )
    );
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearTestimonialErrors());
    }

    if (testimonial?.success) {
      showNotification("success", testimonial?.message);
      dispatch(clearState());
      setCreateModal(false);
    }
  }, [dispatch, error, testimonial?.success, testimonial?.message, showNotification]);

  // CREATE TEXT TESTIMONIAL ENDS
  // CREATE TEXT TESTIMONIAL ENDS

  // GET ALL COURSES DATA START
  // GET ALL COURSES DATA START
  const {
    loading: coursesLoading,
    courses,
    error: coursesError,
  } = useSelector((state) => state.getAllCourses);

  const allCourses = courses?.courses;

  let courseOptions = []; // STORE ALL COURSES IN THIS PARAM
  allCourses?.forEach((item) => {
    courseOptions.push({ label: item?.title, value: item?._id });
  });

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (coursesError) {
      showNotification("error", coursesError);
      dispatch(clearErrors());
    }
  }, [dispatch, coursesError, showNotification]);
  // GET ALL COURSES DATA ENDS
  // GET ALL COURSES DATA ENDS

  return (
    <Layout>
      <div className="create_course_main">
        <div className="container">
          <div className="title_header">
            <PageTitle title="Create New Text Testimonial" />
          </div>

          <div className="create_batch_data">
            <div className="batch_form">
              <CustomMultiSelect
                label="For Course"
                value={selectedCourses}
                onChange={(val) => setSelectedCourses(val)}
                isMulti={true}
                optionArray={
                  coursesLoading
                    ? [{ label: "Please wait..", value: "" }]
                    : courseOptions
                }
              />
              <CustomInput
                label="Title"
                type="text"
                placeholder="Suresh Kumar"
                name="title"
                onChange={handleInputChange}
                value={title}
                impStar="*"
              />
              <CustomInput
                label="Selected In"
                type="text"
                placeholder="Selected In Indian Navy"
                name="selectedIn"
                onChange={handleInputChange}
                value={selectedIn}
                impStar="*"
              />
              <CustomSelect
                label="Select Number Of Stars"
                name="givenStar"
                value={givenStar}
                onChange={handleInputChange}
                optionArray={[
                  { id: 1, option: 1 },
                  { id: 2, option: 2 },
                  { id: 3, option: 3 },
                  { id: 4, option: 4 },
                  { id: 5, option: 5 },
                ]}
                impStar="*"
              />
              <CustomTextarea
                label="Textimonial Text *"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="Textimonial Text..."
                name="textEnglish"
                value={textEnglish}
              />
            </div>
            <div className="course_summary">
              <CustomTextarea
                label="टेस्टीमोनियल टेक्स्ट *"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="टेस्टीमोनियल टेक्स्ट हिंदी में लिखें..."
                name="textHindi"
                value={textHindi}
              />
              {avatarPreview && (
                <img
                  src={avatarPreview}
                  alt={avatarPreview}
                  width="100%"
                  height="200px"
                  style={{
                    marginBottom: "15px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "16px",
                    objectFit: "contain",
                  }}
                />
              )}
              <CustomInput
                label="Course Thumbnail"
                type="file"
                name="thumbnail"
                accept="image/*"
                onChange={handleInputChange}
                impStar="*"
              />
            </div>
            <CustomButton
              className="create_course_btn"
              title="Create"
              onClick={() => setCreateModal(true)}
            />
          </div>
        </div>
      </div>

      {/* POPUP PROMPT START */}
      {createModal && (
        <PopupPrompt
          confirmOnclick={handleCreateTestimnonial}
          cancelOnclick={() => setCreateModal(false)}
          title={`Do you really want to create the Testimonial of ${title} ?`}
          loading={loading}
        />
      )}

      {/* POPUP PROMPT ENDS */}
    </Layout>
  );
};

export default CreateTextTesti;
