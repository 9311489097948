import React from "react";
import "../../styles/allAttendanceList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AllAttendanceList = () => {
  const navigate = useNavigate();
  const dummyBatches = [1, 2, 3, 3, 3, 32, 3, 4, 234, 32, 4, 32];
  return (
    <Layout>
      <div className="all_attendance_main">
        <div className="container">
          <PageTitle title="Attendance" />

          <div className="all_batch_list">
            <div className="batch_listHeader">
              <CustomInput
                icon={<BsSearch />}
                type="search"
                placeholder="Search attendance"
                name="searchBatch"
                //   onChange=""
                //   value=""
              />

              <button
                className="add_batch_button"
                // onClick={() => navigate("/admin/create-batch")}
              >
                <AiOutlinePlus />
              </button>
            </div>

            <div className="table_data custonScroll">
              <table className="list_table" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Batch name</th>
                    <th>Attendance Last Marked</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dummyBatches.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p>NDA_2023_Evening</p>
                        </td>
                        <td>
                          <p>Dec 21, 2022</p>
                        </td>
                        <td>
                          <div>
                            <button
                              className="attendance_btn"
                              onClick={() =>
                                navigate(`/admin/view-batch/${idx}`)
                              }
                            >
                              Mark attendance
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllAttendanceList;
