import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomTextarea from "../../components/CustomTextarea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/createBlog.scss";
import {
  clearBlogErrors,
  clearBlogState,
  createNewBlog,
} from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/ButtonLoader";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { clearErrors, getAllOfficers } from "../../redux/actions/userAction";

const CreateNewBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [blogData, setBlogData] = useState({
    title: "",
    shortDescription: "",
    metaDescription: "",
  });

  const [socialData, setSocialData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setSocialData({
      ...socialData,
      [name]: value,
    });
  };

  const [authorId, setAuthorId] = useState();
  const [image, setImage] = useState();
  const [avatarPreview, setAvatarPreview] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setBlogData({
        ...blogData,
        [name]: value,
      });
    }
  };

  const [blogContent, setBlogContent] = useState();
  //   REACT QUILL SETIINGS
  const style = {
    "& .qlSnow .qlStroke": { stroke: "white" },
    height: "400px",
    marginBottom: "20px",
  };

  const modules = {
    toolbar: [
      [{ header: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["image"],
    ],
  };

  const formats1 = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "bullet",
    "color",
    "background",
    "image",
  ];

  const { title, shortDescription, metaDescription } = blogData;

  const handleSaveDraft = () => {
    const author = authorId?.value;
    const metaTitle = blogData?.title;
    const socialLinksArray = Object.entries(socialData)?.map(
      ([key, value]) => ({ [key]: value })
    );
    const blogCreateData = {
      title,
      shortDescription,
      metaDescription,
      metaTitle,
      image,
      blogContent,
      author,
      socialLinksArray,
    };

    localStorage.setItem("blogCreateData", JSON.stringify(blogCreateData));
    showNotification("success","Draft save successfully")
  };

  const handleCreateBlog = () => {
    const author = authorId?.value;
    const metaTitle = blogData?.title;
    const socialLinksArray = Object.entries(socialData)?.map(
      ([key, value]) => ({ [key]: value })
    );
    dispatch(
      createNewBlog(
        title,
        shortDescription,
        metaDescription,
        metaTitle,
        image,
        blogContent,
        author,
        socialLinksArray
      )
    );
  };

  const { loading, error, blogCreate } = useSelector(
    (state) => state.createBlog
  );

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearBlogErrors());
    }
    if (blogCreate?.success) {
      showNotification("success", blogCreate?.message);
      dispatch(clearBlogState());
      navigate("/admin/blogs");
    }
  }, [
    error,
    showNotification,
    dispatch,
    navigate,
    blogCreate?.success,
    blogCreate?.message,
  ]);

  // GET OFFICER LIST
  const {
    officers,
    loading: officerLoading,
    error: officerError,
  } = useSelector((state) => state.allOfficersReducer);

  useEffect(() => {
    dispatch(getAllOfficers());
  }, [dispatch]);

  useEffect(() => {
    if (officerError) {
      showNotification("error", officerError);
      dispatch(clearErrors());
    }
  }, [officerError, dispatch, showNotification]);

  let officerArray = [];

  officers?.officers?.forEach((ele) => {
    officerArray.push({
      label: ele?.first_name,
      value: ele?._id,
    });
  });

  return (
    <Layout>
      <div className="create_blog_main">
        <div className="container">
          <div className="title_header">
            <PageTitle title="Post New Blog" />
          </div>

          <div className="create_blog_data">
            <div className="batch_form">
              <CustomInput
                label="Title"
                type="text"
                placeholder="Title has maximum 50 to 70 characters"
                name="title"
                onChange={handleInputChange}
                value={blogData?.title}
                impStar="*"
                readOnly=""
                borderColor=""
              />
              {/* <CustomInput
                label="Meta title"
                type="text"
                placeholder="alpha course"
                name="metaTitle"
                onChange={handleInputChange}
                value={blogData.metaTitle}
                impStar="*"
                readOnly=""
                borderColor=""
              /> */}
              <CustomMultiSelect
                label="Select Officer"
                placeholder="Select Officer"
                name="title"
                isMulti={false}
                onChange={(val) => setAuthorId(val)}
                value={authorId}
                loading={officerLoading}
                impStar="*"
                optionArray={officerArray}
              />
              {authorId?.value && (
                <div className="social_links">
                  <CustomInput
                    label="Facebook Url"
                    type="text"
                    placeholder="facebook.com"
                    name="facebook"
                    onChange={handleInput}
                    value={socialData?.facebook}
                    // impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                  <CustomInput
                    label="Twitter Url"
                    type="text"
                    placeholder="twitter.com"
                    name="twitter"
                    onChange={handleInput}
                    value={socialData.twitter}
                    // impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                  <CustomInput
                    label="Instagram Url"
                    type="text"
                    placeholder="instagram.com"
                    name="instagram"
                    onChange={handleInput}
                    value={socialData?.instagram}
                    // impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                  <CustomInput
                    label="Youtube Link"
                    type="text"
                    placeholder="youtube.com"
                    name="youtube"
                    onChange={handleInput}
                    value={socialData.youtube}
                    // impStar="*"
                    readOnly=""
                    borderColor=""
                  />
                </div>
              )}
              <CustomTextarea
                label="ShortDescription"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="Description..."
                name="shortDescription"
                value={blogData.shortDescription}
              />
              <CustomTextarea
                label="Meta Description"
                rows="10"
                type="text"
                onChange={handleInputChange}
                placeholder="Meta Description..."
                name="metaDescription"
                value={blogData.metaDescription}
              />
              <div className="blog_data">
                <p>Add Blog Content</p>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats1}
                  style={style}
                  name="blogContent"
                  value={blogContent}
                  onChange={(content) => setBlogContent(content)}
                />
              </div>

              {avatarPreview && (
                <img
                  src={avatarPreview}
                  alt={avatarPreview}
                  width="100%"
                  height="200px"
                  style={{
                    marginBottom: "15px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "16px",
                    objectFit: "contain",
                  }}
                />
              )}
              <CustomInput
                label="Blog Thumbnail"
                type="file"
                name="thumbnail"
                accept="image/*"
                onChange={handleInputChange}
                impStar="*"
              />
            </div>
            <div className="create_btns">
              <CustomButton
                className="create_course_btn"
                title={"Save As Draft"}
                onClick={() => handleSaveDraft()}
              />
              <CustomButton
                className="create_course_btn"
                title={loading ? <ButtonLoader /> : "Publish Blog"}
                onClick={() => handleCreateBlog()}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateNewBlog;
