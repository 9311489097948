import React from "react";
import "../../styles/allSyllabusList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line, RiEdit2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const AllSyllabusList = () => {
  const navigate = useNavigate();
  const dummyBatches = [1, 2, 3, 3, 3, 32, 3, 4, 234, 32, 4, 32];
  return (
    <Layout>
      <div className="all_syllabus_main">
        <div className="container">
          <PageTitle title="Syllabus" />

          <div className="all_batch_list">
            <div className="batch_listHeader">
              <CustomInput
                icon={<BsSearch />}
                type="search"
                placeholder="Search syllabus"
                name="searchBatch"
                //   onChange=""
                //   value=""
              />

              <button
                className="add_batch_button"
                // onClick={() => navigate("/admin/create-batch")}
              >
                <AiOutlinePlus />
              </button>
            </div>

            <div className="table_data custonScroll">
              <table className="list_table" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Course name</th>
                    <th>Updated on</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dummyBatches.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p>NDA_2023</p>
                        </td>
                        <td>
                          <p>Feb 01, 2023</p>
                        </td>
                        <td>
                          <div>
                            <button>
                              <RiDeleteBin5Line />
                            </button>
                            <button
                              onClick={() =>
                                navigate(`/admin/edit-batch/${idx}`)
                              }
                            >
                              <RiEdit2Line />
                            </button>
                            <button>
                              <MdContentCopy />
                            </button>
                            <button
                              onClick={() =>
                                navigate(`/admin/view-batch/${idx}`)
                              }
                            >
                              <AiFillEye />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllSyllabusList;
