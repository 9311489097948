import React, { Fragment, useEffect, useState } from "react";
import "../../styles/createTeacher.scss";
import PageTitle from "../../components/PageTitle";
import Layout from "../../components/Layout";
import CustomInput from "../../components/CustomInput";
import { defaultUser } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import CustomTextarea from "../../components/CustomTextarea";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { GrAdd, GrClose } from "react-icons/gr";
import {
  clearErrors,
  getUserDetailsByAdmin,
  updateUserDetailsByAdmin,
} from "../../redux/actions/userAction";
import { useParams } from "react-router-dom";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import ButtonLoader from "../../components/ButtonLoader";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useNotification } from "../../context/notificationContext";

const ViewEditTeacher = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const { id: userId } = useParams();
  const [, , showNotification] = useNotification();
  // HANDLE TIME TABLE START
  // HANDLE TIME TABLE START
  const [timeTable, setTimeTable] = useState([]);
  const [weekDay, setWeekDay] = useState("");
  const [time, setTime] = useState("");
  const [classTime, setClassTime] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [avatarPreview, setAvatarPreview] = useState();
  const [thumbnail, setAvatar] = useState();

  // ADD TIME TABLE IN ARRAY
  const handleAddTimeTable = () => {
    if (!weekDay || !time) {
      showNotification("warning", "Please Select Time and WeekDay for add in list");
    } else {
      setClassTime([...classTime, { time: time }]);
      setSelectedTime({
        weekDay: weekDay,
        classTime: [...classTime, { time: time }],
      });

      setTime("");
    }
  };

  // DELETE SINGLE TIME TABLE ROW FROM ARRAY
  const deleteTimeRow = (id) => {
    var newData = classTime;
    newData.splice(id, 1);
    setClassTime([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };

  // ADD WEEKDAY AND TIMES IN MAIN LIST
  const addMainTimeTableList = () => {
    if (!weekDay || classTime?.length <= 0) {
      showNotification("warning", "Please Add Time and WeekDay for adding in main list.");
    } else {
      setTimeTable([
        ...timeTable,
        { weekDay: selectedTime?.weekDay, classTime: selectedTime?.classTime },
      ]);

      setWeekDay("");
      setTime("");
      setClassTime([]);
    }
  };

  // DELETE SINGLE ROW  FROM MAIN TIME TABLE ARRAY
  const deleteMainTimeTableRow = (id) => {
    var newData = timeTable;
    newData.splice(id, 1);
    setTimeTable([...newData]);
    showNotification("success", `Index number ${id + 1} deleted`);
  };
  // HANDLE TIME TABLE ENDS
  // HANDLE TIME TABLE ENDS

  const [assignSubjects, setAssignSubjects] = useState([]);
  const [isActive, setIsActive] = useState(false);
  //
  //
  //
  //
  //
  //
  //
  //

  // GET USER DETAILS BY ID START
  // GET USER DETAILS BY ID START
  const { loading, userDetails, error } = useSelector(
    (state) => state.getUserDetails
  );

  const userDetailParam = userDetails?.userDetails;

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (userDetails?.success) {
      showNotification("success", userDetails?.message);
      setTimeTable(userDetailParam?.timeTable);
      setAssignSubjects(userDetailParam?.assignSubjects);
      setIsActive(userDetailParam?.isActive);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    error,
    userDetails?.success,
    userDetails?.message,
    userDetailParam?.assignSubjects,
    userDetailParam?.timeTable,
    userDetailParam?.isActive, showNotification
  ]);
  // GET USER DETAILS BY ID ENDS
  // GET USER DETAILS BY ID ENDS

  const [teacherData, setTeacherData] = useState({
    first_name: userDetailParam?.first_name,
    email: userDetailParam?.email,
    phone: userDetailParam?.phone,
    dob: userDetailParam?.dob,
    gender: userDetailParam?.gender,
    password: userDetailParam?.password,
    subSpec: userDetailParam?.subSpec,
    teacherBio: userDetailParam?.teacherBio,
    role: userDetailParam?.role,
  });

  // HANDLE INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setTeacherData({
        ...teacherData,
        [name]: value,
      });
    }
  };

  // UPDATE USER DETAILS START
  // UPDATE USER DETAILS START
  const {
    loading: updateUserDetailsLoading,
    updatedUser,
    error: updateUserDetailsError,
  } = useSelector((state) => state.updateUserDetails);

  const handleUserUpdate = () => {
    dispatch(
      updateUserDetailsByAdmin(
        userId,
        teacherData?.first_name,
        teacherData?.email,
        teacherData?.phone,
        teacherData?.dob,
        teacherData?.gender,
        "",
        "",
        "",
        isActive,
        teacherData?.subSpec,
        teacherData?.teacherBio,
        assignSubjects,
        timeTable,
        thumbnail
      )
    );
  };

  useEffect(() => {
    if (updateUserDetailsError) {
      showNotification("error", updateUserDetailsError);
      dispatch(clearErrors());
    }
    if (updatedUser?.success) {
      showNotification("success", updatedUser?.message);
      setEditable(false);
      dispatch(clearErrors());
      dispatch(getUserDetailsByAdmin(userId));
    }
  }, [
    dispatch,
    updateUserDetailsError,
    updatedUser?.success,
    updatedUser?.message,
    userId, showNotification
  ]);
  // UPDATE USER DETAILS ENDS
  // UPDATE USER DETAILS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_Teacher_main">
            <div className="container">
              <PageTitle title="View/Edit Teacher's Profile" />

              <div className="student_profile">
                <div className="student_Parent_details_main">
                  <div className="title">
                    Update Teacher{" "}
                    <button
                      className={editable ? "activeBtn" : ""}
                      onClick={() => setEditable(!editable)}
                    >
                      {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                    </button>
                  </div>

                  <div className="active_teacher">
                    Teacher Active Or Not:
                    <ToggleSwitch
                      onClick={() => setIsActive(!isActive)}
                      checked={isActive}
                      // onChange={}
                      disabled={editable ? false : true}
                    />
                  </div>

                  <div className="details_card studentDetails">
                    <div>
                      <CustomInput
                        label="Full Name"
                        type="text"
                        placeholder="Full Name"
                        name="first_name"
                        value={
                          editable
                            ? teacherData?.first_name
                            : userDetailParam?.first_name
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomInput
                        label="Email"
                        type="email"
                        placeholder="Teacher Email"
                        name="email"
                        value={
                          editable ? teacherData?.email : userDetailParam?.email
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Whatsapp Number"
                        type="number"
                        placeholder="Whatsapp Number"
                        name="phone"
                        value={
                          editable ? teacherData?.phone : userDetailParam?.phone
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Date Of Birth"
                        type="date"
                        placeholder=""
                        name="dob"
                        value={
                          editable ? teacherData?.dob : userDetailParam?.dob
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomSelect
                        label="Gender"
                        name="gender"
                        value={
                          editable
                            ? teacherData?.gender
                            : userDetailParam?.gender
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                        optionArray={[
                          {
                            id: 1,
                            option: "Male",
                          },
                          {
                            id: 2,
                            option: "Female",
                          },
                          {
                            id: 3,
                            option: "Others",
                          },
                        ]}
                      />
                      {editable && (
                        <CustomInput
                          label="Profile Picture"
                          type="file"
                          name="thumbnail"
                          accept="image/*"
                          onChange={handleInputChange}
                          borderColor="#f2f2f2"
                        />
                      )}
                    </div>
                    <div>
                      <CustomInput
                        label="Password"
                        type="text"
                        placeholder="Enter password for Login"
                        name="password"
                        value={
                          editable
                            ? teacherData?.password
                            : userDetailParam?.password
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                      <CustomInput
                        label="Subject Specialization"
                        type="text"
                        placeholder="Subject Specialization"
                        name="subSpec"
                        value={
                          editable
                            ? teacherData?.subSpec
                            : userDetailParam?.subSpec
                        }
                        onChange={handleInputChange}
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />

                      <CustomMultiSelect
                        label="Select Subjects"
                        value={assignSubjects}
                        onChange={(val) => setAssignSubjects(val)}
                        disabled={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                        isMulti={true}
                        optionArray={[
                          { value: "HIN-01", label: "HIN-01" },
                          { value: "HIN-02", label: "HIN-02" },
                          { value: "HIN-03", label: "HIN-03" },
                          { value: "HIN-04", label: "HIN-04" },
                          { value: "HIN-05", label: "HIN-05" },
                        ]}
                      />

                      <CustomTextarea
                        label="Teacher Bio"
                        rows="5"
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Teacher Bio"
                        name="teacherBio"
                        value={
                          editable
                            ? teacherData?.teacherBio
                            : userDetailParam?.teacherBio
                        }
                        readOnly={editable ? false : true}
                        borderColor={editable ? "#f2f2f2" : ""}
                      />
                    </div>
                    <div className="userImage">
                      <img
                        src={
                          avatarPreview
                            ? avatarPreview
                            : userDetailParam?.profile?.url
                              ? userDetailParam?.profile?.url
                              : defaultUser
                        }
                        alt="defaultUser"
                      />
                      {/* <button className="edit_btn">
                        <RiEdit2Line />
                      </button> */}
                    </div>
                  </div>

                  {editable && (
                    <>
                      <div className="add_time_title">Time Table</div>
                      <div className="add_timeTable">
                        <div>
                          <div className="timetable_tile">
                            <span>
                              <select
                                name="weekDay"
                                value={weekDay}
                                onChange={(e) => setWeekDay(e.target.value)}
                              >
                                <option value="">Select Week Day</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thrusday">Thrusday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                              </select>
                              <button onClick={handleAddTimeTable}>
                                <GrAdd />
                              </button>
                            </span>
                            <span>
                              <input
                                type="time"
                                name="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                              />

                              {classTime?.length > 0 && (
                                <div className="time_list">
                                  <h5>Time List:</h5>
                                  {classTime?.map((item, idx) => (
                                    <p key={idx}>
                                      {item.time}
                                      <span onClick={() => deleteTimeRow(idx)}>
                                        <GrClose />
                                      </span>
                                    </p>
                                  ))}
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="btn_sec">
                          <CustomButton
                            onClick={addMainTimeTableList}
                            title="Add In List"
                            className="add_time_btn"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {timeTable?.length > 0 && (
                    <div className="main_timeTable_list">
                      <h5>Main Time Table List</h5>

                      <div className="table_data custonScroll">
                        <table className="list_table" cellSpacing="0">
                          <thead>
                            <tr>
                              <th>Week Day</th>
                              <th>Time Table</th>
                              {editable && <th>Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {timeTable?.map((item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <p>{item?.weekDay}</p>
                                  </td>
                                  <td>
                                    {item?.classTime?.map((item, idx) => (
                                      <p key={idx}>{item?.time}</p>
                                    ))}
                                  </td>

                                  {editable && (
                                    <td>
                                      <div>
                                        <button
                                          onClick={() =>
                                            deleteMainTimeTableRow(idx)
                                          }
                                        >
                                          <GrClose />
                                        </button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {editable && (
                    <CustomButton
                      className="update_user_btn"
                      disabled={updateUserDetailsLoading ? true : false}
                      title={
                        updateUserDetailsLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Update Teacher"
                        )
                      }
                      onClick={handleUserUpdate}
                    />
                  )}

                  {userDetailParam?.document !== "" && (
                    <iframe
                      title={userDetailParam?.first_name}
                      src={userDetailParam?.document?.url}
                      style={{
                        width: "100%",
                        height: "600px",
                        overflow: "auto",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewEditTeacher;
