import { axiosInstance } from "../../utils/axiosConfig";
import {
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ALL_USERS_ADMIN_FAIL,
  ALL_USERS_ADMIN_REQUEST,
  ALL_USERS_ADMIN_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_UPDATE_STATE,
  CLEAR_USER_LOADING,
  CREATE_AFFILIATE_FAIL,
  CREATE_AFFILIATE_REQUEST,
  CREATE_AFFILIATE_SUCCESS,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_TEACHER_FAIL,
  CREATE_TEACHER_REQUEST,
  CREATE_TEACHER_SUCCESS,
  DELETE_USER_ADMIN_FAIL,
  DELETE_USER_ADMIN_REQUEST,
  DELETE_USER_ADMIN_SUCCESS,
  GET_ALL_OFFICERS_FAIL,
  GET_ALL_OFFICERS_REQUEST,
  GET_ALL_OFFICERS_SUCCESS,
  GET_USER_DETAILS_ADMIN_FAIL,
  GET_USER_DETAILS_ADMIN_REQUEST,
  GET_USER_DETAILS_ADMIN_SUCCESS,
  INACTIVATE_USER_FAIL,
  INACTIVATE_USER_REQUEST,
  INACTIVATE_USER_SUCCESS,
  SEAT_BOOK_ELIGIBILITY_FAIL,
  SEAT_BOOK_ELIGIBILITY_REQUEST,
  SEAT_BOOK_ELIGIBILITY_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
} from "../constants/userConstant";

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const loginAdmin = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `/login`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    sessionStorage.setItem(
      "alphaAdminToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL USERS - BY ADMIN
export const getAllUsersByAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_USERS_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-all-users`, config);

    dispatch({
      type: ALL_USERS_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_USERS_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE AFFILIATE USERS
export const createAffiliateByAdmin =
  (
    first_name,
    email,
    phone,
    dob,
    gender,
    address,
    password,
    role,
    couponCode
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_AFFILIATE_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/affiliate-register`,
        {
          first_name,
          email,
          phone,
          dob,
          gender,
          address,
          password,
          role,
          couponCode,
        },
        config
      );

      dispatch({
        type: CREATE_AFFILIATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_AFFILIATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE USER PROFILE DETAILS
export const deleteUserByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/delete-user/${id}`, config);

    dispatch({
      type: DELETE_USER_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS
export const getUserDetailsByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DETAILS_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-user-details/${id}`, config);

    dispatch({
      type: GET_USER_DETAILS_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS
export const updateUserDetailsByAdmin =
  (
    id,
    first_name,
    email,
    phone,
    dob,
    gender,
    address,
    middle_name,
    last_name,
    isActive,
    subSpec,
    teacherBio,
    assignSubjects,
    timeTable,
    thumbnail
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_ADMIN_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-user-details/${id}`,
        {
          first_name,
          email,
          phone,
          dob,
          gender,
          address,
          middle_name,
          last_name,
          isActive,
          subSpec,
          teacherBio,
          assignSubjects,
          timeTable,
          thumbnail,
        },
        config
      );

      dispatch({
        type: UPDATE_USER_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ADMIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CREATE TEACHER USERS == ROLE 12
export const createTeacherByAdmin =
  (
    first_name,
    email,
    phone,
    dob,
    gender,
    password,
    subSpec,
    teacherBio,
    isActive,
    role,
    assignSubjects,
    timeTable,
    thumbnail
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_TEACHER_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-teacher`,
        {
          first_name,
          email,
          phone,
          dob,
          gender,
          password,
          subSpec,
          teacherBio,
          isActive,
          role,
          assignSubjects,
          timeTable,
          thumbnail,
        },
        config
      );

      dispatch({
        type: CREATE_TEACHER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_TEACHER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL OFFICERS BY ADMIN
export const getAllOfficers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_OFFICERS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-officers`, config);

    dispatch({
      type: GET_ALL_OFFICERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_OFFICERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// ACTIVATE USER BY ADMIN -- PUT REQ
export const activateUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_USER_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/activate-user/${id}`,
      {},
      config
    );

    dispatch({
      type: ACTIVATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// INACTIVATE USER BY ADMIN -- PUT REQ
export const inactivateUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INACTIVATE_USER_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/inactivate-user/${id}`,
      {},
      config
    );

    dispatch({
      type: INACTIVATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INACTIVATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// MODIFY SEAT BOOKING FOR COURSE CREATOR BY ADMIN -- PUT REQ
export const seatBookingModify = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEAT_BOOK_ELIGIBILITY_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/modify-seatbooking/${id}`,
      {},
      config
    );

    dispatch({
      type: SEAT_BOOK_ELIGIBILITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEAT_BOOK_ELIGIBILITY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CREATE NEW USER BY ADMIN -- POST REQ
export const createNewUser =
  (first_name, email, phone, password, profile, teacherBio, price, role) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_NEW_USER_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/register`,
        {
          first_name,
          email,
          phone,
          password,
          profile,
          teacherBio,
          price,
          role,
        },
        config
      );

      dispatch({
        type: CREATE_NEW_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_NEW_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR PROFILE ERROR ACTION FUNCTION
export const clearProfileErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE_ERRORS });
  sessionStorage.removeItem("alphaAdminToken");
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR UPDATED STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};

// CLEAR USER LOADING ACTION FUNCTION
export const clearLoading = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_LOADING });
};
