import React from "react";
import Select from "react-select";
import styled from "styled-components";

const Div = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #a9b5bb;
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }

  .selectMulti {
    &__control {
      background: linear-gradient(
        115.85deg,
        rgba(255, 255, 255, 0.4) -185.19%,
        rgba(255, 255, 255, 0) 129.07%
      );
      border: 1px solid ${(props) => props.borderColor};

      &:hover {
        border: 1px solid ${(props) => props.borderColor} !important;
        box-shadow: none;
      }
    }

    &__single-value {
      color: #f2f2f2;
      font-size: 11px;
    }

    &__multi-value__remove {
      color: red;
    }

    &__clear-indicator {
      color: #fff;

      &:hover {
        color: #d8d8d8;
      }
    }

    &__option {
      background-color: #081336;
      font-size: 13px;

      &--is-focused {
        background-color: white;
        color: black;
        cursor: pointer;
      }
    }
    .selectMulti__input-container{
      color:#fff;
    }
    .selectMulti__input{
      color:#fff;
    }
  }
`;

const CustomMultiSelect = ({
  label,
  value,
  onChange,
  defaultValue,
  isMulti,
  optionArray,
  disabled,
  borderColor,
}) => {
  return (
    <Div
      className="custom_multi_select"
      borderColor={borderColor ? borderColor : "transparent"}
    >
      {label && <label>{label}</label>}
      <Select
        defaultValue={defaultValue}
        isMulti={isMulti}
        name="colors"
        options={optionArray}
        className="multi-select"
        classNamePrefix="selectMulti"
        value={value}
        onChange={onChange}
        isDisabled={disabled}
      />
    </Div>
  );
};

export default CustomMultiSelect;
