import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, userProfile } from "../redux/actions/userAction";
import { useNotification } from "./notificationContext";

const userContext = createContext();

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState({
    user: null,
    token: "",
    loading: false,
  });

  const [, , showNotification] = useNotification();

  const {
    userInfo: profileData,
    isAuthenticated,
    error,
    loading: profileLoading,
  } = useSelector((state) => state.profile); // GET USERDATA FROM PROFILE STATE

  const { userInfo: loginUser } = useSelector((state) => state.userLogin); // GET TOKEN DATA FROM LOGIN STATE

  useEffect(() => {
    const token = loginUser?.token;
    if (token) {
      dispatch(userProfile(token)); // GET USER PROFILE DATA
    }
  }, [dispatch, loginUser?.token]);

  // SET COMING DATA FROM PROFILE STATE
  useEffect(() => {
    if (error && loginUser?.token) {
      showNotification("error", error);
      dispatch(clearErrors());
      sessionStorage.removeItem("alphaAdminToken");
    }

    if (isAuthenticated) {
      setAuth({
        user: profileData?.user,
        token: loginUser?.token,
        loading: profileLoading,
      });
    }
  }, [
    isAuthenticated,
    loginUser?.token,
    profileData?.user,
    error,
    dispatch, showNotification,
    profileLoading,
  ]);

  return (
    <userContext.Provider value={[auth, setAuth]}>
      {children}
    </userContext.Provider>
  );
};

const useAuth = () => useContext(userContext);

export { useAuth, UserProvider };
