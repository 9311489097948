import React from "react";
// import styled from "styled-components";
import "../styles/loader.scss";
import AlphaLoader from "../images/alphaLoader.png";
// import loaderBg from "../images/loaderBg.mp4";

// const Div = styled.div`
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #081336;
//   overflow: hidden;

//   /* > div {
//     width: 100px;
//     height: 100px;
//     border-top: 3px solid #fff;
//     border-radius: 100%;
//     animation: rotateLoader infinite 1s linear;
//   } */

//   > div {
//     > video {
//       object-fit: contain;
//       width: 100%;
//       height: 100%;
//       max-width: 700px;
//     }
//   }
// `;

const Loader = () => {
  return (
    <>
      {/* <Div>
      <div>
        <video autoPlay loop muted>
          <source src={loaderBg} type="video/mp4" />
          <source src={loaderBg} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Div> */}
      <div id="particles-foreground"></div>
      <div id="particles-background"></div>

      <div className="vertical-centered-box">
        <div className="content">
          <div className="loader-circle"></div>
          <div className="loader-line-mask">
            <div className="loader-line"></div>
          </div>
          <img src={AlphaLoader} alt="loader" />
        </div>
      </div>
    </>
  );
};

export default Loader;
