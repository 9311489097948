import React, { useState, useEffect, Fragment } from "react";
import "../../styles/viewEditTextTestimonial.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTextarea from "../../components/CustomTextarea";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllCourses } from "../../redux/actions/courseAction";
import PopupPrompt from "../../components/PopupPrompt";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import {
  clearState,
  clearErrors as clearTestimonialErrors,
  getTextTestimonialDetails,
  updateTextTestimonial,
} from "../../redux/actions/textTextiAction";
import { useParams } from "react-router-dom";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import ToggleSwitch from "../../components/ToggleSwitch";
import Loader from "../../components/Loader";
import { useNotification } from "../../context/notificationContext";

const ViewEditTextTestimonial = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams(); // EXTRACT ID FROM PARAM
  const [, , showNotification] = useNotification();
  const [thumbnail, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();

  // STORED STATE FOR TESTIMONIAL CREATION DATA
  const [selectedCourses, setSelectedCourses] = useState([]);
  let forCourse = [];
  selectedCourses.forEach((item) => {
    forCourse.push({ label: item?.label, value: item?.value });
  });

  // GET TEXT TESTIMONIAL DETAILS BY ID START
  // GET TEXT TESTIMONIAL DETAILS BY ID START
  const { loading, data, error } = useSelector(
    (state) => state.getTextTestimonialById
  );

  const testimonialDetails = data?.testimonial;

  useEffect(() => {
    if (id) {
      dispatch(getTextTestimonialDetails(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearTestimonialErrors());
    }

    if (data?.success) {
      showNotification("success", data?.message);
      setAvatarPreview(testimonialDetails?.profile?.url);
      setSelectedCourses(testimonialDetails?.forCourse);
      setIsActive(testimonialDetails?.isActive);
      dispatch(clearState());
    }
  }, [
    dispatch,
    showNotification,
    error,
    testimonialDetails?.profile?.url,
    testimonialDetails?.forCourse,
    testimonialDetails?.isActive,
    data?.success,
    data?.message,
  ]);
  // GET TEXT TESTIMONIAL DETAILS BY ID ENDS
  // GET TEXT TESTIMONIAL DETAILS BY ID ENDS

  const [testimonialData, setTestimonialData] = useState({
    title: testimonialDetails?.title,
    selectedIn: testimonialDetails?.selectedIn,
    givenStar: testimonialDetails?.givenStar,
    textHindi: testimonialDetails?.textHindi,
    textEnglish: testimonialDetails?.textEnglish,
  });

  const { title, selectedIn, givenStar, textHindi, textEnglish } =
    testimonialData;
  // COURSE DATA DESTURUCTURE

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setTestimonialData({
        ...testimonialData,
        [name]: value,
      });
    }
  };

  // UPDATE TEXT TESTIMONIAL START
  // UPDATE TEXT TESTIMONIAL START
  const {
    loading: UpdateLoading,
    testimonial,
    error: UpdateError,
  } = useSelector((state) => state.updateTextTestimonial);

  const handleUpdateTestimnonial = () => {
    dispatch(
      updateTextTestimonial(
        id,
        isActive,
        title,
        selectedIn,
        givenStar,
        textHindi,
        textEnglish,
        thumbnail,
        forCourse
      )
    );
  };

  useEffect(() => {
    if (UpdateError) {
      showNotification("error", UpdateError);
      dispatch(clearTestimonialErrors());
    }

    if (testimonial?.success) {
      showNotification("success", testimonial?.message);
      dispatch(getTextTestimonialDetails(id));
      dispatch(clearState());
      setUpdateModal(false);
      setEditable(false);
    }
  }, [dispatch, UpdateError, testimonial?.success, testimonial?.message, id, showNotification]);

  // UPDATE TEXT TESTIMONIAL ENDS
  // UPDATE TEXT TESTIMONIAL ENDS

  // GET ALL COURSES DATA START
  // GET ALL COURSES DATA START
  const {
    loading: coursesLoading,
    courses,
    error: coursesError,
  } = useSelector((state) => state.getAllCourses);

  const allCourses = courses?.courses;

  let courseOptions = []; // STORE ALL COURSES IN THIS PARAM
  allCourses?.forEach((item) => {
    courseOptions.push({ label: item?.title, value: item?._id });
  });

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (coursesError) {
      showNotification("error", coursesError);
      dispatch(clearErrors());
    }
  }, [dispatch, coursesError, showNotification]);
  // GET ALL COURSES DATA ENDS
  // GET ALL COURSES DATA ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="update_textTesti_main">
            <div className="container">
              <div className="title_header">
                <PageTitle title="View/Edit Text Testimonial" />
              </div>

              <div className="sub_title_main">
                <div className="active_teacher">
                  Active Or Not:
                  <ToggleSwitch
                    onClick={() => setIsActive(!isActive)}
                    checked={isActive}
                    disabled={editable ? false : true}
                  />
                </div>
                <button
                  className={editable ? "activeBtn" : ""}
                  onClick={() => setEditable(!editable)}
                >
                  {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                </button>
              </div>

              <div className="update_textTesti_data">
                <div className="batch_form">
                  <CustomMultiSelect
                    label="For Course"
                    value={selectedCourses}
                    disabled={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                    onChange={(val) => setSelectedCourses(val)}
                    isMulti={true}
                    optionArray={
                      coursesLoading
                        ? [{ label: "Please wait..", value: "" }]
                        : courseOptions
                    }
                  />
                  <CustomInput
                    label="Title"
                    type="text"
                    placeholder="Suresh Kumar"
                    name="title"
                    onChange={handleInputChange}
                    value={editable ? title : testimonialDetails?.title}
                    borderColor={editable ? "#f2f2f2" : ""}
                    readOnly={editable ? false : true}
                    impStar="*"
                  />
                  <CustomInput
                    label="Selected In"
                    type="text"
                    placeholder="Selected In Indian Navy"
                    name="selectedIn"
                    onChange={handleInputChange}
                    value={
                      editable ? selectedIn : testimonialDetails?.selectedIn
                    }
                    borderColor={editable ? "#f2f2f2" : ""}
                    readOnly={editable ? false : true}
                    impStar="*"
                  />
                  <CustomSelect
                    label="Select Number Of Stars"
                    name="givenStar"
                    onChange={handleInputChange}
                    optionArray={[
                      { id: 1, option: 1 },
                      { id: 2, option: 2 },
                      { id: 3, option: 3 },
                      { id: 4, option: 4 },
                      { id: 5, option: 5 },
                    ]}
                    value={editable ? givenStar : testimonialDetails?.givenStar}
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                    impStar="*"
                  />
                  <CustomTextarea
                    label="Textimonial Text *"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Textimonial Text..."
                    name="textEnglish"
                    value={
                      editable ? textEnglish : testimonialDetails?.textEnglish
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                </div>
                <div className="course_summary">
                  <CustomTextarea
                    label="टेस्टीमोनियल टेक्स्ट *"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="टेस्टीमोनियल टेक्स्ट हिंदी में लिखें..."
                    name="textHindi"
                    value={editable ? textHindi : testimonialDetails?.textHindi}
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  {avatarPreview && (
                    <img
                      src={avatarPreview}
                      alt={avatarPreview}
                      width="100%"
                      height="200px"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "16px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {editable && (
                    <CustomInput
                      label="Upload Profile Photo"
                      type="file"
                      name="thumbnail"
                      accept="image/*"
                      onChange={handleInputChange}
                      impStar="*"
                      readOnly={editable ? false : true}
                      borderColor={editable ? "#f2f2f2" : ""}
                    />
                  )}
                </div>
              </div>
              {editable && (
                <CustomButton
                  className="create_course_btn"
                  title="Update"
                  onClick={() => setUpdateModal(true)}
                />
              )}
            </div>
          </div>

          {/* POPUP PROMPT START */}
          {updateModal && (
            <PopupPrompt
              confirmOnclick={handleUpdateTestimnonial}
              cancelOnclick={() => setUpdateModal(false)}
              title={`Do you really want to update the Testimonial of ${title} ?`}
              loading={UpdateLoading}
            />
          )}

          {/* POPUP PROMPT ENDS */}
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewEditTextTestimonial;
