import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERROR,
  CLEAR_STATE,
  COURSE_BY_ID_FAIL,
  COURSE_BY_ID_REQUEST,
  COURSE_BY_ID_SUCCESS,
  CREATE_COURSE_FAIL,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  GET_ALL_COURSE_FAIL,
  GET_ALL_COURSE_REQUEST,
  GET_ALL_COURSE_SUCCESS,
  GET_COURSE_META_FAIL,
  GET_COURSE_META_REQUEST,
  GET_COURSE_META_SUCCESS,
  GET_CRS_LONG_DTLS_FAIL,
  GET_CRS_LONG_DTLS_REQUEST,
  GET_CRS_LONG_DTLS_SUCCESS,
  UPDATE_COURSE_DETAILS_FAIL,
  UPDATE_COURSE_DETAILS_REQUEST,
  UPDATE_COURSE_DETAILS_SUCCESS,
  UPDATE_COURSE_FAIL,
  UPDATE_COURSE_META_FAIL,
  UPDATE_COURSE_META_REQUEST,
  UPDATE_COURSE_META_SUCCESS,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
} from "../constants/courseConstant";

// CREATE NEW COURSE
export const createCourse =
  (
    title,
    titleHindi,
    description,
    descriptionHindi,
    original_price,
    discounted_price,
    study_mode,
    affiliate_percentage,
    thumbnail,
    starting_date,
    goalType,
    courseCategory,
    bannervideoLink,
    isEmiAvail,
    allEmis,
    courseTeachers,
    courseDuration
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_COURSE_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-course`,
        {
          title,
          titleHindi,
          description,
          descriptionHindi,
          original_price,
          discounted_price,
          study_mode,
          affiliate_percentage,
          thumbnail,
          starting_date,
          goalType,
          courseCategory,
          bannervideoLink,
          isEmiAvail,
          allEmis,
          courseTeachers,
          courseDuration,
        },
        config
      );

      dispatch({
        type: CREATE_COURSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_COURSE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL COURSES
export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_COURSE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/courses`, config);

    dispatch({
      type: GET_ALL_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET COURSE BY ID
export const getCourseById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/course/${id}`, config);

    dispatch({
      type: COURSE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_BY_ID_FAIL,
      paylaod: error.response.data.message,
    });
  }
};

// DELETE COURSE BY ID
export const deleteCourse = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COURSE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/course/${id}`, config);

    dispatch({
      type: DELETE_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE COURSE BY ID
export const updateCourse =
  (
    id,
    title,
    titleHindi,
    description,
    descriptionHindi,
    original_price,
    discounted_price,
    study_mode,
    affiliate_percentage,
    thumbnail,
    starting_date,
    goalType,
    courseCategory,
    bannervideoLink,
    isEmiAvail,
    allEmis,
    courseTeachers
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_COURSE_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/course/${id}`,
        {
          title,
          titleHindi,
          description,
          descriptionHindi,
          original_price,
          discounted_price,
          study_mode,
          affiliate_percentage,
          thumbnail,
          starting_date,
          goalType,
          courseCategory,
          bannervideoLink,
          isEmiAvail,
          allEmis,
          courseTeachers,
        },
        config
      );

      dispatch({
        type: UPDATE_COURSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COURSE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CREATE COURSE DETAILS
export const createCourseDetails =
  (
    for_course,
    contentEn1,
    contentEn2,
    contentEn3,
    contentEn4,
    contentHi1,
    contentHi2,
    contentHi3,
    contentHi4
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_COURSE_DETAILS_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-course-details`,
        {
          for_course,
          contentEn1,
          contentEn2,
          contentEn3,
          contentEn4,
          contentHi1,
          contentHi2,
          contentHi3,
          contentHi4,
        },
        config
      );

      dispatch({
        type: UPDATE_COURSE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COURSE_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET COURSE BY ID
export const getCourseLongDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRS_LONG_DTLS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/get-course-long-details/${id}`,
      config
    );

    dispatch({
      type: GET_CRS_LONG_DTLS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CRS_LONG_DTLS_FAIL,
      paylaod: error.response.data.message,
    });
  }
};

// CREATE COURSE META TAGS
export const createCourseMetaAction =
  (
    for_course,
    metaTitle,
    metaKeywords,
    metaDescription,
    metaAuthor,
    metaTitleHindi,
    metaKeywordsHindi,
    metaDescriptionHindi,
    metaAuthorHindi
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_COURSE_META_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-course-meta`,
        {
          for_course,
          metaTitle,
          metaKeywords,
          metaDescription,
          metaAuthor,
          metaTitleHindi,
          metaKeywordsHindi,
          metaDescriptionHindi,
          metaAuthorHindi,
        },
        config
      );

      dispatch({
        type: UPDATE_COURSE_META_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COURSE_META_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET COURSE BY ID
export const getCourseMetaDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSE_META_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/get-course-meta-details/${id}`,
      config
    );

    dispatch({
      type: GET_COURSE_META_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_COURSE_META_FAIL,
      paylaod: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
