import React, { useEffect, useState } from "react";
import "../styles/login.scss";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from "react-icons/ai";
import { MdPassword } from "react-icons/md";
import { logo_main } from "../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  clearErrors,
  clearLoading,
  loginAdmin,
} from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../context/notificationContext";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [, , showNotification] = useNotification();

  const [loginData, setLoginData] = useState({
    loginEmail: "",
    loginPassword: "",
  });

  // DESTUCTURE STATE DATA
  const { loginEmail, loginPassword } = loginData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // LOGIN HANDLER FUNCTION
  const loginHandler = (e) => {
    e.preventDefault();

    dispatch(loginAdmin(loginEmail, loginPassword));
  };

  // GEt User after trigger login function
  const {
    error,
    userInfo,
    loading: loginLoading,
  } = useSelector((state) => state.userLogin);

  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/admin/dashboard";

  useEffect(() => {
    if (userInfo?.success) {
      navigate(redirect);
    }
    if (redirect) {
      dispatch(clearLoading());
    }
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [
    error,
    userInfo?.success,
    navigate,
    dispatch,
    redirect, showNotification
  ]);

  return (
    <section className="login_main_sec">
      <div className="login_sec">
        <form className="login_form" onSubmit={loginHandler}>
          <img
            className="login_logo"
            src={logo_main}
            alt="alpha regiment main logo"
          />
          <CustomInput
            icon={<AiOutlineMail />}
            type="email"
            placeholder="Admin Email"
            name="loginEmail"
            onChange={handleInputChange}
            value={loginEmail}
          />
          <CustomInput
            icon={<MdPassword />}
            eyeIcon={showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            setshowPassword={setshowPassword}
            type={showPassword ? "text" : "password"}
            placeholder="Admin Password"
            name="loginPassword"
            onChange={handleInputChange}
            value={loginPassword}
          />

          <CustomButton
            className="loginButton"
            title={loginLoading ? "LOADING..." : "LOG IN"}
            // onClick=""
            icon=""
            disabled={false}
            width="150px"
          />
          <p className="forgot_password">
            <Link to="/">Forgot Password</Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Login;
