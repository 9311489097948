export const CREATE_BATCH_REQUEST = "CREATE_BATCH_REQUEST";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAIL = "CREATE_BATCH_FAIL";

export const GET_BATCHES_REQUEST = "GET_BATCHES_REQUEST";
export const GET_BATCHES_SUCCESS = "GET_BATCHES_SUCCESS";
export const GET_BATCHES_FAIL = "GET_BATCHES_FAIL";

export const GET_BATCH_BY_ID_REQUEST = "GET_BATCH_BY_ID_REQUEST";
export const GET_BATCH_BY_ID_SUCCESS = "GET_BATCH_BY_ID_SUCCESS";
export const GET_BATCH_BY_ID_FAIL = "GET_BATCH_BY_ID_FAIL";

export const DELETE_BATCH_REQUEST = "DELETE_BATCH_REQUEST";
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS";
export const DELETE_BATCH_FAIL = "DELETE_BATCH_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_STATE = "CLEAR_STATE";
