import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allBatchesList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import moment from "moment";
import usePaginate from "../../hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import {
  clearDeleteVideoState,
  clearVideoErrors,
  clearVideoState,
  deleteVideoByAdminAction,
  getAllVideos,
} from "../../redux/actions/gptVideoAction";

const AllVideoList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();

  // GET ALL BATCHES START
  // GET ALL BATCHES START
  const { loading, videos, error } = useSelector((state) => state.getVideos);

  const allVideos = videos?.videoLinks;

  useEffect(() => {
    dispatch(getAllVideos());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearVideoErrors());
    }
  }, [dispatch, error]);

  // GET ALL BATCHES ENDS
  // GET ALL BATCHES ENDS

  // DELETE BATCH START
  // DELETE BATCH START
  const {
    loading: deletedVideoLoading,
    deletedVideo,
    error: deletedVideoError,
  } = useSelector((state) => state.deleteVideo);

  const handleDeleteVideo = (id) => {
    setDeleteId(id);
    setDeletePrompt(true);
  };

  useEffect(() => {
    if (deletedVideoError) {
      alert(deletedVideoError);
      dispatch(clearVideoErrors());
    }

    if (deletedVideo?.success) {
      alert(deletedVideo?.message);
      setDeleteId("");
      setDeletePrompt(false);
      dispatch(clearDeleteVideoState());
      dispatch(getAllVideos());
    }
  }, [
    deletedVideo?.message,
    deletedVideo?.success,
    deletedVideoError,
    dispatch,
  ]);

  // DELETE BATCH ENDS
  // DELETE BATCH ENDS

  // PAGINATION START
  // PAGINATION START
  const { currentItems, pageCount, handlePageClick } = usePaginate(allVideos);
  // PAGINATION ENDS
  // PAGINATION ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_batches_main">
            <div className="container">
              <PageTitle title="All Video List" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search video"
                    name="searchVideo"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    title="Create New Batch"
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-video")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                <div className="table_data custonScroll">
                  <table className="list_table" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Video Title</th>
                        <th>Video Url</th>
                        <th>Tags</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allVideos?.map((item) => {
                        const storedCreatedAt = item?.createdAt;
                        const formattedCreatedAt = moment(
                          storedCreatedAt
                        ).format("DD-MM-YYYY [at] hh:mm A");
                        return (
                          <tr key={item?._id}>
                            <td>
                              <p>{item?.title?.slice(0, 40)}</p>
                            </td>
                            <td>
                              <p>{item?.url}</p>
                            </td>
                            <td>
                              <p>
                                {/* {item?.tags?.map((itm, idx) => (
                                  <p key={idx}>{itm},</p>
                                ))} */}
                                {item?.tags?.join(" , ")}
                              </p>
                            </td>
                            <td>
                              <p>{formattedCreatedAt}</p>
                            </td>

                            <td>
                              <div>
                                <button
                                  title="Delete Video"
                                  disabled={deletedVideoLoading ? true : false}
                                  onClick={() => handleDeleteVideo(item?._id)}
                                >
                                  <RiDeleteBin5Line />
                                </button>
                                <button
                                  title="View/Edit Video"
                                  onClick={() =>
                                    navigate(
                                      `/admin/view-edit-video/${item?._id}`
                                    )
                                  }
                                >
                                  <AiFillEye />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {allVideos?.length <= 10 ? (
                  ""
                ) : (
                  <div className="pagination_sec">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<RxDoubleArrowRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<RxDoubleArrowLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmBtnTxt="Confirm"
                cancelBtnTxt="Cancel"
                confirmOnclick={() =>
                  dispatch(deleteVideoByAdminAction(deleteId))
                }
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this video link?"
                loading={deletedVideoLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllVideoList;
