import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allTeachersList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { VscFlame } from "react-icons/vsc";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  deleteUserByAdmin,
  getAllUsersByAdmin,
  seatBookingModify,
} from "../../redux/actions/userAction";
import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import { MdAirplanemodeActive, MdAirplanemodeInactive } from "react-icons/md";
import ButtonLoader from "../../components/ButtonLoader";
import {
  activateUser,
  clearState,
  inactivateUser,
} from "../../redux/actions/userAction";
import { useNotification } from "../../context/notificationContext";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import usePaginate from "../../hooks/usePaginate";

const AllTeachersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [allTeachers, setallTeachers] = useState([]);
  const [teacherId, setTeacherId] = useState("");
  const [, , showNotification] = useNotification();
  // const [searchString, setSearchString] = useState("");

  // GET ALL TEACHER USERS START
  // GET ALL TEACHER USERS START
  const { loading, allUsers, error } = useSelector(
    (state) => state.getUsersByAdmin
  );

  const otherTeachers = allUsers?.otherTeachers;
  const alphaTeachers = allUsers?.alphaTeachers;

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
  }, [error, dispatch, showNotification]);
  // GET ALL TEACHER USERS ENDS
  // GET ALL TEACHER USERS ENDS

  // DELETE TEACHER START
  // DELETE TEACHER START
  const {
    loading: deletedUserLoading,
    deletedUser,
    error: deletedUserError,
  } = useSelector((state) => state.deleteUser);

  const deleteUserHandler = (id) => {
    if (id) {
      setDeleteId(id);
      setDeletePrompt(true);
    }
  };

  useEffect(() => {
    if (deletedUserError) {
      showNotification("error", deletedUserError);
      dispatch(clearErrors());
    }
    if (deletedUser?.success) {
      showNotification("success", deletedUser?.message);
      dispatch(clearErrors());
      setDeletePrompt(false);
      dispatch(getAllUsersByAdmin());
    }
  }, [
    deletedUserError,
    deletedUser?.success,
    deletedUser?.message,
    dispatch,
    showNotification,
  ]);

  // DELETE TEACHER ENDS
  // DELETE TEACHER ENDS

  // HANDLE TABS START
  const handleTab = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (activeTab === 0) {
      setallTeachers(alphaTeachers);
    } else if (activeTab === 1) {
      setallTeachers(otherTeachers);
    }
  }, [activeTab, alphaTeachers, otherTeachers]);
  // HANDLE TABS ENDS

  // ACTIVATE USER
  const {
    status: activateStatus,
    loading: activateLoading,
    error: activateError,
  } = useSelector((state) => state.activateUserReducer);

  const handleActiveUser = (id) => {
    if (id) {
      setTeacherId(id);
      dispatch(activateUser(id));
    }
  };

  useEffect(() => {
    if (activateError) {
      showNotification("error", activateError);
      dispatch(clearErrors());
    }
    if (activateStatus?.success) {
      showNotification("success", activateStatus?.message);
      dispatch(clearState());
      dispatch(getAllUsersByAdmin());
    }
  }, [
    activateError,
    dispatch,
    activateStatus?.success,
    activateStatus?.message,
    showNotification,
  ]);
  // ACTIVATE USER

  // INACTIVATE USER
  const {
    status: inactivateStatus,
    loading: inactivateLoading,
    error: inactivateError,
  } = useSelector((state) => state.inactivateUserReducer);

  const handleInactiveUser = (id) => {
    if (id) {
      setTeacherId(id);
      dispatch(inactivateUser(id));
    }
  };

  useEffect(() => {
    if (inactivateError) {
      showNotification("error", inactivateError);
      dispatch(clearErrors());
    }
    if (inactivateStatus?.success) {
      showNotification("success", inactivateStatus?.message);
      dispatch(clearState());
      dispatch(getAllUsersByAdmin());
    }
  }, [
    inactivateError,
    dispatch,
    inactivateStatus?.success,
    inactivateStatus?.message,
    showNotification,
  ]);
  // INACTIVATE USER

  // MODIFY SEAT BOOKING ELIGIBILITY FOR COURSE CREATOR START
  const {
    loading: seatBookingLoading,
    error: seatBookingError,
    status: seatBookingStatus,
  } = useSelector((state) => state.seatBookingEligibility);

  const handleModifySeatBooking = (id) => {
    setTeacherId(id);
    dispatch(seatBookingModify(id));
  };

  useEffect(() => {
    if (seatBookingError) {
      showNotification("error", seatBookingError);
      dispatch(clearErrors());
    }
    if (seatBookingStatus?.success) {
      showNotification("success", seatBookingStatus?.message);
      dispatch(clearState());
      dispatch(getAllUsersByAdmin());
    }
  }, [
    seatBookingError,
    dispatch,
    seatBookingStatus?.success,
    seatBookingStatus?.message,
    showNotification,
  ]);
  // MODIFY SEAT BOOKING ELIGIBILITY FOR COURSE CREATOR END

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(allTeachers);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_teachers_main">
            <div className="container">
              <PageTitle title="Teachers" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search teachers"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    className="add_batch_button"
                    title="Create New Teacher"
                    onClick={() => navigate("/admin/create-teacher")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                <div className="navPanelFilter ">
                  <ul className="navTabs">
                    <li
                      onClick={() => handleTab(0)}
                      className={activeTab === 0 ? "active" : ""}
                    >
                      Alpha Teachers
                    </li>
                    <li
                      onClick={() => handleTab(1)}
                      className={activeTab === 1 ? "active" : ""}
                    >
                      Other Teachers
                    </li>
                  </ul>
                </div>

                {currentItems?.length <= 0 ? (
                  <h3 className="alertText_style">Teachers Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Profile Picture</th>
                          <th>Teacher name</th>
                          <th>Email</th>
                          <th>Contact No.</th>
                          <th>Verified or Not</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={
                                    item?.profile?.url
                                      ? item?.profile?.url
                                      : item?.gender === "Female"
                                      ? defaultFemalUser
                                      : defaultUser
                                  }
                                  alt={item?.first_name}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "5px",
                                  }}
                                />
                              </td>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              <td>
                                <p> {item?.email}</p>
                              </td>
                              <td>
                                <p> {item?.phone}</p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    color: item?.isVerified ? "green" : "red",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.isVerified ? "Verified" : "Unverified"}
                                </p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    title="Delete Teacher"
                                    onClick={() => deleteUserHandler(item?._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>

                                  {item?.isVerified ? (
                                    <button
                                      title="Unverify Teacher"
                                      onClick={() =>
                                        handleInactiveUser(item?._id)
                                      }
                                      style={{ color: "green" }}
                                    >
                                      {inactivateLoading &&
                                      teacherId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeInactive />
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      title="Verify Teacher"
                                      onClick={() =>
                                        handleActiveUser(item?._id)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      {activateLoading &&
                                      teacherId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeActive />
                                      )}
                                    </button>
                                  )}

                                  <button
                                    title="Modify Seat Booking Eligibility"
                                    onClick={() =>
                                      handleModifySeatBooking(item?._id)
                                    }
                                    style={{
                                      color: item?.seatBooking
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {seatBookingLoading &&
                                    teacherId === item?._id ? (
                                      <ButtonLoader />
                                    ) : (
                                      <VscFlame />
                                    )}
                                  </button>

                                  <button
                                    title="View/Edit Teacher"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-teacher/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => dispatch(deleteUserByAdmin(deleteId))}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Teacher?"
                loading={deletedUserLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllTeachersList;
