import React, { Fragment, useEffect, useState } from "react";
import "../../styles/createNewAdmin.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import CustomButton from "../../components/CustomButton";
import { clearState } from "../../redux/actions/batchAction";
import Loader from "../../components/Loader";
import { clearErrors as batchErrors } from "../../redux/actions/batchAction";

const CreateNewAdmin = () => {
  const dispatch = useDispatch();

  const { loading, batch, error } = useSelector((state) => state.createBatch);

  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
  });

  const [accessRight, setAccessRight] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const { fullName, email, password, confirmPassword, gender } = userData;

  const handleCreateBatch = () => {
    if (!fullName || email || !password || !confirmPassword || !gender) {
      alert("Please fill all fields carefully!");
    } else {
      alert("in process ");
    }
  };

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(batchErrors());
    }

    if (batch?.success) {
      alert(batch?.message);
      dispatch(clearState());
    }
  }, [batch?.message, batch?.success, dispatch, error]);

  // CREATE NEW BATCH ENDS
  // CREATE NEW BATCH ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="create_batch_main">
            <div className="container">
              <PageTitle title="Create New Admin" />

              <div className="create_batch_data">
                <div>
                  <div className="batch_form">
                    <CustomInput
                      label="Full name"
                      type="text"
                      placeholder="Ex-Jerry"
                      name="fullName"
                      value={userData?.fullName}
                      onChange={handleInputChange}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />
                    <CustomInput
                      label="Email"
                      type="email"
                      placeholder="Ex-xyz@gm.."
                      name="email"
                      value={userData?.email}
                      onChange={handleInputChange}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />
                    <CustomInput
                      label="Password"
                      type="password"
                      placeholder="***"
                      name="password"
                      value={userData?.password}
                      onChange={handleInputChange}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />
                    <CustomInput
                      label="Confirm Password"
                      type="Confirm Password"
                      placeholder="***"
                      name="confirmPassword"
                      value={userData?.confirmPassword}
                      onChange={handleInputChange}
                      impStar="*"
                      readOnly=""
                      borderColor=""
                    />

                    <CustomMultiSelect
                      label="Access List"
                      //   value={batchTeachers}
                      //   onChange={(val) => setBatchTeachers(val)}
                      //   isMulti={true}
                      //   optionArray={
                      //     allUsersLoading
                      //       ? [{ label: "Please Wait...", value: "" }]
                      //       : teacherOptions
                      //   }
                      disabled={false}
                      borderColor=""
                    />
                  </div>
                </div>
                <CustomButton
                  className="create_batch_btn"
                  title="Create Admin"
                  onClick={handleCreateBatch}
                  disabled={loading ? true : false}
                />
              </div>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateNewAdmin;
