import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import { FaRegPauseCircle, FaRegPlayCircle } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import PopupPrompt from "../../components/PopupPrompt";
import { useDispatch, useSelector } from "react-redux";
import {
  deletSsbCourse,
  getAllSsbCourses,
  pauseSsbCourse,
  ssbClearDeleteState,
  ssbClearErrors,
  ssbClearRatingDeleteState,
  verifySsbCourseAction,
} from "../../redux/actions/ssbCourseAction";
import { useNotification } from "../../context/notificationContext";
import VerifyCourseModal from "./VerifyCourseModal";
import { MdOutlineVerified } from "react-icons/md";
import usePaginate from "../../hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

const SsbCourseList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [pauseModal, setPauseModal] = useState({});
  const [verifyModal, setVerifyModal] = useState({});
  const [deleteMsg, setDeleteMsg] = useState({});
  const [isActive, setIsActive] = useState();
  const [status, setStatus] = useState();
  const [customStatus, setCustomStatus] = useState();
  // GET ALL SSB COURSES START

  const { loading, courses } = useSelector((state) => state.getSsbCourses);

  const allCourses = courses?.courses;

  useEffect(() => {
    dispatch(getAllSsbCourses());
  }, [dispatch]);

  // GET ALL SSB COURSES ENDS

  // PAUSE REQUEST STATE FROM STORE
  const {
    loading: pauseLoading,
    error: pauseError,
    status: pauseStatus,
  } = useSelector((state) => state.pauseSsbCourse);

  // COURSE PAUSE REQUEST
  const handlePauseRequest = (id) => {
    dispatch(pauseSsbCourse(id));
  };

  useEffect(() => {
    if (pauseError) {
      dispatch(ssbClearErrors());
      showNotification("error", pauseError);
    }
    if (pauseStatus?.success) {
      showNotification("success", pauseStatus?.message);
      dispatch(ssbClearDeleteState());
      setPauseModal(false);
      dispatch(getAllSsbCourses());
    }
  }, [
    dispatch,
    showNotification,
    pauseError,
    pauseStatus?.success,
    pauseStatus?.message,
  ]);

  // DELETE SSB COUIRSE BY ID START
  const {
    loading: courseDeleteLoading,
    course,
    error: courseDeleteError,
  } = useSelector((state) => state.deleteSsbCourse);

  // HANDLE DDELETE POPUP SHOW
  const handleDelete = (id) => {
    dispatch(deletSsbCourse(id));
  };

  useEffect(() => {
    if (courseDeleteError) {
      showNotification("error", courseDeleteError);
      dispatch(ssbClearErrors());
    }
    if (course?.success) {
      showNotification("success", course?.message);
      dispatch(getAllSsbCourses());
      setDeleteMsg(false);
      dispatch(ssbClearDeleteState());
    }
  }, [
    courseDeleteError,
    dispatch,
    course?.message,
    course?.success,
    showNotification,
  ]);

  // VERIFICATION PROCESS START
  const {
    loading: verificationLoading,
    course: verifyState,
    error: verifyError,
  } = useSelector((state) => state.verifySsbCourse);

  const handleVerify = (e) => {
    e.preventDefault();
    const sendingStatus = customStatus ? customStatus : status;
    if (sendingStatus) {
      dispatch(verifySsbCourseAction(verifyModal.id, isActive, sendingStatus));
    } else {
      showNotification("error", "Activation Status is required");
    }
  };

  useEffect(() => {
    if (verifyError) {
      showNotification("error", verifyError);
      dispatch(ssbClearErrors());
    }
    if (verifyState?.success) {
      showNotification("success", verifyState?.message);
      dispatch(getAllSsbCourses());
      setVerifyModal(false);
      dispatch(ssbClearRatingDeleteState());
    }
  }, [
    verifyError,
    dispatch,
    verifyState?.message,
    verifyState?.success,
    showNotification,
  ]);
  // VERIFICATION PROCESS ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(allCourses);
  // Pagination Hook Ends

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_course_main">
            <div className="container">
              <PageTitle title="SSB Courses" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search Course"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />

                  {/* <button
                    className="add_batch_button"
                    onClick={() => navigate("/admin/create-ssb-course")}
                  >
                    <AiOutlinePlus />
                  </button> */}
                </div>

                {currentItems?.length > 0 ? (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Course Thumbnail</th>
                          <th>Course name</th>
                          <th>Study Mode</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems?.map((item, idx) => {
                            const createdDate = item?.createdAt;
                            const formattedCreatedate = moment(
                              createdDate
                            ).format("DD-MM-YYYY [at] hh:mm A");
                            return (
                              <tr key={item?._id}>
                                <td>
                                  <p>
                                    <img
                                      width={90}
                                      src={item?.thumbnail?.url}
                                      alt={item?.title}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>{item?.title}</p>
                                </td>
                                <td>
                                  <p>{item?.courseMode}</p>
                                </td>
                                <td>
                                  <p>{formattedCreatedate}</p>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      onClick={() =>
                                        setDeleteMsg({
                                          show: true,
                                          id: item._id,
                                        })
                                      }
                                    >
                                      <RiDeleteBin5Line />
                                    </button>
                                    <button
                                      title={
                                        item?.isPause
                                          ? "Continue this course"
                                          : "Pause this course"
                                      }
                                      onClick={() =>
                                        setPauseModal({
                                          show: true,
                                          id: item?._id,
                                          status: item?.isPause,
                                        })
                                      }
                                      style={{
                                        color: item?.isPause ? "red" : "green",
                                      }}
                                    >
                                      {item?.isPause ? (
                                        <FaRegPlayCircle />
                                      ) : (
                                        <FaRegPauseCircle />
                                      )}
                                    </button>
                                    <button
                                      title={
                                        !item?.isActive
                                          ? "Verify this course"
                                          : "Disprove this course"
                                      }
                                      onClick={() =>
                                        setVerifyModal({
                                          show: true,
                                          id: item?._id,
                                          isActive: item?.isActive,
                                        })
                                      }
                                      style={{
                                        color: item?.isActive ? "green" : "red",
                                      }}
                                    >
                                      <MdOutlineVerified />
                                    </button>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/view-edit-course/${item._id}`
                                        )
                                      }
                                    >
                                      <AiFillEye />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="txt_color txt_center">
                    <p>Courses Not Found.</p>
                  </div>
                )}

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* PROMPT START */}
          {deleteMsg.show && (
            <PopupPrompt
              confirmBtnTxt="Confirm"
              cancelBtnTxt="Cancel"
              title={"Are you sure! You want to Delete this course."}
              confirmOnclick={() => handleDelete(deleteMsg?.id)}
              cancelOnclick={() => setDeleteMsg(false)}
              loading={courseDeleteLoading}
              inputPlaceholder="DELETE"
              inputLabel={"Type DELETE in capital letters"}
            />
          )}
          {/* PROMPT ENDS */}
          {/* Pause COURSE POPUP START */}
          {pauseModal.show && (
            <PopupPrompt
              confirmBtnTxt="Confirm"
              cancelBtnTxt="Cancel"
              title={
                pauseModal?.status
                  ? "Are you sure! You want to Continue this course."
                  : "Are you sure! You want to Pause this course."
              }
              confirmOnclick={() => handlePauseRequest(pauseModal?.id)}
              cancelOnclick={() => setPauseModal(false)}
              loading={pauseLoading}
              inputPlaceholder={pauseModal?.status ? "CONTINUE" : "PAUSE"}
              inputLabel={
                pauseModal?.status
                  ? "Type CONTINUE in capital letters"
                  : "Type PAUSE in capital letters"
              }
            />
          )}
          {/* DELETE COURSE POPUP ENDS */}
          {/* VERIFY COURSE PROMPT START */}
          {verifyModal.show && (
            <VerifyCourseModal
              verifyModal={verifyModal}
              setModal={setVerifyModal}
              isActive={isActive}
              setIsActive={setIsActive}
              setStatus={setStatus}
              status={status}
              setCustomStatus={setCustomStatus}
              customStatus={customStatus}
              handleVerify={handleVerify}
              verificationLoading={verificationLoading}
            />
          )}
          {/* VERIFY COURSE PROMPT ENDS */}
        </Layout>
      )}
    </Fragment>
  );
};

export default SsbCourseList;
