import {
  SSB_COURSES_FAIL,
  SSB_COURSES_REQUEST,
  SSB_COURSES_SUCCESS,
  SSB_CLEAR_ERRORS,
  SSB_COURSE_DETAIL_FAIL,
  SSB_COURSE_DETAIL_REQUEST,
  SSB_COURSE_DETAIL_SUCCESS,
  SSB_COURSES_CREATE_REQUEST,
  SSB_COURSES_CREATE_SUCCESS,
  SSB_COURSES_CREATE_FAIL,
  SSB_CLEAR_STATE,
  OFFICER_SSB_COURSES_REQUEST,
  OFFICER_SSB_COURSES_SUCCESS,
  OFFICER_SSB_COURSES_FAIL,
  OFFICER_SSB_COURSES_DELETE_REQUEST,
  OFFICER_SSB_COURSES_DELETE_SUCCESS,
  OFFICER_SSB_COURSES_DELETE_FAIL,
  SSB_COURSE_ADD_REVIEW_REQUEST,
  SSB_COURSE_ADD_REVIEW_SUCCESS,
  SSB_COURSE_ADD_REVIEW_FAIL,
  SSB_COURSE_DELETE_REVIEW_REQUEST,
  SSB_COURSE_DELETE_REVIEW_SUCCESS,
  SSB_COURSE_DELETE_REVIEW_FAIL,
  SSB_CLEAR_DELETE_STATE,
  SSB_RATING_CLEAR_ERRORS,
  SSB_RATING_CLEAR_STATE,
  SSB_RATING_CLEAR_DELETE_STATE,
  SSB_COURSES_PAUSE_REQUEST,
  SSB_COURSES_PAUSE_SUCCESS,
  SSB_COURSES_PAUSE_FAIL,
  SSB_COURSE_VERFY_REQUEST,
  SSB_COURSE_VERFY_SUCCESS,
  SSB_COURSE_VERFY_FAIL,
} from "../constants/ssbCourseConstant";

export const ssbCourseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSES_CREATE_REQUEST:
      return {
        loading: true,
        courseData: {},
      };
    case SSB_COURSES_CREATE_SUCCESS:
      return {
        loading: false,
        courseData: action.payload,
      };
    case SSB_COURSES_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case SSB_CLEAR_STATE:
      return {
        loading: false,
        courseData: {},
      };

    default:
      return state;
  }
};

// GET ALL COURSES REDUCER
export const getSsbCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSES_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case SSB_COURSES_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case SSB_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET OFFICER SSB COURSES
export const getOfficerSsbCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFICER_SSB_COURSES_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case OFFICER_SSB_COURSES_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case OFFICER_SSB_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE DETAILS REDUCER
export const getSsbCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSE_DETAIL_REQUEST:
      return {
        loading: true,
        courseDetails: {},
      };
    case SSB_COURSE_DETAIL_SUCCESS:
      return {
        loading: false,
        courseDetails: action.payload,
      };
    case SSB_COURSE_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        courseDetails: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE COURSE DETAILS REDUCER
export const deleteSsbCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFICER_SSB_COURSES_DELETE_REQUEST:
      return {
        loading: true,
        course: {},
      };
    case OFFICER_SSB_COURSES_DELETE_SUCCESS:
      return {
        loading: false,
        course: action.payload,
      };
    case OFFICER_SSB_COURSES_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SSB_CLEAR_DELETE_STATE:
      return {
        course: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// PAUSE SSB COURSE REDUCER
export const pauseSsbCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSES_PAUSE_REQUEST:
      return {
        loading: true,
        status: {},
      };
    case SSB_COURSES_PAUSE_SUCCESS:
      return {
        loading: false,
        status: action.payload,
      };
    case SSB_COURSES_PAUSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SSB_CLEAR_DELETE_STATE:
      return {
        status: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// ADD SSB COURSE RATING
export const addSsbCourseRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSE_ADD_REVIEW_REQUEST:
      return {
        loading: true,
        courseReview: {},
      };
    case SSB_COURSE_ADD_REVIEW_SUCCESS:
      return {
        loading: false,
        courseReview: action.payload,
      };
    case SSB_COURSE_ADD_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_RATING_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SSB_RATING_CLEAR_STATE:
      return {
        courseReview: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE SSB COURSE RATING
export const deleteSsbCourseRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSE_DELETE_REVIEW_REQUEST:
      return {
        loading: true,
        courseReview: {},
      };
    case SSB_COURSE_DELETE_REVIEW_SUCCESS:
      return {
        loading: false,
        courseReview: action.payload,
      };
    case SSB_COURSE_DELETE_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_RATING_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SSB_RATING_CLEAR_DELETE_STATE:
      return {
        courseReview: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// VERIFY/NOT VERIFY SSB COURSE
export const verifySsbCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case SSB_COURSE_VERFY_REQUEST:
      return {
        loading: true,
        course: {},
      };
    case SSB_COURSE_VERFY_SUCCESS:
      return {
        loading: false,
        course: action.payload,
      };
    case SSB_COURSE_VERFY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SSB_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SSB_RATING_CLEAR_DELETE_STATE:
      return {
        course: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
