import {
  CLEAR_ERROR,
  CLEAR_STATE,
  CREATE_BATCH_FAIL,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  GET_BATCHES_FAIL,
  GET_BATCHES_REQUEST,
  GET_BATCHES_SUCCESS,
  GET_BATCH_BY_ID_FAIL,
  GET_BATCH_BY_ID_REQUEST,
  GET_BATCH_BY_ID_SUCCESS,
} from "../constants/batchConstant";

// CREATE NEW BATCH BY ADMIN
export const createBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BATCH_REQUEST:
      return {
        loading: true,
        batch: {},
      };
    case CREATE_BATCH_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
      };
    case CREATE_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        batch: {},
      };

    default:
      return state;
  }
};

// GET ALL BATCHES
export const getBatchesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BATCHES_REQUEST:
      return {
        loading: true,
        batches: {},
      };
    case GET_BATCHES_SUCCESS:
      return {
        loading: false,
        batches: action.payload,
      };
    case GET_BATCHES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        batches: {},
      };

    default:
      return state;
  }
};

// GET ALL BATCH BY ID
export const getBatchesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BATCH_BY_ID_REQUEST:
      return {
        loading: true,
        batch: {},
      };
    case GET_BATCH_BY_ID_SUCCESS:
      return {
        loading: false,
        batch: action.payload,
      };
    case GET_BATCH_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        batch: {},
      };

    default:
      return state;
  }
};

// DELETE BATCH BY ADMIN
export const deleteBatchByAdmin = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BATCH_REQUEST:
      return {
        loading: true,
        deletedBatch: {},
      };
    case DELETE_BATCH_SUCCESS:
      return {
        loading: false,
        deletedBatch: action.payload,
      };
    case DELETE_BATCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        loading: false,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        deletedBatch: {},
      };

    default:
      return state;
  }
};
