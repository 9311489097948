import React from "react";
import "../../styles/createAffiliateProfile.scss";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { defaultUser } from "../../assets/images";
import {
  clearErrors,
  createAffiliateByAdmin,
} from "../../redux/actions/userAction";
import { useEffect } from "react";
import { useNotification } from "../../context/notificationContext";

const CreateAffiliate = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // UPDATE PROFILE STATE
  const [affiliateData, setAffiliateData] = useState({
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    address: "",
    password: "",
    confirmPassword: "",
    couponCode: "",
  });

  const {
    first_name,
    email,
    phone,
    dob,
    gender,
    address,
    password,
    confirmPassword,
    couponCode,
  } = affiliateData;

  // INPUT CHNAGE HANDLER
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setAffiliateData({
      ...affiliateData,
      [name]: value,
    });
  };

  // CREATE AFFILIATE STATE AND HANDLERS START
  // CREATE AFFILIATE STATE AND HANDLERS START
  const { loading, affiliate, error } = useSelector(
    (state) => state.createAffiliate
  );

  console.log(affiliate);

  const createAffiliateHandler = () => {
    if (
      !first_name ||
      !email ||
      !phone ||
      !gender ||
      !address ||
      !couponCode ||
      !password ||
      !confirmPassword
    ) {
      showNotification("warning", "Please fill all (*) fields!");
    } else if (password !== confirmPassword) {
      showNotification("warning", "Password & Confirm Password must be same.");
    } else {
      dispatch(
        createAffiliateByAdmin(
          first_name,
          email,
          phone,
          dob,
          gender,
          address,
          password,
          "15",
          couponCode
        )
      );
    }
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (affiliate?.success) {
      showNotification("success", affiliate?.message);
      dispatch(clearErrors());
      setAffiliateData({
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        address: "",
        password: "",
        confirmPassword: "",
        couponCode: "",
      });
    }
  }, [dispatch, error, affiliate?.message, affiliate?.success, showNotification]);
  // CREATE AFFILIATE STATE AND HANDLERS ENDS
  // CREATE AFFILIATE STATE AND HANDLERS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_affiliate_main">
            <div className="container">
              <PageTitle title="Create Affiliate" />

              <section className="affiliate_profile_section txt_color">
                {/* <div className="container"> */}
                {/* <h2 className="profilePage_heading">Account Info</h2> */}

                <div className="user_details_main">
                  <div className="user_pro_data">
                    {/* <h2>Profile</h2> */}
                    <div>
                      <div className="data_card">
                        <CustomInput
                          label="Full Name"
                          type="text"
                          placeholder="Full Name"
                          impStar="*"
                          name="first_name"
                          value={first_name}
                          onChange={handleInputChange}
                        />

                        <CustomInput
                          label="Email"
                          type="email"
                          placeholder="example@email.com"
                          impStar="*"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                        <CustomInput
                          label="Whatsapp Number"
                          type="number"
                          placeholder="0987654321"
                          impStar="*"
                          name="phone"
                          value={phone}
                          onChange={handleInputChange}
                        />
                        <CustomInput
                          label="Date Of Birth"
                          type="date"
                          placeholder="26-11-1998"
                          name="dob"
                          value={dob}
                          onChange={handleInputChange}
                        />
                        <CustomSelect
                          label="Gender"
                          name="gender"
                          impStar="*"
                          value={gender}
                          onChange={handleInputChange}
                          optionArray={[
                            {
                              id: 1,
                              option: "Male",
                            },
                            {
                              id: 2,
                              option: "Female",
                            },
                            {
                              id: 3,
                              option: "Others",
                            },
                          ]}
                        />

                        <CustomInput
                          label="Address"
                          type="text"
                          placeholder="Ex: MG Road, Gurugram"
                          impStar="*"
                          name="address"
                          value={address}
                          onChange={handleInputChange}
                        />
                        <CustomInput
                          label="Unique Coupon Code"
                          type="text"
                          placeholder="Unique Coupon Code In Capital Letters"
                          impStar="*"
                          name="couponCode"
                          value={couponCode}
                          onChange={handleInputChange}
                        />
                        <CustomInput
                          label="Password"
                          type="text"
                          placeholder="********"
                          impStar="*"
                          name="password"
                          value={password}
                          onChange={handleInputChange}
                        />
                        <CustomInput
                          label="Confirm Password"
                          type="text"
                          placeholder="********"
                          impStar="*"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="profile_picSec">
                        <div className="user_pro_pic">
                          <img src={defaultUser} alt="alpha regiment user" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <CustomButton
                    onClick={createAffiliateHandler}
                    title="Create Affiliate"
                    className="create_affilaite_btn"
                    disabled=""
                  />
                </div>
                {/* </div> */}
              </section>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateAffiliate;
