import {
  ALL_VID_TESTI_FAIL,
  ALL_VID_TESTI_REQUEST,
  ALL_VID_TESTI_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
  CREATE_VID_TESTI_FAIL,
  CREATE_VID_TESTI_REQUEST,
  CREATE_VID_TESTI_SUCCESS,
  DELETE_VID_TESTI_FAIL,
  DELETE_VID_TESTI_REQUEST,
  DELETE_VID_TESTI_SUCCESS,
  GET_VID_TESTI_DETAIL_FAIL,
  GET_VID_TESTI_DETAIL_REQUEST,
  GET_VID_TESTI_DETAIL_SUCCESS,
  UPDATE_VIDEO_TESTI_FAIL,
  UPDATE_VIDEO_TESTI_REQUEST,
  UPDATE_VIDEO_TESTI_SUCCESS,
} from "../constants/vidTestiConstant";

// CREATE VIDEO TESTIMONIAL REDUCER - ADMIN
export const createVideoTestimonialsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_VID_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: {},
      };
    case CREATE_VID_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case CREATE_VID_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: {},
      };

    default:
      return state;
  }
};

// CREATE VIDEO TESTIMONIAL REDUCER - ADMIN
export const allVideoTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_VID_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: {},
      };
    case ALL_VID_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case ALL_VID_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: {},
      };

    default:
      return state;
  }
};

// DELETE VIDEO TESTIMONIAL REDUCER - ADMIN
export const deleteVideoTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VID_TESTI_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DELETE_VID_TESTI_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DELETE_VID_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        data: {},
      };

    default:
      return state;
  }
};

// GET VIDEO TESTIMONIAL BY ID REDUCER - ADMIN
export const getVideoTestimonialByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VID_TESTI_DETAIL_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_VID_TESTI_DETAIL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_VID_TESTI_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        data: {},
      };

    default:
      return state;
  }
};

// UPDATE VIDEO TESTIMONIAL REDUCER - ADMIN
export const updateVideoTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: {},
      };
    case UPDATE_VIDEO_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case UPDATE_VIDEO_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: {},
      };

    default:
      return state;
  }
};
