import { useState } from "react";
import "../styles/paginationHook.scss";

const usePaginate = (itemArray, itemsPerPage = 10) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;

  let currentItems = itemArray?.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(itemArray?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemArray?.length;
    setItemOffset(newOffset);
  };

  return { currentItems, pageCount, handlePageClick };
};

export default usePaginate;
