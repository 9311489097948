import React from "react";
import "../../styles/viewEditCourse.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { FaExpeditedssl, FaUserEdit } from "react-icons/fa";
import { useState } from "react";
import CustomTextarea from "../../components/CustomTextarea";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearState,
  createCourseDetails,
  createCourseMetaAction,
  getCourseById,
  getCourseLongDetails,
  getCourseMetaDetails,
  updateCourse,
} from "../../redux/actions/courseAction";
import { clearErrors as clearUserErrors } from "../../redux/actions/userAction";
import ButtonLoader from "../../components/ButtonLoader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import ToggleSwitch from "../../components/ToggleSwitch";
import { BsCalendarDay } from "react-icons/bs";
import { getAllUsersByAdmin } from "../../redux/actions/userAction";
import CustomMultiSelect from "../../components/CustomMultiSelect";
import { useNotification } from "../../context/notificationContext";

const ViewEditCourse = () => {
  const [editable, setEditable] = useState(false); // For Course Edit
  const [editCourseDetails, setEditCourseDetails] = useState(false); // For Course Details
  const [editCourseMeta, setEditCourseMeta] = useState(false); // For Course Meta Details
  const dispatch = useDispatch();
  const [thumbnail, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();
  const [isEmiAvail, setIsEmiAvail] = useState(false);
  const [allEmis, setAllEmis] = useState([]); // Add all EMIs of this course in this state
  const [courseTeachers, setCourseTeachers] = useState([]);
  const [, , showNotification] = useNotification();

  // GET ALL TEACHER USERS START
  // GET ALL TEACHER USERS START
  const {
    loading: allUsersLoading,
    allUsers,
    error: allUsersErrors,
  } = useSelector((state) => state.getUsersByAdmin);

  const allTeachers = allUsers?.allTeachers;

  const teacherOptions = [];
  allTeachers?.forEach((item) => {
    teacherOptions.push({
      value: item?._id,
      label: item?.first_name,
    });
  });

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (allUsersErrors) {
      showNotification("error", allUsersErrors);
      dispatch(clearUserErrors());
    }
  }, [allUsersErrors, dispatch, showNotification]);
  // GET ALL TEACHER USERS ENDS
  // GET ALL TEACHER USERS ENDS

  // CREATE EMI FOR COURSE START
  // CREATE EMI FOR COURSE START
  const [courseEMIs, setCourseEMIs] = useState({
    emiName: "",
    emiAmount: "",
    emiDuration: "",
  });

  // ADD EMI IN LOCALSTORAGE FOR TEMP START
  // useEffect(() => {
  //   localStorage.setItem("allEmis", JSON.stringify(allEmis));
  // }, [allEmis]);
  // ADD EMI IN LOCALSTORAGE FOR TEMP ENDS

  const handleAddEMI = (e) => {
    const { value, name } = e.target;
    setCourseEMIs({
      ...courseEMIs,
      [name]: value,
    });
  };

  const handleRemoveEmi = (id) => {
    var newData = allEmis;
    newData.splice(id, 1);
    setAllEmis([...newData]);
    showNotification("success", `EMI number ${id + 1} removed`);
  };

  const addAllEmis = () => {
    if (courseEMIs.emiAmount || courseEMIs.emiName || courseEMIs.emiDuration) {
      setAllEmis([...allEmis, courseEMIs]);
    } else {
      showNotification("warning", "Add All EMIs Parameters!");
    }
  };
  // CREATE EMI FOR COURSE ENDS
  // CREATE EMI FOR COURSE ENDS

  const {
    loading,
    course: courseById,
    error,
  } = useSelector((state) => state.getCourseById);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourseById(id)); // get course details by id
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }

    if (courseById?.success) {
      showNotification("success", courseById?.message);
      setCourseTeachers(courseById?.course?.courseTeachers); //Set all course teachers in the state
      setAllEmis(courseById?.course?.allEmis); // set course's available emis in this state
      setIsEmiAvail(courseById?.course?.isEmiAvail); // set course's availablity of emis in this state
      setAvatarPreview(courseById?.course?.thumbnail?.url);
      dispatch(clearState());
    }
  }, [
    dispatch,
    error,
    courseById?.success,
    courseById?.message,
    courseById?.course?.allEmis,
    courseById?.course?.isEmiAvail,
    courseById?.course?.thumbnail?.url,
    courseById?.course?.courseTeachers, showNotification
  ]);

  // STORED STATE FOR COURSE CREATION DATA
  const [courseData, setCourseData] = useState({
    title: courseById?.course?.title,
    titleHindi: courseById?.course?.titleHindi,
    description: courseById?.course?.description,
    descriptionHindi: courseById?.course?.descriptionHindi,
    original_price: courseById?.course?.original_price,
    discounted_price: courseById?.course?.discounted_price,
    study_mode: courseById?.course?.study_mode,
    affiliate_percentage: courseById?.course?.affiliate_percentage,
    starting_date: courseById?.course?.starting_date,
    goalType: courseById?.course?.goalType,
    courseCategory: courseById?.course?.courseCategory,
    bannervideoLink: courseById?.course?.bannervideoLink,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "thumbnail") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setCourseData({
        ...courseData,
        [name]: value,
      });
    }
  };

  const {
    loading: courseUpdateLoading,
    courseUpdate: courseUpdateData,
    error: courseUpdateError,
  } = useSelector((state) => state.updateCourse);

  // UPDATE COURSE HANDLER
  const handleCourseUpdate = () => {
    dispatch(
      updateCourse(
        id,
        courseData?.title,
        courseData?.titleHindi,
        courseData?.description,
        courseData?.descriptionHindi,
        courseData?.original_price,
        courseData?.discounted_price,
        courseData?.study_mode,
        courseData?.affiliate_percentage,
        thumbnail,
        courseData?.starting_date,
        courseData?.goalType,
        courseData?.courseCategory,
        courseData?.bannervideoLink,
        isEmiAvail,
        allEmis,
        courseTeachers
      )
    );
  };

  useEffect(() => {
    if (courseUpdateError) {
      showNotification("error", courseUpdateError);
      dispatch(clearErrors());
    }
    if (courseUpdateData?.success) {
      showNotification("success", courseUpdateData?.message);
      setEditable(false);
      dispatch(clearState());
      setTimeout(() => {
        dispatch(getCourseById(id)); // GET UPDATED COURSE DETAILS
        dispatch(clearState());
      }, 3000);
      dispatch(clearState());
    }
  }, [
    dispatch,
    courseUpdateError,
    courseUpdateData?.success,
    courseUpdateData?.message,
    id, showNotification
  ]);

  // COURSE LONG DETAILS QUIRIES START
  // COURSE LONG DETAILS QUIRIES START
  const {
    loading: courseLongDetailsLoading,
    courseLongDetails,
    error: courseLongDetailsError,
  } = useSelector((state) => state.getCourseLongDetails);

  const courseLongDetailsParam = courseLongDetails?.courseDetail;

  useEffect(() => {
    if (id) {
      dispatch(getCourseLongDetails(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (courseLongDetailsError) {
      showNotification("error", courseLongDetailsError);
      dispatch(clearErrors());
    }
    // if (!courseLongDetails?.success) {
    //    showNotification("success", courseLongDetails?.message);
    //   dispatch(clearState());
    // }
  }, [
    courseLongDetailsError,
    dispatch,
    courseLongDetails?.success,
    courseLongDetails?.message, showNotification
  ]);

  const [courseDetails, setcourseDetails] = useState({
    for_course: id,
    contentEn1: courseLongDetailsParam?.contentEn1,
    contentEn2: courseLongDetailsParam?.contentEn2,
    contentEn3: courseLongDetailsParam?.contentEn3,
    contentEn4: courseLongDetailsParam?.contentEn4,
    contentHi1: courseLongDetailsParam?.contentHi1,
    contentHi2: courseLongDetailsParam?.contentH12,
    contentHi3: courseLongDetailsParam?.contentHi3,
    contentHi4: courseLongDetailsParam?.contentHi4,
  });

  const handleCourseDetailsChange = (e) => {
    const { name, value } = e.target;
    setcourseDetails({
      ...courseDetails,
      [name]: value,
    });
  };

  const createCourseDetailsHandler = () => {
    if (
      (courseDetails?.for_course,
        courseDetails?.contentEn1,
        courseDetails?.contentEn2,
        courseDetails?.contentEn3,
        courseDetails?.contentEn4,
        courseDetails?.contentHi1,
        courseDetails?.contentHi2,
        courseDetails?.contentHi3,
        courseDetails?.contentHi4)
    ) {
      dispatch(
        createCourseDetails(
          courseDetails?.for_course,
          courseDetails?.contentEn1,
          courseDetails?.contentEn2,
          courseDetails?.contentEn3,
          courseDetails?.contentEn4,
          courseDetails?.contentHi1,
          courseDetails?.contentHi2,
          courseDetails?.contentHi3,
          courseDetails?.contentHi4
        )
      );
    } else {
      showNotification("warning", "Please fill all (*) data!");
    }
  };

  const {
    loading: createdCourseDetailsLoading,
    courseDetails: createdCourseDetails,
    error: createdCourseDetailsError,
  } = useSelector((state) => state.createCourseDetails);

  useEffect(() => {
    if (createdCourseDetailsError) {
      showNotification("error", createdCourseDetailsError);
      dispatch(clearErrors());
    }
    if (createdCourseDetails?.success) {
      showNotification("success", createdCourseDetails?.message);
      dispatch(getCourseLongDetails(id));
      setEditCourseDetails(false);
      dispatch(clearState());
    }
  }, [
    createdCourseDetailsError,
    dispatch,
    createdCourseDetails?.success,
    createdCourseDetails?.message,
    id, showNotification
  ]);
  // COURSE LONG DETAILS QUIRIES ENDS
  // COURSE LONG DETAILS QUIRIES ENDS

  // UPDATE COURSE META TAGS START
  // UPDATE COURSE META TAGS START
  const {
    loading: courseMetaDetailsLoading,
    courseMetaDetails,
    error: courseMetaDetailsError,
  } = useSelector((state) => state.getCourseMetaDetails);

  const courseMetaDetailsParam = courseMetaDetails?.courseMetaDetail;

  // GET CPURSE META FUNCTION
  useEffect(() => {
    if (id) {
      dispatch(getCourseMetaDetails(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (courseMetaDetailsError) {
      showNotification("error", courseMetaDetailsError);
      dispatch(clearErrors());
    }
    if (courseMetaDetails?.success) {
      dispatch(clearErrors());
    }
  }, [courseMetaDetailsError, dispatch, courseMetaDetails?.success, showNotification]);

  // UPDATE META STATE
  const {
    loading: metaTagsLoading,
    metaTags,
    error: metaTagsError,
  } = useSelector((state) => state.updateCourseMeta);

  const [courseMetaData, setCourseMetaData] = useState({
    for_course: id,
    metaTitle: courseMetaDetailsParam?.metaTitle,
    metaKeywords: courseMetaDetailsParam?.metaKeywords,
    metaDescription: courseMetaDetailsParam?.metaDescription,
    metaAuthor: courseMetaDetailsParam?.metaAuthor,
    metaTitleHindi: courseMetaDetailsParam?.metaTitleHindi,
    metaKeywordsHindi: courseMetaDetailsParam?.metaKeywordsHindi,
    metaDescriptionHindi: courseMetaDetailsParam?.metaDescriptionHindi,
    metaAuthorHindi: courseMetaDetailsParam?.metaAuthorHindi,
  });

  const handleCourseMetaChange = (e) => {
    const { name, value } = e.target;
    setCourseMetaData({
      ...courseMetaData,
      [name]: value,
    });
  };

  // CREATE COURSE META HANDLER
  const createCourseMetaHandler = () => {
    if (
      courseMetaData?.for_course &&
      courseMetaData?.metaTitle &&
      courseMetaData?.metaKeywords &&
      courseMetaData?.metaDescription &&
      courseMetaData?.metaAuthor &&
      courseMetaData?.metaTitleHindi &&
      courseMetaData?.metaKeywordsHindi &&
      courseMetaData?.metaDescriptionHindi &&
      courseMetaData?.metaAuthorHindi
    ) {
      dispatch(
        createCourseMetaAction(
          courseMetaData?.for_course,
          courseMetaData?.metaTitle,
          courseMetaData?.metaKeywords,
          courseMetaData?.metaDescription,
          courseMetaData?.metaAuthor,
          courseMetaData?.metaTitleHindi,
          courseMetaData?.metaKeywordsHindi,
          courseMetaData?.metaDescriptionHindi,
          courseMetaData?.metaAuthorHindi
        )
      );
    } else {
      showNotification("warning", "Fill all required (*) details!");
    }
  };

  useEffect(() => {
    if (metaTagsError) {
      showNotification("error", metaTagsError);
      dispatch(clearErrors());
    }
    if (metaTags?.success) {
      showNotification("success", metaTags?.message);
      dispatch(getCourseMetaDetails(id));
      dispatch(clearErrors());
      setEditCourseMeta(false);
    }
    // if (!metaTags?.success) {
    //    showNotification("success", metaTags?.message);
    // }
  }, [metaTagsError, dispatch, metaTags?.success, metaTags?.message, id, showNotification]);

  // UPDATE COURSE META TAGS ENDS
  // UPDATE COURSE META TAGS ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="viewEdit_course_main">
            <div className="container">
              <div className="title_header">
                <PageTitle title="View/Edit course" />
                <button
                  className={`courseEditBtn ${editable ? "activeBtn" : ""}`}
                  onClick={() => setEditable(!editable)}
                >
                  {editable ? <FaExpeditedssl /> : <FaUserEdit />} Edit
                </button>
              </div>

              <div className="create_batch_data">
                <div className="batch_form">
                  <CustomMultiSelect
                    label="Select Course Teachers *"
                    value={courseTeachers}
                    onChange={(val) => setCourseTeachers(val)}
                    isMulti={true}
                    optionArray={teacherOptions}
                    disabled={allUsersLoading || !editable ? true : false}
                    borderColor={allUsersLoading || editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Course name"
                    type="text"
                    placeholder="AGNIVEER + SSB"
                    name="title"
                    onChange={handleInputChange}
                    value={
                      editable ? courseData?.title : courseById?.course?.title
                    }
                    impStar="*"
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="कोर्स का नाम"
                    type="text"
                    placeholder="अग्निवीर + एसएसबी"
                    name="titleHindi"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.titleHindi
                        : courseById?.course?.titleHindi
                    }
                    impStar="*"
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Original Price"
                    type="Number"
                    placeholder="Original Price"
                    name="original_price"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.original_price
                        : courseById?.course?.original_price
                    }
                    impStar="*"
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Discounted Price"
                    type="Number"
                    placeholder="Discounted Price"
                    name="discounted_price"
                    icon="₹"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.discounted_price
                        : courseById?.course?.discounted_price
                    }
                    impStar="*"
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomSelect
                    label="Study Mode"
                    name="study_mode"
                    impStar="*"
                    value={
                      editable
                        ? courseData?.study_mode
                        : courseById?.course?.study_mode
                    }
                    onChange={handleInputChange}
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                    optionArray={[
                      { id: 1, option: "Online" },
                      { id: 2, option: "Offline" },
                      { id: 3, option: "Both Online/Offline" },
                    ]}
                  />
                  <CustomInput
                    label="Affiliate Percentage"
                    type="Number"
                    placeholder="Affiliate Percentage"
                    name="affiliate_percentage"
                    icon="%"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.affiliate_percentage
                        : courseById?.course?.affiliate_percentage
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Starting From"
                    type="text"
                    placeholder="Starting From Text (Custom)"
                    name="starting_date"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.starting_date
                        : courseById?.course?.starting_date
                    }
                    impStar="*"
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Goal Type"
                    type="text"
                    placeholder="Goal Type i.e; Armed Forces | Para Military"
                    name="goalType"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.goalType
                        : courseById?.course?.goalType
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />

                  <CustomInput
                    label="Course Category"
                    type="text"
                    placeholder="Course Category, i.e; Air Force | Navy | Army | CAPF"
                    name="courseCategory"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.courseCategory
                        : courseById?.course?.courseCategory
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomInput
                    label="Banner video Link"
                    type="text"
                    placeholder="Note: Please provide youtube embed video link!"
                    name="bannervideoLink"
                    onChange={handleInputChange}
                    value={
                      editable
                        ? courseData?.bannervideoLink
                        : courseById?.course?.bannervideoLink
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                </div>
                <div className="course_summary">
                  <CustomTextarea
                    label="Course Description *"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="Course Description..."
                    name="description"
                    value={
                      editable
                        ? courseData?.description
                        : courseById?.course?.description
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  <CustomTextarea
                    label="कोर्स डिस्क्रिप्शन *"
                    rows="10"
                    type="text"
                    onChange={handleInputChange}
                    placeholder="कोर्स डिस्क्रिप्शन हिंदी में लिखें..."
                    name="descriptionHindi"
                    value={
                      editable
                        ? courseData?.descriptionHindi
                        : courseById?.course?.descriptionHindi
                    }
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />
                  {(avatarPreview || courseById?.course?.thumbnail?.url) && (
                    <img
                      src={
                        avatarPreview
                          ? avatarPreview
                          : courseById?.course?.thumbnail?.url
                      }
                      alt={avatarPreview}
                      width="100%"
                      height="200px"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #f2f2f2",
                        borderRadius: "16px",
                        objectFit: "contain",
                      }}
                    />
                  )}

                  <CustomInput
                    label="Course Thumbnail"
                    type="file"
                    name="thumbnail"
                    accept="image/*"
                    impStar="*"
                    onChange={handleInputChange}
                    readOnly={editable ? false : true}
                    borderColor={editable ? "#f2f2f2" : ""}
                  />

                  {/* {
                    allEmis?.length > 0 ? "" : <div
                      style={{
                        fontSize: "13px",
                        padding: "10px",
                        background: "#081336",
                        width: "fit-content",
                        margin: "10px auto 0",
                      }}
                    >
                      No EMIs created for this course
                    </div>
                  } */}

                  <div className="add_Emis">
                    <div className="title">
                      List of EMIs For This Course:{" "}
                      {editable && (
                        <ToggleSwitch
                          onChange={(e) => setIsEmiAvail(!isEmiAvail)}
                          checked={isEmiAvail ? true : false}
                        />
                      )}
                    </div>

                    {allEmis?.length > 0 && isEmiAvail && (
                      <div className="emi_data_table_main custonScroll">
                        <table className="all_emis_table ">
                          <thead>
                            <tr>
                              <th>
                                <p>#</p>
                              </th>
                              <th>
                                <p>EMI Name</p>
                              </th>
                              <th>
                                <p>EMI Amount</p>
                              </th>
                              <th>
                                <p>EMI Time</p>
                              </th>
                              {editable && (
                                <th>
                                  <p>Action</p>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {allEmis?.map((item, idx) => (
                              <tr key={idx}>
                                <td>
                                  <p>{idx + 1}</p>
                                </td>
                                <td>
                                  <p>{item?.emiName}</p>
                                </td>
                                <td>
                                  <p>₹{item?.emiAmount}</p>
                                </td>
                                <td>
                                  <p>{item?.emiDuration} days</p>
                                </td>
                                {editable && (
                                  <td>
                                    <button
                                      onClick={() => handleRemoveEmi(idx)}
                                    >
                                      X
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {editable && isEmiAvail ? (
                      <div
                        className="options_data"
                        style={{ marginTop: "10px" }}
                      >
                        <div>
                          <CustomInput
                            placeholder="EMI Name"
                            type="text"
                            name="emiName"
                            impStar="*"
                            onChange={handleAddEMI}
                          />
                          <CustomInput
                            placeholder="EMI Amout"
                            type="number"
                            name="emiAmount"
                            icon="₹"
                            onChange={handleAddEMI}
                          />
                          <CustomInput
                            placeholder="EMI Duration (in days)"
                            type="number"
                            name="emiDuration"
                            icon={<BsCalendarDay />}
                            onChange={handleAddEMI}
                          />
                        </div>
                        <button className="add_Emi_btn" onClick={addAllEmis}>
                          Add
                        </button>
                      </div>
                    ) : allEmis?.length <= 0 ? (
                      <div
                        style={{
                          fontSize: "13px",
                          padding: "10px",
                          background: "#081336",
                          width: "fit-content",
                          margin: "10px auto 0",
                        }}
                      >
                        If you want to create EMIs for this course, please trun
                        on EMI Options
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {editable && (
                  <CustomButton
                    onClick={handleCourseUpdate}
                    className="create_course_btn"
                    title={
                      courseUpdateLoading ? <ButtonLoader /> : "Update Course"
                    }
                    disabled={courseUpdateLoading ? true : false}
                  />
                )}
              </div>

              <h2 className="main_heading_landing">Landing Page Content</h2>

              {/* UPDATE COURSE META DETAILS START */}
              {courseMetaDetailsLoading ? (
                <div style={{ margin: "30px 0" }}>Loading please wait...</div>
              ) : (
                <div className="course_details_main">
                  <div className="title">
                    <h2>Meta Details</h2>
                    <button
                      className={`courseEditBtn ${editCourseMeta ? "activeBtn" : ""
                        }`}
                      onClick={() => setEditCourseMeta(!editCourseMeta)}
                    >
                      {editCourseMeta ? <FaExpeditedssl /> : <FaUserEdit />}{" "}
                      Edit
                    </button>
                  </div>

                  <div className="course_details_form couse_meta_data_row">
                    <div>
                      <div className="en_cols">
                        <CustomTextarea
                          label="Meta Title English"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="Meta Title English"
                          name="metaTitle"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaTitle
                              : courseMetaDetailsParam?.metaTitle
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="Meta Keywords English"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="Meta Keywords English"
                          name="metaKeywords"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaKeywords
                              : courseMetaDetailsParam?.metaKeywords
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="Meta Description English"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="Meta Description English"
                          name="metaDescription"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaDescription
                              : courseMetaDetailsParam?.metaDescription
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="Meta Author English"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="Meta Author English"
                          name="metaAuthor"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaAuthor
                              : courseMetaDetailsParam?.metaAuthor
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                      </div>
                      <div className="hi_cols">
                        <CustomTextarea
                          label="मेटा टाइटल हिंदी"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="मेटा टाइटल हिंदी"
                          name="metaTitleHindi"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaTitleHindi
                              : courseMetaDetailsParam?.metaTitleHindi
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="मेटा कीवर्ड्स हिंदी"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="मेटा कीवर्ड्स हिंदी"
                          name="metaKeywordsHindi"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaKeywordsHindi
                              : courseMetaDetailsParam?.metaKeywordsHindi
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="मेटा डिस्क्रिप्शन हिंदी"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="मेटा डिस्क्रिप्शन हिंदी"
                          name="metaDescriptionHindi"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaDescriptionHindi
                              : courseMetaDetailsParam?.metaDescriptionHindi
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                        <CustomTextarea
                          label="मेटा ऑथर हिंदी"
                          rows="2"
                          type="text"
                          onChange={handleCourseMetaChange}
                          placeholder="मेटा ऑथर हिंदी"
                          name="metaAuthorHindi"
                          value={
                            editCourseMeta
                              ? courseMetaData?.metaAuthorHindi
                              : courseMetaDetailsParam?.metaAuthorHindi
                          }
                          readOnly={editCourseMeta ? false : true}
                          borderColor={editCourseMeta ? "#f2f2f2" : ""}
                        />
                      </div>
                    </div>
                    {editCourseMeta && (
                      <CustomButton
                        className="update_course_dtls"
                        title={
                          metaTagsLoading ? (
                            <ButtonLoader />
                          ) : (
                            "Update Meta Details"
                          )
                        }
                        onClick={createCourseMetaHandler}
                        disabled={metaTagsLoading ? true : false}
                        width=""
                      />
                    )}
                  </div>
                </div>
              )}

              {/* UPDATE COURSE META DETAILS ENDS */}

              {/* COURSE LONG DETAILS UPDATE FORM START */}
              {courseLongDetailsLoading ? (
                <div style={{ margin: "30px 0" }}>Loading please wait...</div>
              ) : (
                <div className="course_details_main">
                  <div className="title">
                    <h2>Course Details</h2>
                    <button
                      className={`courseEditBtn ${editCourseDetails ? "activeBtn" : ""
                        }`}
                      onClick={() => setEditCourseDetails(!editCourseDetails)}
                    >
                      {editCourseDetails ? <FaExpeditedssl /> : <FaUserEdit />}{" "}
                      Edit
                    </button>
                  </div>
                  <div className="course_details_form">
                    <CustomTextarea
                      label="Course Detail English 1"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="Course Detail English 1"
                      name="contentEn1"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentEn1
                          : courseLongDetailsParam?.contentEn1
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="Course Detail English 2"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="Course Detail English 1"
                      name="contentEn2"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentEn2
                          : courseLongDetailsParam?.contentEn2
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="Course Detail English 3"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="Course Detail English 1"
                      name="contentEn3"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentEn3
                          : courseLongDetailsParam?.contentEn3
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="Course Detail English 4"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="Course Detail English 1"
                      name="contentEn4"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentEn4
                          : courseLongDetailsParam?.contentEn4
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="कोर्स डिटेल्स हिंदी 1"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="कोर्स डिटेल्स हिंदी 1"
                      name="contentHi1"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentHi1
                          : courseLongDetailsParam?.contentHi1
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="कोर्स डिटेल्स हिंदी 2"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="कोर्स डिटेल्स हिंदी 2"
                      name="contentHi2"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentHi2
                          : courseLongDetailsParam?.contentHi2
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="कोर्स डिटेल्स हिंदी 3"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="कोर्स डिटेल्स हिंदी 3"
                      name="contentHi3"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentHi3
                          : courseLongDetailsParam?.contentHi3
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    <CustomTextarea
                      label="कोर्स डिटेल्स हिंदी 4"
                      rows="3"
                      type="text"
                      onChange={handleCourseDetailsChange}
                      placeholder="कोर्स डिटेल्स हिंदी 4"
                      name="contentHi4"
                      value={
                        editCourseDetails
                          ? courseDetails?.contentHi4
                          : courseLongDetailsParam?.contentHi4
                      }
                      readOnly={editCourseDetails ? false : true}
                      borderColor={editCourseDetails ? "#f2f2f2" : ""}
                    />
                    {editCourseDetails && (
                      <CustomButton
                        className="update_course_dtls"
                        title={
                          createdCourseDetailsLoading ? (
                            <ButtonLoader />
                          ) : (
                            "Update Course Details"
                          )
                        }
                        onClick={createCourseDetailsHandler}
                        disabled={createdCourseDetailsLoading ? true : false}
                        width=""
                      />
                    )}
                  </div>
                </div>
              )}
              {/* COURSE LONG DETAILS UPDATE FORM ENDS */}
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default ViewEditCourse;
