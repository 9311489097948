// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    type:"",
    message:""
  });
  const [visible, setVisible] = useState(false)

  const showNotification = (type, message) => {
    setNotification({ type, message });
    setVisible(true);
    setTimeout(() => {
      hideNotification();
      setNotification("")
    }, 7000);
  };

  const hideNotification = () => {
    setNotification(null);
    setVisible(false);
  };

  return (
    <NotificationContext.Provider value={[notification, visible, showNotification, hideNotification]}>
      {children}
    </NotificationContext.Provider>
  );
};

export { useNotification, NotificationProvider }