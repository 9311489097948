import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  CREATE_TEXT_TESTI_FAIL,
  CREATE_TEXT_TESTI_REQUEST,
  CREATE_TEXT_TESTI_SUCCESS,
  DELETE_TEXT_TESTI_FAIL,
  DELETE_TEXT_TESTI_REQUEST,
  DELETE_TEXT_TESTI_SUCCESS,
  GET_TEXT_TESTI_BY_ID_FAIL,
  GET_TEXT_TESTI_BY_ID_REQUEST,
  GET_TEXT_TESTI_BY_ID_SUCCESS,
  GET_TEXT_TESTI_FAIL,
  GET_TEXT_TESTI_REQUEST,
  GET_TEXT_TESTI_SUCCESS,
  UPDATE_TEXT_TESTI_FAIL,
  UPDATE_TEXT_TESTI_REQUEST,
  UPDATE_TEXT_TESTI_SUCCESS,
} from "../constants/textTextiConstant";

// CREATE TEXT TESTIMONIAL REDUCER - ADMIN
export const createTextTestimonialsReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TEXT_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: {},
      };
    case CREATE_TEXT_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case CREATE_TEXT_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: {},
      };

    default:
      return state;
  }
};

// GET ALL TEXT TESTIMONIAL REDUCERs
export const getTextTestimonialsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEXT_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: [],
      };
    case GET_TEXT_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case GET_TEXT_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: [],
      };

    default:
      return state;
  }
};

// DELETE TEXT TESTIMONIAL REDUCER - ADMIN
export const deleteTextTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TEXT_TESTI_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DELETE_TEXT_TESTI_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DELETE_TEXT_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        data: {},
      };

    default:
      return state;
  }
};

// GET TEXT TESTIMONIAL BY ID REDUCER - ADMIN
export const getTextTestimonialByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEXT_TESTI_BY_ID_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case GET_TEXT_TESTI_BY_ID_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_TEXT_TESTI_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        data: {},
      };

    default:
      return state;
  }
};

// UPDATE TEXT TESTIMONIALREDUCER - ADMIN
export const updateTextTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TEXT_TESTI_REQUEST:
      return {
        loading: true,
        testimonial: {},
      };
    case UPDATE_TEXT_TESTI_SUCCESS:
      return {
        loading: false,
        testimonial: action.payload,
      };
    case UPDATE_TEXT_TESTI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        error: null,
        testimonial: {},
      };

    default:
      return state;
  }
};
