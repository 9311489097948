import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_ERRORS,
  CLEAR_STATE,
  CREATE_TEXT_TESTI_FAIL,
  CREATE_TEXT_TESTI_REQUEST,
  CREATE_TEXT_TESTI_SUCCESS,
  DELETE_TEXT_TESTI_FAIL,
  DELETE_TEXT_TESTI_REQUEST,
  DELETE_TEXT_TESTI_SUCCESS,
  GET_TEXT_TESTI_BY_ID_FAIL,
  GET_TEXT_TESTI_BY_ID_REQUEST,
  GET_TEXT_TESTI_BY_ID_SUCCESS,
  GET_TEXT_TESTI_FAIL,
  GET_TEXT_TESTI_REQUEST,
  GET_TEXT_TESTI_SUCCESS,
  UPDATE_TEXT_TESTI_FAIL,
  UPDATE_TEXT_TESTI_REQUEST,
  UPDATE_TEXT_TESTI_SUCCESS,
} from "../constants/textTextiConstant";

// CREATE NEW TEXT TESTIMONIAL - POST
export const createTextTestimonial =
  (
    title,
    selectedIn,
    givenStar,
    textHindi,
    textEnglish,
    thumbnail,
    forCourse
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_TEXT_TESTI_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-text-testimonial`,
        {
          title,
          selectedIn,
          givenStar,
          textHindi,
          textEnglish,
          thumbnail,
          forCourse,
        },
        config
      );

      dispatch({
        type: CREATE_TEXT_TESTI_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_TEXT_TESTI_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL TEXT TESTIMONIALS
export const getTextTestimonial = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEXT_TESTI_REQUEST,
    });

    const { data } = await axiosInstance.get(`/get-all-testimonials`);

    dispatch({
      type: GET_TEXT_TESTI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEXT_TESTI_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE TEXT TESTIMONIAL - ADMIN
export const deleteTextTestimonial = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEXT_TESTI_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-text-testimonial/${id}`,
      config
    );

    dispatch({
      type: DELETE_TEXT_TESTI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TEXT_TESTI_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET TEXT TESTIMONIAL DETAILS BY ID - ADMIN
export const getTextTestimonialDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEXT_TESTI_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/text-testimonial/${id}`, config);

    dispatch({
      type: GET_TEXT_TESTI_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEXT_TESTI_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE TEXT TESTIMONIAL - PUT
export const updateTextTestimonial =
  (
    id,
    isActive,
    title,
    selectedIn,
    givenStar,
    textHindi,
    textEnglish,
    thumbnail,
    forCourse
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_TEXT_TESTI_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/text-testimonial/${id}`,
        {
          isActive,
          title,
          selectedIn,
          givenStar,
          textHindi,
          textEnglish,
          thumbnail,
          forCourse,
        },
        config
      );

      dispatch({
        type: UPDATE_TEXT_TESTI_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TEXT_TESTI_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
