import React from "react";
import styled from "styled-components";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Section = styled.div`
  margin: 40px 0;

  @media screen and (max-width: 767px) {
    margin: 20px 0;
  }

  > .title {
    display: flex;
    align-items: center;

    > span {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 15px;
      cursor: pointer;
      background-color: #121d40;
      color: #fff;
      transition: 0.4s;

      &:hover {
        background-color: #fff;
        color: #121d40;
      }

      > svg {
        width: 70%;
        height: 70%;
      }
    }
    > h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff;
    }
  }
`;

const PageTitle = ({ goBack = true, title }) => {
  const navigate = useNavigate();
  return (
    <Section className="page_title_main">
      <div className="title">
        {goBack && (
          <span onClick={() => navigate(-1)}>
            <BiLeftArrowAlt />
          </span>
        )}
        <h2 className="monserrat_font">{title}</h2>
      </div>
    </Section>
  );
};

export default PageTitle;
