import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_VID_TESTI_FAIL,
  ALL_VID_TESTI_REQUEST,
  ALL_VID_TESTI_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
  CREATE_VID_TESTI_FAIL,
  CREATE_VID_TESTI_REQUEST,
  CREATE_VID_TESTI_SUCCESS,
  DELETE_VID_TESTI_FAIL,
  DELETE_VID_TESTI_REQUEST,
  DELETE_VID_TESTI_SUCCESS,
  GET_VID_TESTI_DETAIL_FAIL,
  GET_VID_TESTI_DETAIL_REQUEST,
  GET_VID_TESTI_DETAIL_SUCCESS,
  UPDATE_VIDEO_TESTI_FAIL,
  UPDATE_VIDEO_TESTI_REQUEST,
  UPDATE_VIDEO_TESTI_SUCCESS,
} from "../constants/vidTestiConstant";

// CREATE NEW VIDEO TESTIMONIAL - POST
export const createVideoTestimonial =
  (videoUrl, thumbnail, forCourse) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_VID_TESTI_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-vid-testimonial`,
        {
          videoUrl,
          thumbnail,
          forCourse,
        },
        config
      );

      dispatch({
        type: CREATE_VID_TESTI_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_VID_TESTI_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL VIDEO TESTIMONIALS
export const getVideoTestimonial = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_VID_TESTI_REQUEST,
    });

    const { data } = await axiosInstance.get(`/all-vid-testimonials`);

    dispatch({
      type: ALL_VID_TESTI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_VID_TESTI_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE VIDEO TESTIMONIAL - ADMIN
export const deleteVideoTestimonial = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_VID_TESTI_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-video-testimonial/${id}`,
      config
    );

    dispatch({
      type: DELETE_VID_TESTI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VID_TESTI_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET VIDEO TESTIMONIAL DETAILS BY ID - ADMIN
export const getVideoTestimonialDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VID_TESTI_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
      ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/video-testimonial/${id}`,
      config
    );

    dispatch({
      type: GET_VID_TESTI_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_VID_TESTI_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE VIDEO TESTIMONIAL - PUT
export const updateVideoTestimonial =
  (id, isActive, videoUrl, thumbnail, forCourse) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_VIDEO_TESTI_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("alphaAdminToken")
        ? JSON.parse(sessionStorage.getItem("alphaAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/video-testimonial/${id}`,
        {
          isActive,
          videoUrl,
          thumbnail,
          forCourse,
        },
        config
      );

      dispatch({
        type: UPDATE_VIDEO_TESTI_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VIDEO_TESTI_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
