import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: transparent;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  height: 40px;
  width: ${(props) => props.width};
  transition: 0.4s;

  &:hover {
    background-color: #f2f2f2;
    color: #081336;
    border: 1px solid #081336;
  }
`;

const CustomButton = ({
  className,
  title,
  onClick,
  icon,
  disabled,
  width = "260px",
}) => {
  return (
    <Button
      className={`${className} monserrat_font`}
      onClick={onClick}
      width={width}
      disabled={disabled}
    >
      {title} {icon}
    </Button>
  );
};

export default CustomButton;
