import {
    CLEAR_BLOG_ERROR,
    CLEAR_BLOG_STATE,
    CLEAR_DELETE_BLOG_STATE,
    CREATE_BLOG_FAIL,
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    GET_BLOGS_FAIL,
    GET_BLOGS_REQUEST,
    GET_BLOGS_SUCCESS,
    GET_BLOG_BY_ID_FAIL,
    GET_BLOG_BY_ID_REQUEST,
    GET_BLOG_BY_ID_SUCCESS,
    UPDATE_BLOG_FAIL,
    UPDATE_BLOG_REQUEST,
    UPDATE_BLOG_SUCCESS,
  } from "../constants/blogConstant";
  
  // CREATE NEW BATCH BY ADMIN
  export const createBlogReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_BLOG_REQUEST:
        return {
          loading: true,
          blogCreate: {},
        };
      case CREATE_BLOG_SUCCESS:
        return {
          loading: false,
          blogCreate: action.payload,
        };
      case CREATE_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogCreate: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL blogs
  export const getBlogsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_BLOGS_REQUEST:
        return {
          loading: true,
          blogs: {},
        };
      case GET_BLOGS_SUCCESS:
        return {
          loading: false,
          blogs: action.payload,
        };
      case GET_BLOGS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogs: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL BATCH BY ID
  export const getBlogByIdReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_BLOG_BY_ID_REQUEST:
        return {
          loading: true,
          blogDetails: {},
        };
      case GET_BLOG_BY_ID_SUCCESS:
        return {
          loading: false,
          blogDetails: action.payload,
        };
      case GET_BLOG_BY_ID_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogDetails: {},
        };
  
      default:
        return state;
    }
  };


//   UPDATE BLOG REDUCER
export const updateBlogDataReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_BLOG_REQUEST:
        return {
          loading: true,
          updateData: {},
        };
      case UPDATE_BLOG_SUCCESS:
        return {
          loading: false,
          updateData: action.payload,
        };
      case UPDATE_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_BLOG_STATE:
        return {
          loading: false,
          error: null,
          updateData: {},
        };
  
      default:
        return state;
    }
  };
  
  // DELETE BATCH BY ADMIN
  export const deleteBlogByAdmin = (state = {}, action) => {
    switch (action.type) {
      case DELETE_BLOG_REQUEST:
        return {
          loading: true,
          blogDelete: {},
        };
      case DELETE_BLOG_SUCCESS:
        return {
          loading: false,
          blogDelete: action.payload,
        };
      case DELETE_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_BLOG_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_DELETE_BLOG_STATE:
        return {
          loading: false,
          error: null,
          blogDelete: {},
        };
  
      default:
        return state;
    }
  };
  