import React, { useEffect, useState } from "react";
import "../../styles/allStudentsList.scss";
import "../../styles/tableDataStyle.scss";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line, RiEdit2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  clearErrors,
  deleteUserByAdmin,
  getAllUsersByAdmin,
} from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import Loader from "../../components/Loader";
import PopupPrompt from "../../components/PopupPrompt";
import ReactPaginate from "react-paginate";
import usePaginate from "../../hooks/usePaginate";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import { useNotification } from "../../context/notificationContext";

const AllStudents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchString, setSearchString] = useState("");
  const [, , showNotification] = useNotification();

  // GET ALL USERS START
  // GET ALL USERS START
  const {
    loading: allUsersLoading,
    allUsers,
    error: allUsersError,
  } = useSelector((state) => state.getUsersByAdmin);

  const allStudents = allUsers?.allStudents;

  useEffect(() => {
    dispatch(getAllUsersByAdmin());
  }, [dispatch]);

  useEffect(() => {
    if (allUsersError) {
      showNotification("error", allUsersError);
      dispatch(clearErrors());
    }
  }, [allUsersError, dispatch, showNotification]);
  // GET ALL USERS ENDS
  // GET ALL USERS ENDS

  // DELETE AFFILIATE START
  // DELETE AFFILIATE START
  const {
    loading: deletedUserLoading,
    deletedUser,
    error: deletedUserError,
  } = useSelector((state) => state.deleteUser);

  const deleteUserHandler = (id) => {
    if (id) {
      setDeleteId(id);
      setDeletePrompt(true);
    }
  };

  useEffect(() => {
    if (deletedUserError) {
      showNotification("error", deletedUserError);
      dispatch(clearErrors());
    }
    if (deletedUser?.success) {
      showNotification("success", deletedUser?.message);
      dispatch(clearErrors());
      setDeletePrompt(false);
      dispatch(getAllUsersByAdmin());
    }
  }, [deletedUserError, deletedUser?.success, deletedUser?.message, dispatch, showNotification]);

  // DELETE AFFILIATE ENDS
  // DELETE AFFILIATE ENDS

  // SEARCH USERS START
  // SEARCH USERS START
  // let filteredUsers = [];

  // filteredUsers = allStudents?.filter((i) => {
  //   return (
  //     i.email.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
  //     i.phone.includes(searchString.toLocaleLowerCase())
  //   );
  // });
  // SEARCH USERS ENDSs
  // SEARCH USERS ENDS

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(allStudents);
  // Pagination Hook Ends

  // const stringWithSpaces = "f o n t a w e s o m e i c o n s";
  // const stringWithoutSpaces = stringWithSpaces.replace(/\s/g, "");

  return (
    <Fragment>
      {allUsersLoading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="all_students_main">
            <div className="container">
              <PageTitle title="Students" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search students"
                    name="searchStudent"
                    onChange={(e) => setSearchString(e.target.value)}
                    value={searchString}
                  />

                  <button
                    className="add_batch_button"
                  // onClick={() => navigate("/admin/create-batch")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {currentItems?.length <= 0 ? (
                  <h3 className="alertText_style">Students Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Profile Picture</th>
                          <th>Student name</th>
                          <th>Email</th>
                          <th>Contact No.</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={
                                    item?.profile?.url
                                      ? item?.profile?.url
                                      : item?.gender === "Female"
                                        ? defaultFemalUser
                                        : defaultUser
                                  }
                                  alt={item?.first_name}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "5px",
                                    background: "#f2f2f2",
                                  }}
                                />
                              </td>
                              <td>
                                <p>
                                  {item?.first_name} {item?.middle_name}{" "}
                                  {item?.last_name}{" "}
                                </p>
                              </td>
                              <td>
                                <p> {item?.email} </p>
                              </td>
                              <td>
                                <p> {item?.phone} </p>
                              </td>
                              <td>
                                <div>
                                  <button
                                    title="Delete"
                                    onClick={() => deleteUserHandler(item?._id)}
                                  >
                                    <RiDeleteBin5Line />
                                  </button>
                                  <button
                                    title="View/Edit"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-student/${item?._id}`
                                      )
                                    }
                                  >
                                    <RiEdit2Line />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="pagination_sec">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RxDoubleArrowRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={<RxDoubleArrowLeft />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>

            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => dispatch(deleteUserByAdmin(deleteId))}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this affiliate user?"
                loading={deletedUserLoading}
              />
            )}

            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllStudents;
