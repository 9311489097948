import React, { Fragment, useEffect, useState } from "react";
import "../../styles/allAdminList.scss";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { defaultFemalUser, defaultUser } from "../../assets/images";
import { RiDeleteBin5Line } from "react-icons/ri";
import PopupPrompt from "../../components/PopupPrompt";
import { MdAirplanemodeActive, MdAirplanemodeInactive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  clearErrors,
  clearState,
  getAllOfficers,
  inactivateUser,
} from "../../redux/actions/userAction";
import ButtonLoader from "../../components/ButtonLoader";

const AllAdminList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [offcierId, setOffcierId] = useState();
  const [filterOfficerArr, setFilterOfficerArr] = useState([]);

  // GET ALL OFFICERS START
  const { officers, loading, error } = useSelector(
    (state) => state.allOfficersReducer
  );

  useEffect(() => {
    dispatch(getAllOfficers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
    if (officers?.success) {
      setFilterOfficerArr(officers?.officers);
    }
  }, [error, dispatch, officers?.success, officers?.officers]);
  // GET ALL OFFICERS ENDS

  // FILTER OFFICER START
  const filterOfficers = (role) => {
    setRoleId(role);
    if (role !== 0) {
      const allOfficers = officers?.officers?.filter(
        (ele) => ele?.role === role
      );
      setFilterOfficerArr(allOfficers);
    } else {
      setFilterOfficerArr(officers?.officers);
    }
  };
  // FILTER OFFICER ENDS

  // ACTIVATE USER
  const {
    status: activateStatus,
    loading: activateLoading,
    error: activateError,
  } = useSelector((state) => state.activateUserReducer);

  const handleActiveUser = (id) => {
    if (id) {
      setOffcierId(id);
      dispatch(activateUser(id));
    }
  };

  useEffect(() => {
    if (activateError) {
      alert(activateError);
      dispatch(clearErrors());
    }
    if (activateStatus?.success) {
      alert(activateStatus?.message);
      dispatch(clearState());
      dispatch(getAllOfficers());
    }
  }, [
    activateError,
    dispatch,
    activateStatus?.success,
    activateStatus?.message,
  ]);
  // ACTIVATE USER

  // INACTIVATE USER
  const {
    status: inactivateStatus,
    loading: inactivateLoading,
    error: inactivateError,
  } = useSelector((state) => state.inactivateUserReducer);

  const handleInactiveUser = (id) => {
    if (id) {
      setOffcierId(id);
      dispatch(inactivateUser(id));
    }
  };

  useEffect(() => {
    if (inactivateError) {
      alert(inactivateError);
      dispatch(clearErrors());
    }
    if (inactivateStatus?.success) {
      alert(inactivateStatus?.message);
      dispatch(clearState());
      dispatch(getAllOfficers());
    }
  }, [
    inactivateError,
    dispatch,
    inactivateStatus?.success,
    inactivateStatus?.message,
  ]);
  // INACTIVATE USER

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="allAdminsMain">
            <div className="container">
              <PageTitle title="All Admins" />

              <div className="all_batch_list">
                <div className="batch_listHeader">
                  <CustomInput
                    icon={<BsSearch />}
                    type="search"
                    placeholder="Search Admin"
                    name="searchBatch"
                    //   onChange=""
                    //   value=""
                  />

                  <button
                    className="add_batch_button"
                    title="Create New Admin"
                    onClick={() => navigate("/admin/create-admin")}
                  >
                    <AiOutlinePlus />
                  </button>
                </div>

                {filterOfficerArr?.length <= 0 ? (
                  <h3 className="alertText_style">Admins Not Found!</h3>
                ) : (
                  <div className="table_data custonScroll">
                    <table className="list_table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Profile Picture</th>
                          <th>Admin name</th>
                          <th>Email</th>
                          <th>Access Right</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterOfficerArr?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                <img
                                  src={
                                    item?.profile?.url
                                      ? item?.profile?.url
                                      : item?.gender === "Female"
                                      ? defaultFemalUser
                                      : defaultUser
                                  }
                                  alt={item?.first_name}
                                  style={{ width: "50px", height: "auto" }}
                                />
                              </td>
                              <td>
                                <p>{item?.first_name}</p>
                              </td>
                              <td>
                                <p> {item?.email}</p>
                              </td>
                              <td>
                                <p>Batches , Courses, Blogs</p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    color: item?.isVerified ? "green" : "red",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item?.isVerified ? "Verified" : "Unverified"}
                                </p>
                              </td>
                              <td>
                                <div>
                                  <button title="Delete Admin">
                                    <RiDeleteBin5Line />
                                  </button>

                                  {item?.isVerified ? (
                                    <button
                                      title="Unverify Admin"
                                      onClick={() =>
                                        handleInactiveUser(item?._id)
                                      }
                                    >
                                      {inactivateLoading &&
                                      offcierId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeInactive />
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      title="Verify Officer"
                                      onClick={() =>
                                        handleActiveUser(item?._id)
                                      }
                                    >
                                      {activateLoading &&
                                      offcierId === item?._id ? (
                                        <ButtonLoader />
                                      ) : (
                                        <MdAirplanemodeActive />
                                      )}
                                    </button>
                                  )}

                                  <button
                                    title="View/Edit Officer"
                                    onClick={() =>
                                      navigate(
                                        `/admin/view-edit-teacher/${item?._id}`
                                      )
                                    }
                                  >
                                    <AiFillEye />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            {/* DELETE USER PROMPT START */}
            {deletePrompt && (
              <PopupPrompt
                confirmOnclick={() => setDeletePrompt(false)}
                cancelOnclick={() => setDeletePrompt(false)}
                title="Do you really want to delete this Officer?"
              />
            )}
            {/* DELETE USER PROMPT ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default AllAdminList;
