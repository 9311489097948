import React from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import CustomSelect from "../../components/CustomSelect";
import CustomButton from "../../components/CustomButton";
import ButtonLoader from "../../components/ButtonLoader";
import CustomTextarea from "../../components/CustomTextarea";

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;

  > .closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid #f2f2f2;
    background-color: #081336;
    color: #f2f2f2;
    font-size: 20px;
    width: 30px;
    height: 30px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }

  > .content {
    background: #081336;
    max-width: 767px;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    padding: 15px;
    box-sizing: border-box;

    > .modalTitle {
      color: #f2f2f2;
      margin-bottom: 20px;

      > span {
        border-radius: 4px;
        padding: 2px;
        font-size: 13px;
      }
      > .green {
        background-color: green;
        color: #f2f2f2;
      }
      > .red {
        background-color: red;
        color: #f2f2f2;
      }
    }

    > .verificationForm {
      > button {
        margin: 0 auto;
      }
    }
  }
`;

const VerifyCourseModal = ({
  verifyModal,
  setModal,
  setIsActive,
  isActive,
  status,
  setStatus,
  handleVerify,
  verificationLoading,
  setCustomStatus,
  customStatus,
}) => {

  return (
    <Wrapper>
      <button
        className="closeBtn"
        onClick={() => setModal({ show: false, id: "" })}
      >
        <IoClose />
      </button>
      <div className="content">
        <div className="modalTitle">
          Currently, This Course is{" "}
          <span className={verifyModal.isActive ? "green" : "red"}>
            {verifyModal.isActive ? "Verified" : "not verified"}
          </span>
          .
          <p>
            If you take any action on it, course status & visiblity may effect.
          </p>
        </div>

        <form className="verificationForm">
          <CustomSelect
            label="Verify/Not"
            name="isActive"
            onChange={(e) => {
              setIsActive(e.target.value);
              setStatus("");
              setCustomStatus("");
            }}
            optionArray={[
              { option: "Verify", value: true },
              { option: "Un-Verify", value: false },
            ]}
            impStar="*"
            value={isActive}
          />

          {isActive !== "" && (
            <CustomSelect
              label="Status"
              name="status"
              onChange={(e) => {
                setStatus(e.target.value);
                setCustomStatus("");
              }}
              optionArray={[
                { option: "Verified" },
                { option: "Recheck your course." },
                { option: "Rejected" },
                { option: "Custom" },
              ]}
              impStar="*"
              value={status}
            />
          )}

          {status === "Custom" && (
            <CustomTextarea
              label="Write custom status *"
              rows="5"
              type="text"
              onChange={(e) => setCustomStatus(e.target.value)}
              placeholder="Custom Status"
              name="customStatus"
              value={customStatus}
            />
          )}

          <CustomButton
            title={verificationLoading ? <ButtonLoader /> : "Submit"}
            onClick={handleVerify}
            disabled={verificationLoading ? true : false}
          />
        </form>
      </div>
    </Wrapper>
  );
};

export default VerifyCourseModal;
